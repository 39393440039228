import React from 'react'
import { withRouter } from '../components/WithRouter'
import { connect } from 'react-redux'
import Button from '../components/Layouts/Button'
import PropTypes from 'prop-types'
import WebsiteFontColor from '../components/Layouts/WebsiteFontColor'

function Error404Page(props) {
    return (
        <WebsiteFontColor>
            <div className='page text-center'>
                <div className='container'>
                    <h1 className='h1 mt-0 mb-4 display-1 mb-5 mt-7'>404</h1>
                    <h2 className='h2 mt-0 mb-4'>Oops... You just found an error page...</h2>
                    <h4 className='h4 mt-0 mb-4 font-weight-normal mb-7'>We are sorry but the page you have requested can not be found...</h4>
                    <Button classes='btn mb-7' onClick={() => props.navigate('/')}>
                        <i className='fe fe-arrow-left mr-2'></i>
                        Go To Home
                    </Button>
                </div>
            </div>
        </WebsiteFontColor>
    )
}

Error404Page.propTypes = {
    navigate: PropTypes.func.isRequired
}

export default connect()(withRouter(Error404Page))
