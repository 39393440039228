import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { withRouter } from '../WithRouter'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import BlogCard from './../blogs/BlogCard'
import { getAllBlogs, deleteBlogPost, editPost, selectedFeedTag, setLoading } from '../../actions/blogActions'
import { refreshBlurImage, rotateImage, sendVideoProcessingRequest } from '../../actions/adminActions'
import { blogChargeByPrevious } from '../../actions/ccbillActions'
import PhotoIdModal from './../Layouts/PhotoIdModal'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { toggleNewPaymentModal } from '../../actions/utilityActions'
import BlogGalleryDialog from './../Layouts/BlogGalleryDialog'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import EditBlog from '../Layouts/EditBlog'
import PromotionNotificationBar from '../Layouts/PromotionNotificationBar'
import { blogContentPurchase } from '../../actions/stickyIoActions'
import Loader from '../Layouts/Loader'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { blogPurchasePayment } from '../../actions/hybridPaymentAction'
import NewPostBlogForm from '../Layouts/NewPostBlogForm'
import NewReusePost from '../Layouts/NewReusePost'
import CategoryTags from '../Layouts/CategoryTags'
import PaymentIcons from '../Layouts/PaymentIcons'
import AddFundForm from '../crypto/AddFundForm'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import Pagination from '../table/Pagination'
import classNames from 'classnames'

const useStyles = theme => ({
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 900
        }
    },
    row: {
        marginLeft: '0px',
        marginRight: '0px'
    }
})

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const HeaderBanner = styled.div`
    position: relative;
    text-align: center;
    color: white;
    max-height: 800px;
    width: auto;
    overflow: hidden;
    opacity: ${props => props.opacity}%;
`

const Header = styled.div`
    position: absolute;
    top: ${props => props.theme2BannerTopPercentage}%;
    left: ${props => props.theme2BannerLeftPercentage}%;
    transform: translate(-50%, -50%);
    width: fit-content;
`

const HeaderBannerTitle = styled.div`
    font-size: ${props => props.theme2FontSize[3]};
    font-family: ${props => props.theme2BannerFontFamily};
    line-height: 1;
    padding-top: 40px;
    color:${props => props.theme2BannerTextColor};

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.theme2FontSize[0]};
        max-width: 288px;
        padding-top: 20px;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.theme2FontSize[1]};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.theme2FontSize[2]};
    }
`

const HeaderBannerTitleImage = styled.img`
    height: ${props => props.theme.imageSize[0]};

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        height: ${props => props.theme.imageSize[3]};;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        height: ${props => props.theme.imageSize[2]};;
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        height: ${props => props.theme.imageSize[1]};;
    }
`

const HeaderSubscribeNow = styled.div`
    font-size: ${props => props.theme2FontSize[3]};
    padding-top: ${props => props.theme2BannerTextImageUrl ? '25px' : '0px'};
    margin-top: ${props => props.marginTop} !important;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.theme2FontSize[0]};
        max-width: 320px;
        top: 65%;
        padding-top: ${props => props.theme2BannerTextImageUrl ? '5px' : '0px'};
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.theme2FontSize[1]};
        padding-top: ${props => props.theme2BannerTextImageUrl ? '10px' : '0px'};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.theme2FontSize[2]};
        padding-top: ${props => props.theme2BannerTextImageUrl ? '20px' : '0px'};
    }
`

const HeaderSubscribeNowVideo = styled.div`
    font-size: ${props => `calc(${props.theme2FontSize[3]} - 15px)`};
    padding-top: ${props => props.theme2BannerTextImageUrl ? '25px' : '0px'};

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => `calc(${props.theme2FontSize[0]} - 5px)`};
        max-width: 320px;
        top: 65%;
        padding-top: ${props => props.theme2BannerTextImageUrl ? '5px' : '0px'};
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => `calc(${props.theme2FontSize[1]} - 10px)`};
        padding-top: ${props => props.theme2BannerTextImageUrl ? '10px' : '0px'};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => `calc(${props.theme2FontSize[2]} - 15px)`};
        padding-top: ${props => props.theme2BannerTextImageUrl ? '20px' : '0px'};
    }
`

const HeaderText = styled.div`
    font-size: ${props => props.exclusiveContentBannerFontSize[3]} !important;
    text-align: center;
    padding-top: 20px;

    font-family: ${props => props.textExclusiveContentBannerFontStyle} !important;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.exclusiveContentBannerFontSize[0]} !important;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.exclusiveContentBannerFontSize[1]} !important;
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.exclusiveContentBannerFontSize[2]} !important;
    }
`

const RecentPostText = styled.div`
    font-size: 20px;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: 16px;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: 17px;
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: 18px;
    }
`

const SocialMediaIcons = styled.img`
    height: 30px;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.mobileL}) {
        height: 30px;
    }
`

const PreviousImage = styled.div`
    position: absolute;
    top: 50%;
    left: 16px;
    cursor: pointer;
    transform: translate(-50%, -50%);
`

const NextImage = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    transform: translate(-50%, -50%);
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

const BadgeSpan = styled.span`
    position: absolute;
    margin-left: -15px;
    background: ${props => props.content_font_color};
    color: ${props => props.content_color} !important;
`

const BtnModel = styled.div`
    .row{
        text-align: center;
        padding: 0 12px;
        margin: 0 !important;
    }
    .row .col{
        margin: 0 !important;
        padding: 0 !important;
    }

    @media (max-width: 576px) {
        .row{
            justify-content: ${props => props.role === 'live_stream_manager' ? 'center' : 'space-between'};
        }
        .col{
            display: flex;
            flex: 0 0 48%;
            justify-content: space-between;
            white-space: nowrap;
        }
        .badge-pill{
            margin-left: 80%;
            margin-top: -10px;
        }
        .col .btn{
            width: 85%;
            margin: 0;
            box-sizing: border-box;
        }
        .col :last-child{
            width: 10%;
            padding: 0;
            margin: 0 4px 0 0;
        }
    }
    @media (max-width: 425px){
        .row {
            display: block !important;
        }
        .badge-pill{
            margin-left: 87%;
            margin-top: 0px;
        }
        .col .btn{
            width: 90%;
            margin: 10px 0;
            box-sizing: border-box;
        }
        .col :last-child{
            margin: 0;
        }
    }
`

const BtnAdmin = styled.div`
    padding : 0 32px;
    @media  (max-width: 425px) {
        .row{
            margin: 0 !important;
            justify-content: space-between;
        }
        .row .col{
            margin: 0;
            padding: 0;
        }
        .row :nth-child(1){
            flex: 0 0 15%;
        }
        .row .admin-promotion-btn{
            flex: 0 0 70%;
            display: flex;
            justify-content: space-between;
        }
        .col .btn{
            margin: 0;
        }
        .admin-promotion-btn .btn{
            flex: 0 0 85%;
        }
        .admin-promotion-btn :last-child{
            flex: 0 0 10%;
            margin: 0;
            padding: 0;
        }
    }
    @media  (max-width: 300px) {
        .row {
            display: block !important;
        }
        .admin-promotion-btn{
            margin: 10px 0 0 0 !important;
        }
    }
`

class Theme2BlogPage extends React.Component {
    constructor() {
        super()
        this.state = {
            blogs: {},
            isLoading: false,
            isBlogOpen: false,
            index: -1,
            openEditBlogDialoag: false,
            description: '',
            postId: '',
            selectedIndex: 0,
            totalBannerImages: 0,
            isDropdownOpen: false,
            dropdownIndex: -1,
            galleryIndex: 0,
            openReuseBlogDialog: false,
            contentUrl: '',
            blurGalleryImages: [],
            blogType: '',
            privateBlur: false,
            payment_cascade: {
                error_from: '',
                transaction_id: ''
            },
            payment_request: {},
            is_unlock_by_crypto: false,
            isPromotionApplied: false,
            promotionId: false,
            unlockBlogId: false,
            showAddFundPopup: false,
            remainAmount: 0,
            feedTagId: '',
            openDialogCategory: false,
            navHeight: 0,
            clientWidth: 576
        }

        this.openDialogBlog = this.openDialogBlog.bind(this)
        this.closeDialogBlog = this.closeDialogBlog.bind(this)
        this.deleteBlog = this.deleteBlog.bind(this)
        this.openEditBlogDialog = this.openEditBlogDialog.bind(this)
        this.handleEditBlogDialogClose = this.handleEditBlogDialogClose.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.addPageNumbers = this.addPageNumbers.bind(this)
        this.refreshBlurImage = this.refreshBlurImage.bind(this)
        this.goToPreviousBannerImage = this.goToPreviousBannerImage.bind(this)
        this.goToNextBannerImage = this.goToNextBannerImage.bind(this)
        this.rotateImageLeft = this.rotateImageLeft.bind(this)
        this.rotateImageRight = this.rotateImageRight.bind(this)
        this.openDropdown = this.openDropdown.bind(this)
        this.closeDropdown = this.closeDropdown.bind(this)
        this.unlockContent = this.unlockContent.bind(this)
        this.createVideoProcessingRequest = this.createVideoProcessingRequest.bind(this)
        this.sendUnlockContentRequest = this.sendUnlockContentRequest.bind(this)
        this.openReuseBlogDialogBox = this.openReuseBlogDialogBox.bind(this)
        this.handleReuseBlogDialogClose = this.handleReuseBlogDialogClose.bind(this)
        this.listenToScroll = this.listenToScroll.bind(this)
        this.scrollToPost = this.scrollToPost.bind(this)
        this.resetSelectedFeedTag = this.resetSelectedFeedTag.bind(this)
        this.hideAddFundForm = this.hideAddFundForm.bind(this)
        this.setFeedTag = this.setFeedTag.bind(this)
        this.getBlogs = this.getBlogs.bind(this)
        this.changePage = this.changePage.bind(this)
        this.getNavHeight = this.getNavHeight.bind(this)
        this.setClientWidth = this.setClientWidth.bind(this)
    }

    componentDidMount() {
        let data = {
            pageNum: this.props.blog.currentPage !== 0 ? this.props.blog.currentPage : 1
        }
        if (this.props.blog.selectedTag) {
            data.feedTagId = this.props.blog.selectedTag
            this.setState({ openDialogCategory: true })
        } else {
            this.resetSelectedFeedTag()
        }
        if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
            const oldDate = moment(localStorage.getItem('last_feed_visited'))
            if (oldDate.diff(moment(), 'second') < -900) {
                this.props.setLoading(true)
                localStorage.setItem('last_feed_visited', moment().format('YYYY-MM-DD HH:mm:ss'))
                this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => { })
            }
        }
        if (this.props.blog.currentPage === 0) {
            localStorage.setItem('last_feed_visited', moment().format('YYYY-MM-DD HH:mm:ss'))
            this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => { })
        } else {
            this.setState({ isLoading: false })
        }

        if (!this.props.auth.appSettings.is_pagination_on_exclusive_content_enabled) {
            window.addEventListener('scroll', this.listenToScroll)
        }

        const { isAuthenticated, isAdmin } = this.props.auth

        if (isAuthenticated && (window.orientation !== undefined)) {
            let showProgressiveWebPopUp = localStorage.getItem('showProgressiveWebPopUp')
            if (showProgressiveWebPopUp === 'never') {
                // DO NOTHING
            } else if (!showProgressiveWebPopUp) {
                localStorage.setItem('showProgressiveWebPopUp', 1)
            } else if (showProgressiveWebPopUp !== 'false' && Number(showProgressiveWebPopUp) < 50) {
                showProgressiveWebPopUp = Number(showProgressiveWebPopUp) + 1
                localStorage.setItem('showProgressiveWebPopUp', showProgressiveWebPopUp)
            }
        }

        if (isAuthenticated && isAdmin === false) {
            const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
            if (differentPasswordPopupReminderCount && differentPasswordPopupReminderCount && differentPasswordPopupReminderCount <= 50) {
                localStorage.setItem('differentPasswordPopupReminderCount', Number(differentPasswordPopupReminderCount) + 1)
            }
        }

        this.getNavHeight()
        this.setClientWidth()

        window.addEventListener('resize', () => {
            this.getNavHeight()
            this.setClientWidth()
        })

    }

    setClientWidth() {
        this.setState({
            clientWidth: window.innerWidth
        })
    }

    getNavHeight() {
        let navHeight = 0

        let navbar = document.querySelector('.navbar')
        if (navbar) navHeight += navbar.clientHeight

        let bottomNavbar = document.querySelector('#BottomNavBar')
        if (bottomNavbar) navHeight += bottomNavbar.clientHeight

        this.setState({
            navHeight: navHeight
        })
    }

    listenToScroll(e) {
        e.preventDefault()
        if (this.state.isLoading === false && this.props.blog.totalPages > this.props.blog.currentPage && this.props.blog.isLoading === false) {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
            const scrolled = winScroll / height
            if (scrolled > 0.98) {
                this.setState({ isLoading: this.props.blog.isLoading ? false : true }, () => {
                    let data = {
                        pageNum: this.props.blog.currentPage + 1,
                        feedTagId: this.props.blog.selectedTag
                    }

                    this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => {
                        this.setState({ isLoading: false })
                        window.removeEventListener('scroll', {}, false)
                    })
                })
            }
        }
    }

    changePage(pageNum) {
        this.setState({ isLoading: true }, () => {
            let data = {
                pageNum: pageNum,
                feedTagId: this.props.blog.selectedTag,
                isPaginationEnabled: true
            }

            this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => {
                this.setState({ isLoading: false })
                const recentPostsText = document.getElementById('recent-posts')
                if (recentPostsText) {
                    const currentScrollY = document.documentElement.scrollTop
                    const elementTop = recentPostsText.getBoundingClientRect().top + currentScrollY
                    window.scrollTo({
                        top: elementTop,
                        behavior: 'instant'
                    })
                }
            })
        })
    }

    scrollToPost() {
        const hash = window.location.hash.replace('#', '')
        const post = document.getElementById(hash)
        if (post !== null) {
            window.scrollTo({
                top: post.offsetTop,
                behavior: 'smooth'
            })
            this.props.navigate('/')
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id] !== undefined) {
            let favicon = document.getElementById('favicon')
            favicon.href = (nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id].profile === '' ? getCloudFrontAssetsUrl('faces/avatar.png') :
                nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id].profile)
        }
        this.scrollToPost()
    }

    openDropdown(index) {
        this.setState({ isDropdownOpen: true, index: index, dropdownIndex: index })
    }

    closeDropdown() {
        this.setState({ isDropdownOpen: false, index: -1, dropdownIndex: -1 })
    }

    openDialogBlog(index, galleryIndex) {
        if (galleryIndex === undefined) {
            galleryIndex = 0
        }
        if (this.props.blog.blog[index].processing === false) {
            this.setState({ isBlogOpen: true, index: index, galleryIndex: galleryIndex })
        }
    }

    closeDialogBlog() {
        this.setState({ isBlogOpen: false, index: -1 })
    }

    deleteBlog(id) {
        let confirmation = window.confirm('Are you sure you want to delete this post?')

        if (confirmation === true) {
            this.setState({ index: -1 })
            let data = {
                id: id,
                pageNum: this.props.blog.currentPage,
                feedTagId: this.props.blog.selectedTag
            }

            this.props.deleteBlogPost(data, () => {
                this.setState({ isDropdownOpen: false })
            })
        }
    }

    openEditBlogDialog(id, description, editCaptionBlur, isEditPostLocked, editPostType, mediaPreview = [], media = [], feed_tags) {
        let editPostOption = editPostType === true ? 'Private' : 'Public'
        let feedTagId = feed_tags ? feed_tags.id : ''
        document.querySelector('body').style.overflow = 'hidden'
        this.setState({
            openEditBlogDialoag: true,
            description: description,
            postId: id,
            captionBlur: editCaptionBlur ? editCaptionBlur : false,
            isEditPostLocked: isEditPostLocked,
            editPostOption: editPostOption,
            mediaPreview: mediaPreview,
            media,
            feedTagId: feedTagId
        })
    }

    handleEditBlogDialogClose() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ openEditBlogDialoag: false, postId: '', isDropdownOpen: false })
    }

    openReuseBlogDialogBox(id) {
        document.querySelector('body').style.overflow = 'hidden'
        this.setState({
            openReuseBlogDialog: true,
            postId: id
        })
    }

    handleReuseBlogDialogClose() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ openReuseBlogDialog: false, postId: '', isDropdownOpen: false })
    }

    onChange(e) {
        let value = e.target.value

        if (value === 'true') {
            value = true
        }

        if (value === 'false') {
            value = false
        }
        this.setState({ [e.target.name]: value })
    }

    onSubmit(e) {
        e.preventDefault()

        let data = {
            postId: this.state.postId,
            description: this.state.description,
            pageNum: this.props.blog.currentPage,
            isCaptionBlur: this.state.captionBlur,
            isPublicPost: this.state.editPostOption === 'Public' ? true : false
        }

        this.props.editPost(data, () => {
            this.setState({ openEditBlogDialoag: false, postId: '', isDropdownOpen: false })
            alert('Updated blog data successfully.')
        })
    }

    addPageNumbers() {
        let numbers = []
        let pad = 3
        let { currentPage, totalPages } = this.props.blog

        if (currentPage <= pad) {
            for (let index = 1; index < currentPage; index++) {
                numbers.push(index)
            }
        }

        if (currentPage > pad) {
            for (let index = currentPage - pad; index < currentPage; index++) {
                numbers.push(index)
            }
        }

        numbers.push(currentPage)

        if (totalPages > currentPage) {
            for (let index = currentPage + 1; index <= totalPages; index++) {
                if (index > currentPage + pad) {
                    break
                }
                numbers.push(index)
            }
        }

        return numbers
    }

    refreshBlurImage(id, format) {
        let data = {
            id: id,
            type: 'blog',
            format: format
        }

        this.props.refreshBlurImage(data)
    }

    goToNextBannerImage() {
        let index = this.state.selectedIndex + 1

        if (index >= this.props.auth.bannerImages.length) {
            index = 0
        }

        this.setState({ selectedIndex: index })
    }

    goToPreviousBannerImage() {
        let index = this.state.selectedIndex - 1

        if (index < 0) {
            index = this.props.auth.bannerImages.length - 1
        }

        this.setState({ selectedIndex: index })
    }

    async rotateImageLeft(id, imageIndex, format) {
        const alertMessage = 'Are you sure you want to rotate this image?'
        if (window.confirm(alertMessage)) {
            let data = {
                id: id,
                type: 'blog',
                rotate_angle: -90,
                imageIndex: imageIndex,
                format: format
            }

            await this.props.rotateImage(data)
            this.closeDropdown()
        }
    }

    async rotateImageRight(id, imageIndex, format) {
        const alertMessage = 'Are you sure you want to rotate this image?'
        if (window.confirm(alertMessage)) {
            let data = {
                id: id,
                type: 'blog',
                rotate_angle: 90,
                imageIndex: imageIndex,
                format: format
            }

            await this.props.rotateImage(data)
            this.closeDropdown()
        }
    }

    unlockContent(id, amount, blogType, description, isPromotionApplied) {
        this.sendUnlockContentRequest(id, amount, blogType, description, isPromotionApplied)
    }

    async sendUnlockContentRequest(id, amount, blogType, description, isPromotionApplied) {
        let productSKU = blogType
        if (productSKU === 'photo') {
            productSKU = 'image'
        }
        // Set google analytics add_to_cart event for unlock feed
        googleAnalyticsTrackEvent('add_to_cart', '', amount, productSKU, 'unlock feed', description)

        let confirmationMessage = `Please Confirm Your Purchase of $${amount}.`
        if (this.props.auth.user.default_payment_method === 'crypto_currency') {
            confirmationMessage += `You currently have $${this.props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
        }
        let confirmation
        if (this.props.auth.user.default_payment_method !== 'crypto_currency') {
            confirmation = window.confirm(confirmationMessage)
        } else {
            if (this.props.auth.user.wallet_amount >= parseFloat(amount)) {
                confirmation = window.confirm(confirmationMessage)
            } else {
                confirmation = true
            }
        }
        const { enable_promotion } = this.props.auth.appSettings
        const { lockedContentPromotion } = this.props.promotion
        let promotionId = false
        if (enable_promotion && lockedContentPromotion !== false && isPromotionApplied === true) {
            promotionId = _.get(lockedContentPromotion, '_id', false)
        }
        if (isPromotionApplied === true && promotionId === false) {
            return alert('Sorry promotion expired.')
        }
        if (confirmation === true) {
            let data = {
                blogId: id,
                amount: amount,
                recurring: false,
                email: this.props.auth.user.email,
                action: 'blog',
                blogType: blogType,
                title: description
            }

            if (promotionId !== false && isPromotionApplied === true) {
                data.ribbon_text = lockedContentPromotion.ribbon_text
                data.promotion_id = promotionId
                data.is_promotion_applied = isPromotionApplied
            }

            const payment_api_version = _.get(this.props.auth.appSettings, 'payment_api_version', 'v1')
            if (this.props.auth.user.default_payment_method === 'crypto_currency') {
                this.setState({
                    unlockData: data
                })
                this.unlockUsingCrypto(data)
                return
                // this.setState({
                //     is_unlock_by_crypto: true,
                //     isPromotionApplied,
                //     promotionId,
                //     unlockBlogId: id
                // })
            } else if (payment_api_version === 'v1') {
                if (this.props.auth.appSettings.is_sticky_io_enabled === true) {
                    this.props.blogContentPurchase(data)
                } else {
                    this.props.blogChargeByPrevious(data)
                }
            } else {
                await this.props.blogPurchasePayment(data)
            }
        }
    }

    async unlockUsingCrypto(feedData) {
        let userWalletAmount = this.props.auth.user.wallet_amount
        let showDefaultAlert = true
        if (_.isNumber(feedData) === true) {
            userWalletAmount = feedData
            feedData = this.state.unlockData
            showDefaultAlert = false
        }

        const feedAmount = Number(parseFloat(feedData.amount).toFixed(2))
        const data = {
            payment_for: 'blog',
            content_id: feedData.blogId,
            userId: this.props.auth.user._id
        }
        if (_.get(feedData, 'promotion_id', false) !== false) {
            data.promotion_id = feedData.promotion_id
            data.is_promotion_applied = feedData.is_promotion_applied
        }
        if (userWalletAmount >= feedAmount) {
            const res = await this.props.purchaseFromWallet(data)

            if (res.success === 1) {
                this.props.updateWalletAmount(res.data.wallet_balance)
                this.setState({
                    unlockData: {}
                })
                if (showDefaultAlert || res.data.wallet_balance === 0) {
                    alert(res.data.message)
                } else {
                    alert(`The payment of $${res.data.transaction_amount} was successful. Your content was unlocked. The remainder of your crypto deposit is stored in your wallet and can be used for future transactions.`)
                }
            } else {
                const message = _.get(res, 'message', 'Payment failed.')
                alert(message)
            }
            return
        } else {
            if (userWalletAmount === 0.00) {
                this.setState({
                    showAddFundPopup: true,
                    remainAmount: 0
                })
            } else {
                const remainAmount = Math.ceil(feedAmount - userWalletAmount)
                this.setState({
                    showAddFundPopup: true,
                    remainAmount
                })
            }
        }
    }

    createVideoProcessingRequest(id, blur, contentURL) {
        let data = {
            id: id,
            videoFrom: 'blog',
            udid: '',
            isMassMessage: false,
            s3FilePath: `originalVideos/${contentURL}`,
            shouldGenerateBlurImage: blur
        }

        this.props.sendVideoProcessingRequest(data, () => {
            this.setState({ isDropdownOpen: false })
        })
    }

    async setFeedTag(feedTagId) {
        this.closeDropdown()
        if (feedTagId !== this.props.blog.selectedTag) {
            this.props.selectedFeedTag(feedTagId)
            this.props.setLoading(true)
            let data = {
                pageNum: 1,
                feedTagId: feedTagId
            }
            await this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => { })
        }
    }

    async getBlogs() {
        if (this.props.blog.selectedTag) {
            this.props.setLoading(true)
            let data = {
                pageNum: 1,
                feedTagId: this.props.blog.selectedTag
            }
            await this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => { })
        }
    }

    hideAddFundForm() {
        this.setState({ showAddFundPopup: false })
    }

    async resetSelectedFeedTag() {
        this.closeDropdown()
        if (this.props.blog.selectedTag !== '') {
            this.props.setLoading(true)
            this.props.selectedFeedTag('')
            let data = {
                pageNum: 1
            }
            await this.props.getAllBlogs(this.props.auth.user.isAdmin, data, () => { })
        }
        this.setState({
            openDialogCategory: false
        })
    }

    render() {
        const { classes } = this.props
        const {
            text_exclusive_content_banner,
            content_color,
            content_font_color,
            theme_2_banner_images_opacity,
            theme_2_show_social_media_icon,
            theme_2_instagram_url,
            theme_2_twitter_url,
            theme_2_snapchat_url,
            theme_2_facebook_url,
            theme_2_youtube_url,
            theme_2_website_url,
            theme_2_tiktok_url,
            theme_2_amazon_wishlist_url,
            theme_2_twitch_url,
            theme_2_discord_url,
            theme_2_banner_top_percentage,
            theme_2_banner_left_percentage,
            theme_2_banner_text_image_url,
            theme_2_banner_text_image_size,
            theme_2_banner_font_family,
            theme_2_banner_text,
            exclusive_content_recent_posts_text,
            theme_2_banner_subscribe_now_text,
            enable_watermark,
            watermark_color,
            watermark_size,
            watermark_position,
            theme_2_banner_text_font_size,
            theme_2_banner_subscribe_now_text_font_size,
            theme_2_banner_subscribe_now_type,
            theme_2_banner_subscribe_now_button_color,
            theme_2_banner_subscribe_now_button_font_color,
            theme_2_banner_subscribe_now_font_padding,
            theme_2_banner_extra_button_redirect_url,
            theme_2_banner_extra_button_text,
            theme_2_banner_extra_button_color,
            theme_2_banner_extra_button_font_color,
            theme_2_banner_extra_button_font_padding,
            card_background_color,
            site_font_color,
            theme_2_banner_text_color,
            theme_2_banner_arrow_icon_color,
            theme_2_banner_subscribe_now_text_link_color,
            lock_icon_color,
            enable_group_live_streaming,
            theme_2_banner_subscribe_now_button_font_style,
            theme_2_banner_subscribe_now_button_radius,
            theme_2_banner_subscribe_now_button_border_size,
            theme_2_banner_subscribe_now_button_border_color,
            theme_2_banner_subscribe_now_button_padding,
            theme_2_banner_subscribe_now_button_above_spacing,
            enable_promotion,
            promotion_settings,
            blog_unlock_button_text_font_size,
            text_exclusive_content_banner_font_style,
            text_exclusive_content_banner_font_size,
            chat_navbar_name,
            feed_tags,
            feed_tags_enable,
            feed_tag_filter_button_text,
            theme_2_banner_video_url,
            theme_2_banner_video_url_mobile,
            enable_theme_2_banner_video
        } = this.props.auth.appSettings

        const { isAdmin, ccbillSubscriptionStatus, role } = this.props.auth.user
        const { userUnreadMessage } = this.props.auth.counts

        const showChatWithMeButton = isAdmin === false && (ccbillSubscriptionStatus === '2' || ccbillSubscriptionStatus === '1') ? true : false
        let ribbonPosition = _.get(promotion_settings, 'subscription_promotion_ribbon_position', 'above menu')
        if (isAdmin === true || ccbillSubscriptionStatus === '2' || ccbillSubscriptionStatus === '1') {
            ribbonPosition = _.get(promotion_settings, 'locked_content_promotion_ribbon_position', 'above menu')
        }

        let show_instagram_icon = (theme_2_instagram_url === '' || theme_2_instagram_url === undefined) ? false : true
        let show_twitter_icon = (theme_2_twitter_url === '' || theme_2_twitter_url === undefined) ? false : true
        let show_snapchat_icon = (theme_2_snapchat_url === '' || theme_2_snapchat_url === undefined) ? false : true
        let show_facebook_icon = (theme_2_facebook_url === '' || theme_2_facebook_url === undefined) ? false : true
        let show_youtube_icon = (theme_2_youtube_url === '' || theme_2_youtube_url === undefined) ? false : true
        let show_website_icon = (theme_2_website_url === '' || theme_2_website_url === undefined) ? false : true
        let show_tiktok_icon = (theme_2_tiktok_url === '' || theme_2_tiktok_url === undefined) ? false : true
        let show_amazon_wishlist_icon = (theme_2_amazon_wishlist_url === '' || theme_2_amazon_wishlist_url === undefined) ? false : true
        let show_twitch_icon = (theme_2_twitch_url === '' || theme_2_twitch_url === undefined) ? false : true
        let show_discord_url = (theme_2_discord_url === '' || theme_2_discord_url === undefined) ? false : true
        let bannerImageDimnsions = theme_2_banner_text_image_size.split(' ')
        if (bannerImageDimnsions.length !== 4) {
            bannerImageDimnsions = ['250px', '160px', '130px', '60px']
        }

        let bannerHeaderBannerTitleFontSize = theme_2_banner_text_font_size.split('|')
        if (bannerHeaderBannerTitleFontSize.length !== 4) {
            bannerHeaderBannerTitleFontSize = ['40px', '90px', '100px', '120px']
        }

        let bannerHeaderSubscribeNowFontSize = theme_2_banner_subscribe_now_text_font_size.split('|')
        if (bannerHeaderSubscribeNowFontSize.length !== 4) {
            bannerHeaderSubscribeNowFontSize = ['13px', '30px', '30px', '35px']
        }
        let exclusiveContentBannerFontSize = text_exclusive_content_banner_font_size.split('|')
        if (exclusiveContentBannerFontSize.length !== 4) {
            exclusiveContentBannerFontSize = ['16px', '17px', '18px', '20px']
        }
        let blogUnlockButtonTextFontSize = blog_unlock_button_text_font_size.split('|')
        if (blogUnlockButtonTextFontSize.length !== 4) {
            blogUnlockButtonTextFontSize = ['12px', '12px', '12px', '12px']
        }

        let showNextAndPreviousButton = this.props.auth.bannerImages.length > 1 ? true : false

        const { isAuthenticated, isModel, bannerImages, isPhotoIdModalOpen } = this.props.auth

        let redirectUrl = isAuthenticated ? '/membership' : '/register'

        let subscribeNowFontPadding = theme_2_banner_subscribe_now_font_padding
        let subscribeNowButtonFontPadding = theme_2_banner_subscribe_now_button_padding

        if (_.isEmpty(subscribeNowFontPadding)) {
            subscribeNowFontPadding = '8px 30px 8px 30px'
        }

        if (_.isEmpty(subscribeNowButtonFontPadding)) {
            subscribeNowButtonFontPadding = '8px 30px 8px 30px'
        }

        let subscribeNowText = <HeaderSubscribeNow className='centered mx-auto' theme2BannerTextImageUrl={theme_2_banner_text_image_url} theme2FontSize={bannerHeaderSubscribeNowFontSize}>
            <Link to={redirectUrl} style={{ color: theme_2_banner_subscribe_now_text_link_color, textDecoration: 'none', padding: subscribeNowFontPadding }}>{theme_2_banner_subscribe_now_text}</Link>
        </HeaderSubscribeNow>

        if (theme_2_banner_subscribe_now_type === 'button') {
            subscribeNowText = <HeaderSubscribeNow className='centered mx-auto' marginTop={theme_2_banner_subscribe_now_button_above_spacing} theme2BannerTextImageUrl={theme_2_banner_text_image_url} theme2FontSize={bannerHeaderSubscribeNowFontSize}>
                <Link to={redirectUrl} style={{
                    color: theme_2_banner_subscribe_now_button_font_color,
                    textDecoration: 'none',
                    background: theme_2_banner_subscribe_now_button_color,
                    fontFamily: theme_2_banner_subscribe_now_button_font_style,
                    borderRadius: theme_2_banner_subscribe_now_button_radius,
                    border: `${theme_2_banner_subscribe_now_button_border_size} solid`,
                    borderColor: theme_2_banner_subscribe_now_button_border_color,
                    padding: subscribeNowButtonFontPadding
                }}>
                    {theme_2_banner_subscribe_now_text}
                </Link>
            </HeaderSubscribeNow>
        }

        let ExtraButton = null
        if (!_.isEmpty(theme_2_banner_extra_button_redirect_url)) {
            let theme2ExtraButtonFontPadding = theme_2_banner_extra_button_font_padding

            if (_.isEmpty(theme2ExtraButtonFontPadding)) {
                theme2ExtraButtonFontPadding = '8px 30px 8px 30px'
            }

            ExtraButton = <HeaderSubscribeNowVideo className='centered mx-auto mt-5' theme2BannerTextImageUrl={theme_2_banner_text_image_url} theme2FontSize={bannerHeaderSubscribeNowFontSize}>
                <a href={theme_2_banner_extra_button_redirect_url} style={{
                    color: theme_2_banner_extra_button_font_color,
                    textDecoration: 'none',
                    background: theme_2_banner_extra_button_color,
                    padding: theme2ExtraButtonFontPadding,
                    borderRadius: '16px'
                }}>{theme_2_banner_extra_button_text}</a>
            </HeaderSubscribeNowVideo>
        }

        let posts = {}
        posts = this.props.blog.blog
        const { lockedContentPromotion } = this.props.promotion
        let promotionPercentage = 0

        if (enable_promotion && lockedContentPromotion !== false && lockedContentPromotion.type === 'LOCKED_CONTENT') {
            if (lockedContentPromotion.applicable_to === 'EXCLUSIVE_CONTENT_AND_MASS_MESSAGE' || lockedContentPromotion.applicable_to === 'EXCLUSIVE_CONTENT') {
                promotionPercentage = parseInt(lockedContentPromotion.discount_percentage)
            }
        }

        let blogCards = []
        for (let index = 0; index < posts.length; index++) {
            const post = posts[index]
            const profilePic = (post.user.avatarUrl) ? post.user.avatarUrl : getCloudFrontAssetsUrl('faces/avatar.png')
            blogCards.push(
                <BlogCard
                    id={post._id}
                    format={post.format}
                    blogType={post.blogType}
                    lock={this.props.auth.isAuthenticated && this.props.auth.user.payment.membership === true ? false : !post.public}
                    locked={!post.public}
                    blur={post.privateBlur}
                    contentURL={post.url}
                    description={post.description}
                    authorName={post.user.name}
                    avatarUrl={profilePic}
                    date={post.date}
                    height={800}
                    key={post._id}
                    index={index}
                    openDlgBlog={this.openDialogBlog}
                    thumbnailUrl={post.thumbnailUrl}
                    deletePost={this.deleteBlog}
                    isAdmin={this.props.auth.user.isAdmin}
                    isAuthenticated={this.props.auth.isAuthenticated}
                    processing={post.processing}
                    openEditBlogDialog={this.openEditBlogDialog}
                    blurUrl={post.blurUrl}
                    isBlurLoading={this.props.admin.isBlurLoading}
                    refreshBlurImage={this.refreshBlurImage}
                    isModel={isModel}
                    role={this.props.auth.user.role}
                    rotateImageLeft={this.rotateImageLeft}
                    rotateImageRight={this.rotateImageRight}
                    openDropdown={this.openDropdown}
                    closeDropdown={this.closeDropdown}
                    isDropdownOpen={this.state.isDropdownOpen}
                    dropdownIndex={this.state.dropdownIndex}
                    isLocked={this.props.auth.isAuthenticated && this.props.auth.user.isAdmin === true ? false : post.isLocked}
                    amount={post.amount}
                    unlockContent={this.unlockContent}
                    isPaymentProcessing={this.props.ccbill.isBlogPaymentLoading}
                    contentColor={content_color}
                    contentFontColor={content_font_color}
                    createVideoProcessingRequest={this.createVideoProcessingRequest}
                    isPostLocked={post.isLocked}
                    enableWatermark={enable_watermark}
                    watermarkColor={watermark_color}
                    watermarkSize={watermark_size}
                    watermarkPosition={watermark_position}
                    userId={this.props.auth.user._id}
                    cardBackgroundColor={card_background_color}
                    siteFontColor={site_font_color}
                    captionBlur={post.captionBlur}
                    postCaptionBlurIntensity={this.props.auth.appSettings.post_caption_blur_intensity}
                    userMembership={_.isEmpty(this.props.auth.user) ? false : this.props.auth.user.payment.membership}
                    galleryImages={post.gallery}
                    blurGalleryImages={post.blurUrlGallery}
                    error={(post.isError) ? post.isError : false}
                    openReuseBlogDialogBox={this.openReuseBlogDialogBox}
                    lockIconColor={lock_icon_color}
                    subscribeText={this.props.auth.appSettings.subscribe_text_for_locked_content}
                    promotionPercentage={promotionPercentage}
                    lineThroughColor={promotion_settings.price_strike_through_color}
                    content_length={post.content_length}
                    unlockButtonFontSize={blogUnlockButtonTextFontSize}
                    thumbnailUrlGallery={post.thumbnailUrlGallery}
                    contentLeftForProcessing={post.contentLeftForProcessing}
                    isPreviewEnable={post.isPreviewEnable}
                    contentCount={post.contentCount}
                    previewEnableImages={post.previewEnableImages}
                    showNumberOfGalleryImage={post.showNumberOfGalleryImage}
                    show_content_length={post.show_content_length}
                    blogSubType={post.blogSubType}
                    is_published={post.is_published}
                    gallery_process_error_index={post.gallery_process_error_index}
                    showTimestamp={this.props.auth.appSettings.timestamp_visibility.home_feed}
                    media={post.media}
                    media_preview={post.media_preview}
                    total_comments={post.total_comments}
                    total_likes={post.total_likes}
                    user_feed_like={post.user_feed_like}
                    feed_tags={post.feed_tags}
                    onGetBlogs={this.getBlogs}
                />
            )
        }

        // let image
        // const imageStyle = { maxWidth: '100%', maxHeight: '80vh', margin: 'auto', display: 'block', pointerEvents: 'none' }

        // if (enable_watermark) {
        //     image = <OverlayContainer userId={this.props.auth.user._id}>
        //         <img className='' draggable={false} src={this.state.index >= 0 ? posts[this.state.index].url : ''} style={imageStyle} alt='' />
        //     </OverlayContainer>
        // } else {
        //     imageStyle.objectFit = 'contain'
        //     image = <img className='' draggable={false} src={this.state.index >= 0 ? posts[this.state.index].url : ''} style={imageStyle} alt='' />
        // }

        return (
            <div>
                {isPhotoIdModalOpen ? <PhotoIdModal /> : null}
                <HeaderBanner opacity={theme_2_banner_images_opacity}>
                    {enable_theme_2_banner_video
                        ?
                        <>{
                            (this.state.clientWidth < 576)
                                ? <video
                                    // Video banner for mobile screens
                                    src={theme_2_banner_video_url_mobile ? theme_2_banner_video_url_mobile : theme_2_banner_video_url}
                                    type='video/mp4'
                                    playsInline autoPlay loop muted
                                    style={{ width: '100vw', maxHeight: `calc(100vh - ${this.state.navHeight}px)` }}>
                                </video>
                                : <video
                                    src={theme_2_banner_video_url}
                                    type='video/mp4'
                                    playsInline autoPlay loop muted
                                    style={{ width: '100vw' }}>
                                </video>
                        }</>
                        : <>
                            <img className='img-fluid' src={bannerImages[this.state.selectedIndex]} alt='' draggable={false} />
                            {showNextAndPreviousButton ? <PreviousImage onClick={this.goToPreviousBannerImage}>
                                <i style={{ color: theme_2_banner_arrow_icon_color }} className='fas fa-chevron-left fa-2x'></i>
                            </PreviousImage> : null}
                            {showNextAndPreviousButton ? <NextImage onClick={this.goToNextBannerImage}>
                                <i style={{ color: theme_2_banner_arrow_icon_color }} className='fas fa-chevron-right fa-2x'></i>
                            </NextImage> : null}
                        </>}

                    <Header theme2BannerTopPercentage={theme_2_banner_top_percentage} theme2BannerLeftPercentage={theme_2_banner_left_percentage}>
                        {theme_2_banner_text_image_url ? <HeaderBannerTitleImage src={theme_2_banner_text_image_url} theme={{ imageSize: bannerImageDimnsions }}></HeaderBannerTitleImage> :
                            <HeaderBannerTitle className='centered' theme2BannerFontFamily={theme_2_banner_font_family} theme2FontSize={bannerHeaderBannerTitleFontSize} theme2BannerTextColor={theme_2_banner_text_color}>{theme_2_banner_text}</HeaderBannerTitle>
                        }
                        {this.props.auth.isAuthenticated && this.props.auth.user.payment.membership === true ?
                            null
                            :
                            <>{subscribeNowText}</>
                        }
                        {ExtraButton}
                    </Header>

                </HeaderBanner>
                {ribbonPosition === 'below hero' &&
                    <PromotionNotificationBar />
                }
                <div className='container'>
                    <PaymentIcons />
                    <WebsiteFontColor>
                        <HeaderText textExclusiveContentBannerFontStyle={text_exclusive_content_banner_font_style} exclusiveContentBannerFontSize={exclusiveContentBannerFontSize}>
                            {text_exclusive_content_banner.split('\n').map((item, key) => {
                                return <div key={key}>{item}<br /></div>
                            })}
                        </HeaderText>
                        {theme_2_show_social_media_icon ? <div className='row row-eq-height pt-3'>
                            <div className='col text-center'>
                                {show_instagram_icon ?
                                    <a href={theme_2_instagram_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/instagram.png')} alt='Instagram' />
                                    </a> : null}
                                {show_twitter_icon ?
                                    <a className='pl-4' href={theme_2_twitter_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/twitter.png')} alt='Twitter' />
                                    </a> : null}
                                {show_snapchat_icon ?
                                    <a className='pl-4' href={theme_2_snapchat_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/snapchat.png')} alt='Snapchat' />
                                    </a> : null}
                                {show_facebook_icon ?
                                    <a className='pl-4' href={theme_2_facebook_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/facebook.png')} alt='Facebook' />
                                    </a> : null}
                                {show_youtube_icon ?
                                    <a className='pl-4' href={theme_2_youtube_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/youtube.png')} alt='YouTube' />
                                    </a> : null}
                                {show_website_icon ?
                                    <a className='pl-4' href={theme_2_website_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/website.png')} alt='website' />
                                    </a> : null}
                                {show_tiktok_icon ?
                                    <a className='pl-4' href={theme_2_tiktok_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/tiktok.png')} alt='tiktok' />
                                    </a> : null}
                                {show_amazon_wishlist_icon ?
                                    <a className='pl-4' href={theme_2_amazon_wishlist_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/amazon.png')} alt='amazon' />
                                    </a> : null}
                                {show_twitch_icon ?
                                    <a className='pl-4' href={theme_2_twitch_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/twitch.png')} alt='twitch' />
                                    </a> : null}
                                {show_discord_url ?
                                    <a className='pl-4' href={theme_2_discord_url} target='_blank' rel='noopener noreferrer'>
                                        <SocialMediaIcons src={getCloudFrontAssetsUrl('social-icons/discord.png')} alt='discord' />
                                    </a> : null}
                            </div>
                        </div> : null}
                    </WebsiteFontColor>
                    {showChatWithMeButton === true &&
                        <div className='text-center mt-3'>
                            <Link to={'/private-chat'}>
                                <button className='btn' style={{ backgroundColor: content_color, color: content_font_color }}>{chat_navbar_name}</button>
                                {userUnreadMessage > 0 &&
                                    <BadgeSpan className='badge badge-pill py-1' content_color={content_color} content_font_color={content_font_color}>{userUnreadMessage}</BadgeSpan>
                                }
                            </Link>
                        </div>
                    }
                    {isAdmin && role !== 'live_stream_manager' ?
                        <div className='row mt-5 justify-content-center'>
                            <div className='col-lg-8'>
                                <NewPostBlogForm />
                            </div>
                        </div>
                        : null}
                    {(role === 'model' || role === 'live_stream_manager') &&
                        <div
                            className={classNames('row justify-content-center mb-3', {
                                'mt-5': role === 'live_stream_manager'
                            })}
                        >
                            <BtnModel className='col col-lg-8 col-md-8 p-0' role={role}>
                                <div className='row'>
                                    {enable_group_live_streaming &&
                                        <div className='col'>
                                            <button className='btn' style={{ backgroundColor: content_color, color: content_font_color, position: 'relative' }} onClick={this.props.onStartGoLive}>
                                                GO LIVE NOW
                                            </button>
                                            <BadgeSpan className='badge badge-pill py-1' content_color={content_color} content_font_color={content_font_color}>{this.props.onlineUserCount} / {this.props.onlineAllUsersCount}</BadgeSpan>
                                            <HelperButton onClick={this.props.setHelperModelOpen} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                <i className='fas fa-question-circle'></i>
                                            </HelperButton>
                                        </div>
                                    }
                                    {enable_promotion === true && role !== 'live_stream_manager' &&
                                        <div className='col'>
                                            <button className='btn' onClick={this.props.setPromotionModelOpen} style={{ backgroundColor: content_color, color: content_font_color }}>
                                                {this.props.promotionButtonText}
                                            </button>
                                            <HelperButton onClick={this.props.setHelperModelOpenForPromotion} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                <i className='fas fa-question-circle'></i>
                                            </HelperButton>
                                        </div>
                                    }
                                </div>
                            </BtnModel>
                        </div>
                    }
                    {(this.props.auth.user.role === 'admin' || this.props.auth.user.role === 'sub_admin' || this.props.auth.user.role === 'content_manager') &&
                        <div className='row justify-content-center mb-3'>
                            <BtnAdmin className='col col-lg-8'>
                                <div className='row' style={{ textAlign: 'center' }}>
                                    <div className='col'>
                                        <button className='btn' style={{ backgroundColor: content_color, color: content_font_color }}>
                                            <i className='fas fa-eye'></i>
                                        </button>
                                        <BadgeSpan className='badge badge-pill py-1' content_color={content_color} content_font_color={content_font_color}>{this.props.onlineUserCount} / {this.props.onlineAllUsersCount}</BadgeSpan>
                                    </div>
                                    {enable_promotion === true &&
                                        <div className='col admin-promotion-btn' style={{ whiteSpace: 'nowrap' }}>
                                            <button className='btn' onClick={this.props.setPromotionModelOpen} style={{ backgroundColor: content_color, color: content_font_color, width: '175px' }}>
                                                {this.props.promotionButtonText}
                                            </button>
                                            <HelperButton onClick={this.props.setHelperModelOpenForPromotion} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                <i className='fas fa-question-circle'></i>
                                            </HelperButton>
                                        </div>
                                    }
                                </div>
                            </BtnAdmin>
                        </div>
                    }
                    <WebsiteFontColor>
                        <div className='row justify-content-center' style={{ paddingTop: '15px' }}>
                            <div className='col-8' style={{ border: `0.5px solid ${site_font_color}` }}>
                            </div>
                        </div>
                        <RecentPostText id='recent-posts' className='p-5 text-center'>{exclusive_content_recent_posts_text}</RecentPostText>
                        {(feed_tags_enable && this.props.blog.tagList.length > 0 && this.props.auth.user._id !== undefined) && <div className='d-flex flex-row-reverse pb-5'>
                            {this.state.openDialogCategory ?
                                <CategoryTags
                                    tagList={feed_tags}
                                    onClick={this.setFeedTag}
                                    onReset={this.resetSelectedFeedTag}
                                    selectedTag={this.props.blog.selectedTag}
                                    disabled={this.props.blog.isLoading}
                                    showCount={false}
                                /> :
                                <button className='btn' onClick={() => {
                                    this.setState({
                                        openDialogCategory: true
                                    })
                                    this.closeDropdown()
                                }} style={{ backgroundColor: content_color, color: content_font_color }}>
                                    {feed_tag_filter_button_text} <i className='fas fa-filter'></i>
                                </button>}
                        </div>}
                        {this.props.blog.isLoading ?
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Loader loading={true} size={10} />
                            </div> : <>
                                <div className='row' onTouchMove={this.props.auth.appSettings.is_pagination_on_exclusive_content_enabled
                                    ? null
                                    : this.listenToScroll}>
                                    {blogCards.length > 0
                                        ?
                                        // hide blogs while switching pages in pagination
                                        !(this.props.auth.appSettings.is_pagination_on_exclusive_content_enabled && this.state.isLoading) &&
                                        blogCards
                                        : <div className='text-center my-4'><h4>No Posts Found</h4></div>}
                                </div>
                            </>
                        }
                        {this.state.isLoading === true ?
                            <div className='text-center'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <Loader loading={true} size={10} />
                                </div>
                            </div> : null
                        }
                        {(this.props.auth.appSettings.is_pagination_on_exclusive_content_enabled) &&
                            <div className='pagination justify-content-center'>
                                <Pagination
                                    totalPages={this.props.blog.totalPages}
                                    currentPage={this.props.blog.currentPage}
                                    contentColor={content_color}
                                    contentFontColor={content_font_color}
                                    isFiltered={false}
                                    onItemClick={this.changePage}
                                    showFirstAndLastBtn={true}
                                    isLoading={this.state.isLoading || this.props.blog.isLoading}
                                />
                            </div>
                        }
                    </WebsiteFontColor>
                </div>
                {this.state.isBlogOpen === true && this.state.index >= 0 && posts[this.state.index].blogType === 'gallery' &&
                    <BlogGalleryDialog
                        galleryImages={posts[this.state.index].gallery}
                        closeDialogBlog={this.closeDialogBlog}
                        classes={classes}
                        userId={this.props.auth.user._id}
                        galleryIndex={this.state.galleryIndex}
                        isPreviewEnable={posts[this.state.index].isPreviewEnable}
                        previewEnableImages={posts[this.state.index].previewEnableImages}
                        isLocked={posts[this.state.index].isLocked}
                        contentCount={posts[this.state.index].contentCount}
                        thumbImages={posts[this.state.index].thumbnailUrlGallery}
                        media={posts[this.state.index].media}
                        contentFrom='blog'
                    />
                }
                {this.state.isBlogOpen === true && this.state.index >= 0 && posts[this.state.index].blogType !== 'gallery' &&
                    <FullScreenModelPopUpDialog
                        type={this.state.index >= 0 && posts[this.state.index].blogType === 'video' ? 'video' : 'photo'}
                        url={this.state.index >= 0 ? posts[this.state.index].media[0].url : ''}
                        handleClose={this.closeDialogBlog}
                    />
                }
                {this.state.openEditBlogDialoag ?
                    <EditBlog
                        onSubmit={this.onSubmit}
                        onChange={this.onChange}
                        handleEditBlogDialogClose={this.handleEditBlogDialogClose}
                        description={this.state.description}
                        captionBlur={this.state.captionBlur}
                        isEditPostLocked={this.state.isEditPostLocked}
                        editPostOption={this.state.editPostOption}
                        mediaPreview={this.state.mediaPreview}
                        postId={this.state.postId}
                        media={this.state.media}
                        feedTagId={this.state.feedTagId}
                    />
                    : null
                }
                {this.state.openReuseBlogDialog &&
                    <NewReusePost
                        handleReuseBlogDialogClose={this.handleReuseBlogDialogClose}
                        editBlogId={this.state.postId}
                    />
                }
                {this.state.showAddFundPopup === true &&
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                            <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                                <div className='modal-header align-items-center'>
                                    <button className='close' onClick={() => {
                                        this.setState({
                                            showAddFundPopup: false
                                        })
                                    }} style={{ color: site_font_color, opacity: 1 }} />
                                </div>
                                <div className='modal-body'>
                                    <div className='container'>
                                        <AddFundForm
                                            onHideAddFund={this.hideAddFundForm}
                                            type='blog'
                                            transactionAmount={Number(this.state.unlockData.amount)}
                                            remainAmount={this.state.remainAmount}
                                            onCompleteTransaction={
                                                (updatedBalance) => {
                                                    this.setState({
                                                        showAddFundPopup: false
                                                    })
                                                    if (updatedBalance) {
                                                        this.unlockUsingCrypto(updatedBalance)
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Theme2BlogPage.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired,
    ccbill: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    getAllBlogs: PropTypes.func.isRequired,
    deleteBlogPost: PropTypes.func.isRequired,
    editPost: PropTypes.func.isRequired,
    refreshBlurImage: PropTypes.func.isRequired,
    rotateImage: PropTypes.func.isRequired,
    blogChargeByPrevious: PropTypes.func.isRequired,
    sendVideoProcessingRequest: PropTypes.func.isRequired,
    toggleNewPaymentModal: PropTypes.func.isRequired,
    onlineUserCount: PropTypes.number.isRequired,
    setHelperModelOpen: PropTypes.func.isRequired,
    setHelperModelOpenForPromotion: PropTypes.func.isRequired,
    onStartGoLive: PropTypes.func.isRequired,
    onlineAllUsersCount: PropTypes.number.isRequired,
    setPromotionModelOpen: PropTypes.func.isRequired,
    promotion: PropTypes.object.isRequired,
    promotionButtonText: PropTypes.string,
    blogContentPurchase: PropTypes.func.isRequired,
    blogPurchasePayment: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    selectedFeedTag: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    chat: state.chat,
    blog: state.blog,
    admin: state.admin,
    ccbill: state.ccbill,
    promotion: state.promotion,
    utility: state.utility
})

export default connect(
    mapStateToProps,
    {
        getAllBlogs,
        deleteBlogPost,
        editPost,
        refreshBlurImage,
        rotateImage,
        blogChargeByPrevious,
        sendVideoProcessingRequest,
        toggleNewPaymentModal,
        blogContentPurchase,
        blogPurchasePayment,
        purchaseFromWallet,
        updateWalletAmount,
        selectedFeedTag,
        setLoading
    }
)(withRouter(withStyles(useStyles)(Theme2BlogPage)))
