import React from 'react'
import PropTypes from 'prop-types'
import PulseLoader from 'react-spinners/PulseLoader'
import { withRouter } from '../WithRouter'
import { connect } from 'react-redux'
import styled from 'styled-components'

const LoaderStyle = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 10px;
`

function Loader(props) {
    const { site_font_color } = props.auth.appSettings

    return props.loading === true &&
        <LoaderStyle style={{ ...props.style }}>
            <PulseLoader color={props.color || site_font_color} loading={props.loading} size={props.size} />
        </LoaderStyle>
}

Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
    size: PropTypes.number,
    auth: PropTypes.object.isRequired,
    color: PropTypes.string,
    style: PropTypes.object
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(Loader))
