import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isIOS } from 'react-device-detect'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { FILE_SIZE_LIMIT_IN_BYTE, M4V } from '../../utils/constant'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import Button from '../Layouts/Button'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import axios from 'axios'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import { addMedia, incrementMediaCount, saveMediaData, updateMediaCountInCategory } from '../../actions/mediaAction'
import { getFileExtension } from '../../utils/common'

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: dashed;
    border-width: 1px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};

    .plus-button {
        height: 150px;
        max-width: 150px;
    }

    .drop-zone-div {
        text-align: center;
        outline: transparent;
    }
`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }

    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${props => props.site_font_color};
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video{
        object-fit: cover;
        border-radius: 5px;
        height: 150px;
        width: 100%; 
        position: relative;
        background-color: black;
    }
    
    .progress {
        border-radius: 5px;
    }

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video{
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const CloseButton = styled.button`
    right: 5px;
    top: 0;
    position: absolute;
    border-radius: 82%;
    width: 24px;
    background-color: ${props => props.content_color} !important;
    color: ${props => props.content_font_color} !important;
    cursor: pointer;
    z-index: 5;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: 13%;
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }
`

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;

    input {
        display: none;
    }
`

const MediaProgress = styled.div`
    width: ${props => props.width};
    color: ${props => props.fontColor} !important;
    background-color: ${props => props.backgroundColor} !important;
`

function MediaUpload(props) {
    const { content_color, site_font_color, content_font_color, button_shadow_color, card_header_border_color } = props.auth.appSettings
    const { _id } = props.auth.user
    const { filter } = props.media
    const [media, setMedia] = useState([])
    const [mediaUploaded, setMediaUploaded] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState({})
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const fileInputRef = useRef(null)

    /**
     *
     * @param {string} contentType Media type
     * @param {string} url Presigned url of media
     * @param {object} body File
     * @param {number} mediaIndex Index of media
     * @returns {boolean} returns true for success, otherwise returns false
     */
    const uploadFileUsingPresignedUrl = async (contentType, url, body, mediaIndex = 0) => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })
                const object = {
                    progress: percentCompleted + '%',
                    index: mediaIndex,
                    progressNumber: percentCompleted
                }
                setUploadProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    /**
     *
     * Save media info into database
     * @param {object} data Media data
     */
    const saveMedia = async (data) => {
        const category = props.media.category.find(item => item._id === props.media.filter.activeCategory)
        const { _id, slug } = category
        if (slug !== 'all media') {
            data.category = _id
        }
        data.categorySlug = slug

        const res = await props.saveMediaData(data)
        if (res && (filter.type === data.type || filter.type === 'all')) {
            props.addMedia({ _id: 0, processing: true, type: data.type })
        }

        props.updateMediaCountInCategory({ slug, mediaType: data.type })
        if (slug !== 'all media') {
            props.updateMediaCountInCategory({ slug: 'all media', mediaType: data.type })
        }
        setMediaUploaded(prev => ++prev)
        setUploadProgress({})
    }

    /**
     *
     * @param {object} file File
     * @returns {string} return File type
     */
    function getFileType(file) {
        const fileType = file.type
        if (fileType.startsWith('video/')) return 'video'
        if (fileType.startsWith('image/')) return 'image'
        return null
    }

    /**
     *
     * Upload multiple media one by one
     */
    const handleUpload = async () => {
        try {
            for (let i = 0; i < media.length; i++) {
                let fileExtension = getFileExtension(media[i].selectedFile.name)
                if ([M4V].includes(fileExtension.toLocaleLowerCase())) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                setIsLoading(true)
                const selectedFile = media[i].selectedFile
                const fileType = getFileType(selectedFile) === 'image' ? 'photo' : 'video'
                const presignedUrlData = await getPresignedUrl(selectedFile.name, 'media', fileType)
                if (presignedUrlData.presigned_url !== '') {
                    const fileUploaded = await uploadFileUsingPresignedUrl(fileType, presignedUrlData.presigned_url, selectedFile, i)
                    if (fileUploaded === true) {
                        const data = {
                            path: presignedUrlData.file_name,
                            upload_by: _id,
                            type: fileType,
                            processing: true
                        }
                        saveMedia(data)
                    } else {
                        props.setSweetAlert({ description: 'Error occured while uploading media' })
                    }
                } else {
                    setIsLoading(false)
                    break
                }
            }
        } catch (err) {
            props.setSweetAlert({ description: err.message ? err.message : 'Error occured while uploading media' })
            setIsLoading(false)
        }
    }

    /**
     *
     * @param {string} url Media url
     * @param {string} type Media type
     */
    const openDialog = (url, type) => {
        if (isLoading === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    /**
     *
     * Remove media which are selected for upload
     *
     * @param {number} index Index of media
     */
    const handleRemoveMedia = (index) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null
        }

        const copyMedia = [...media]
        const updatedFilesArray = [
            ...copyMedia.slice(0, index),
            ...copyMedia.slice(index + 1)
        ]
        setMedia(updatedFilesArray)

    }

    /**
     *
     * @param {number} mediaIndex Index of media
     * @param {number} progressNumber Uploading file status (ie. 66 means 66% file is uploaded)
     * @returns {HtmlElement} return HtmlElement
     */
    const uploadProgressInMb = (mediaIndex, progressNumber) => {
        let actualFileSize = 0
        let actualFileUploaded = 0.00
        const selectedFile = media[mediaIndex].selectedFile
        actualFileSize = ((_.get(selectedFile, 'size', 0) / 1024) / 1024).toFixed(2)
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text'>Uploading {actualFileUploaded} MB Of {actualFileSize} MB</div>
    }

    /**
     *
     * @param {Array} files Files
     */
    const addMediaFiles = (files) => {
        for (let index = 0; index < files.length; index++) {
            const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
            if (!acceptExtension.includes(files[index].type)) {
                return props.setSweetAlert({ description: 'Media format is not supported.' })
            }
        }
        const newUploadedFiles = []
        for (let index = 0; index < files.length; index++) {
            const fileSize = _.get(files[index], 'size', -1)
            if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
            } else {
                const file = files[index]
                if (file) {
                    const newFileName = file.name
                    const newFileSize = file.size
                    const galleries = media
                    let findRecord = -1
                    findRecord = _.findIndex(galleries, function (n) {
                        return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                    })
                    if (findRecord === -1) {
                        let newFile = {
                            selectedFile: file,
                            renderFile: URL.createObjectURL(file)
                        }
                        newUploadedFiles.push(newFile)
                    }
                }
            }
        }
        let copyNewFiles = [...media]
        copyNewFiles = copyNewFiles.concat(newUploadedFiles)
        setMedia(copyNewFiles)
    }

    /**
     *
     * @param {HtmlInputEvent} e HtmlInputEvent
     */
    const handleMediaChange = (e) => {
        const files = e.target.files
        addMediaFiles(files)
    }

    /**
     *
     * Displays how much media is uploaded in MB
     *
     * @param {object} index Index of media
     * @returns {HtmlElement} return HtmlElement
     */
    const getMediaProgress = (index) => {
        const progress = _.get(uploadProgress, 'progress', '')
        const progressIndex = _.get(uploadProgress, 'index', '')
        const progressNumber = _.get(uploadProgress, 'progressNumber', '')
        if (progress !== '' && progressIndex === index) {
            return <span className='progress-span mt-1'>
                <div className='progress'>
                    <MediaProgress
                        className='progress-bar progress-bar-striped progress-bar-animated'
                        backgroundColor={content_color}
                        fontColor={content_font_color}
                        width={progress}
                        role='progressbar'
                    >{progress}</MediaProgress>
                </div>
                {uploadProgressInMb(progressIndex, progressNumber)}
            </span>
        }
        return <></>
    }

    /**
     *
     * UI to display selected media
     */
    const previewContent = () => {
        const fileArray = media
        return <>
            {
                fileArray.map((item, i) => {
                    let url = ''
                    let isUpdatePreview = false
                    if (item.url === undefined) {
                        url = 'url(' + item.renderFile + ')'
                    } else {
                        url = 'url(' + item.url + ')'
                        isUpdatePreview = true
                    }
                    return (
                        <ColumnDiv
                            isUploading={isLoading}
                            content_color={content_color}
                            site_font_color={site_font_color}
                            key={i}
                            className='align-items-center col-12 col-md-4 d-flex justify-content-center mt-3'
                        >
                            {['video/quicktime', 'video/mp4', 'video'].includes(isUpdatePreview === true ? item.content_type : item.selectedFile.type) ?
                                <>
                                    {!isLoading &&
                                        <CloseButton
                                            className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                            content_color={content_color}
                                            content_font_color={content_font_color}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleRemoveMedia(i)
                                            }}
                                        />
                                    }
                                    <VideoDiv
                                        onClick={() => { openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'video') }}
                                    >
                                        {isIOS ?
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' />
                                            :
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} id='uploaded-video' />
                                        }
                                        <GalleryIcon>
                                            <span className='video-button-span'>
                                                <i className='fas fa-play-circle play-icon' />
                                            </span>
                                        </GalleryIcon>
                                    </VideoDiv>
                                    {isLoading && getMediaProgress(i)}
                                </>
                                :
                                <>
                                    {!isLoading &&
                                        <CloseButton
                                            className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                            content_color={content_color}
                                            content_font_color={content_font_color}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleRemoveMedia(i)
                                            }}
                                            disabled={isLoading}
                                        />
                                    }
                                    <DivData
                                        imageUrl={url}
                                        onClick={() => { openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'photo') }}
                                    >
                                    </DivData>
                                    {isLoading && getMediaProgress(i)}
                                </>
                            }
                        </ColumnDiv>
                    )
                })
            }
        </>
    }

    /**
     *
     * UI to choose media to upload
     */
    const getPlusButton = () => {
        let id = 'button-gallery'
        return <>
            <div className='col-12 col-md-4 mt-3 mb-2 plus-button'>
                <PlusButton
                    htmlFor={id}
                    content_color={content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={content_font_color}
                    disabled={isLoading}
                    button_shadow_color={button_shadow_color}
                >
                    <input
                        ref={fileInputRef}
                        accept='image/png,image/jpg,image/jpeg,video/mp4,video/quicktime'
                        id={id}
                        onChange={(e) => handleMediaChange(e)}
                        type='file'
                        disabled={isLoading}
                        multiple={true}
                    />
                    <i className='fas fa-plus' aria-hidden='true'></i>
                </PlusButton>
            </div>
        </>
    }

    useEffect(() => {
        if (media.length) {
            if (media.length === mediaUploaded) {
                setIsLoading(false)
                setMediaUploaded(0)
                setMedia([])
                props.setSweetAlert({ description: 'Media uploaded successfully' })
                // reset file input
                fileInputRef.current.value = null
            }
        }
    }, [mediaUploaded])

    return (
        <>
            <div className='my-5 px-0 mt-lg-0 px-lg-5'>
                <Dropzone multiple={true} onDrop={(files) => { addMediaFiles(files) }} noDrag={isLoading}>
                    {({ getRootProps }) => (
                        <DropZoneSection site_font_color={card_header_border_color}>
                            <div className='drop-zone-div' {...getRootProps()}>
                                <div className='row d-flex justify-content-center my-3'>
                                    {previewContent()}
                                    {getPlusButton()}
                                </div>
                                {media.length > 0 &&
                                    <Button
                                        loading={isLoading}
                                        classes='mt-0'
                                        onClick={handleUpload}
                                    >
                                        {isLoading ? <>Uploading</> : <>Upload Media</>}
                                    </Button>
                                }
                            </div>
                        </DropZoneSection>
                    )}
                </Dropzone>
            </div>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                    showWatermark={false}
                />
            }
        </>
    )
}

MediaUpload.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    addMedia: PropTypes.func.isRequired,
    incrementMediaCount: PropTypes.func.isRequired,
    saveMediaData: PropTypes.func.isRequired,
    updateMediaCountInCategory: PropTypes.func.isRequired
}

MediaUpload.defaultProps = {
    showMarkAsPreviewButton: true
}

const mapStateToProps = state => ({
    auth: state.auth,
    media: state.media
})

export default connect(
    mapStateToProps,
    {
        setSweetAlert,
        addMedia,
        incrementMediaCount,
        saveMediaData,
        updateMediaCountInCategory
    }
)(withRouter((MediaUpload)))
