
import React from 'react'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { setConfirmSweetAlert, removeConfirmSweetAlert } from '../../actions/sweetAlertActions'
import styled from 'styled-components'
import Button from '../Layouts/Button'

const AlertDiv = styled.div`
    width: 100%;
    p {
        color: ${props => props.color} !important;
        font-size: 15px !important;
        margin-top: 0px !important;
        white-space: pre-line;
    }
    .custom-cancel-btn {
        text-transform: capitalize !important;
    }
    .custom-cancel-btn:hover {
        text-decoration: none !important;
    }
`
function ConfirmSweetAlertsWrapper(props) {
    const { card_background_color, content_color, site_font_color, content_font_color } = props.auth.appSettings
    const { confirmDescription, onConfirmUrl, showConfirmAlert, isLoading } = props.sweetAlert

    const onConfirm = () => {
        props.onConfirm()
        if (onConfirmUrl !== '') {
            props.navigate(onConfirmUrl)
        }
    }
    const onCancel = () => {
        props.onCancel()
        props.removeConfirmSweetAlert()
    }
    return (
        <>
            {showConfirmAlert === true &&
                <AlertDiv
                    backgroundColor={content_color}
                    color={site_font_color}
                    btnFontColor={content_font_color}
                >
                    <SweetAlert
                        title={''}
                        hideCloseButton
                        style={{ backgroundColor: card_background_color, color: content_font_color }}
                        closeOnClickOutside={false}
                        openAnim={false}
                        allowEscape={false}
                        showConfirm={false}
                    >
                        {confirmDescription !== '' &&
                            <p>{confirmDescription}</p>
                        }
                        <div>
                            <Button
                                type='button'
                                classes='custom-cancel-btn'
                                onClick={onCancel}
                                loading={isLoading}
                            >CANCEL</Button>
                            <Button
                                classes='ml-3'
                                type='button'
                                variant="primary"
                                onClick={onConfirm}
                                loading={isLoading}
                            >
                                {isLoading ?
                                    <>
                                        <span className="spinner-border spinner-border-sm mr-2"></span>OK
                                    </>
                                    :
                                    'OK'
                                }
                            </Button>
                        </div>
                    </SweetAlert>
                </AlertDiv>
            }
        </>
    )
}
ConfirmSweetAlertsWrapper.propTypes = {
    auth: PropTypes.object.isRequired,
    sweetAlert: PropTypes.object.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    sweetAlert: state.sweetAlert
})

export default connect(mapStateToProps, { setConfirmSweetAlert, removeConfirmSweetAlert })(withRouter(ConfirmSweetAlertsWrapper))
