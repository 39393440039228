import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import Button from '../Layouts/Button'
import { setPushNotificationPrompt } from '../../actions/authActions'
import { saveUserNotificationPreferences } from '../../actions/webPushNotificationSettingAction'
import { SetTagsToOneSignal, showNativePrompt } from '../../actions/OneSignalAction'
import { setExternalUserId } from '../../actions/OneSignalAction'

const ModalStyle = styled.div`
width: 100%;

    .modal {
        display: block;
        background-color: #00000080;
    }
    .prompt-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
    }
    .prompt-box-container {
        padding: 20px;
        margin: 0 20px;
        width: 400px;
        text-align: center;
        background-color: ${props => props.card_background_color};
        color: ${props => props.site_font_color};
    }
    .prompt-box-description {}
    .subscribe-btn {
        width: 200px;
        border-radius: 5px;
    }
    .cancel-btn{
        margin: 8px 0;
        border: transparent;
        background-color: transparent;
        color: ${props => props.site_font_color};
        text-decoration: underline;
        padding : 0px;
    }
`

function WebPushNotificationPrompt(props) {

    const { appSettings, user } = props.auth
    const dispatch = useDispatch()
    const { card_background_color, site_font_color, is_feed_like_enabled, is_feed_comment_enabled } = appSettings
    const userId = _.get(user, '_id', false)
    const isAdmin = _.get(user, 'isAdmin', false)
    const is_allow_web_notification = _.get(user, 'is_allow_web_notification', false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        let msg = <p>Would you like to receive a <b>Push Notification</b> any time I send you a direct message, post new content or when I am going to be live on the website.</p>
        if (isAdmin) {
            if (is_feed_like_enabled && is_feed_comment_enabled) {
                msg = <p>We will send you a <b>Push Notification</b> when user sends a message, purchases any content, sends a tip, on new subscriptions, cancel subscriptions, likes and comments on exclusive content!</p>
            } else if (is_feed_like_enabled) {
                msg = <p>We will send you a <b>Push Notification</b> when user sends a message, purchases any content, sends a tip, on new subscriptions, cancel subscriptions, likes on exclusive content!</p>
            } else if (is_feed_comment_enabled) {
                msg = <p>We will send you a <b>Push Notification</b> when user sends a message, purchases any content, sends a tip, on new subscriptions, cancel subscriptions, comments on exclusive content!</p>
            } else {
                msg = <p>We will send you a <b>Push Notification</b> when user sends a message, purchases any content, sends a tip, on new subscriptions, cancel subscriptions!</p>
            }
        }
        setMessage(msg)
    }, [])

    const onSubmit = () => {
        if (is_allow_web_notification === false) {
            setUserNotificationPreferences()
        }
        showNativePrompt()
        setPromptSetting(true)
        setExternalUserId(userId)
        if (!isAdmin) {
            SetTagsToOneSignal(user)
        }
    }

    const onCancel = () => {
        setPromptSetting(false)
    }

    const setPromptSetting = (allowNotification) => {
        const userIdList = localStorage.getItem('hide_notification_prompt') ? JSON.parse(localStorage.getItem('hide_notification_prompt')) : {}
        userIdList[userId] = allowNotification
        localStorage.setItem('hide_notification_prompt', JSON.stringify(userIdList))
        props.setPushNotificationPrompt(false)
    }

    function setUserNotificationPreferences() {
        let web_notification_preferences = {}
        if (isAdmin) {
            web_notification_preferences = {
                messages: true,
                tip: true,
                subscription: true,
                rebill_subscription: true,
                cancel_subscription: true,
                purchase: true,
                likes: true,
                comments: true
            }
        } else {
            web_notification_preferences = {
                content: true,
                offers: true,
                live_stream: true,
                messages: true
            }
        }

        dispatch(saveUserNotificationPreferences({ user_id: userId, web_notification_preferences: web_notification_preferences }))
    }

    return (
        <ModalStyle card_background_color={card_background_color} site_font_color={site_font_color}>
            <div className='modal fade show' role='dialog'>
                <div className='prompt-box' role='document'>
                    <div className='prompt-box-container'>
                        <div className='prompt-box-description'>
                            {message}
                        </div>
                        <div className='prompt-box-btn'>
                            <Button loading={false} type='submit' onClick={() => onSubmit()} classes='mx-0 my-2 subscribe-btn'>
                                Subscribe
                            </Button>
                            <button className='cancel-btn' type='button' onClick={() => onCancel()}>
                                NO THANKS, MAYBE AT ANOTHER TIME
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyle>
    )
}

WebPushNotificationPrompt.propTypes = {
    auth: PropTypes.object.isRequired,
    setPushNotificationPrompt: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { setPushNotificationPrompt })(withRouter(WebPushNotificationPrompt))
