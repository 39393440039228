import axios from 'axios'
import { getAppBaseUrl } from './api'
import { UPDATE_USER_OPT_IN_DETAIL, UPDATE_USER_OPT_IN_EMAIL_STATUS, UPDATE_USER_EMAIL_AFTER_VERIFIED } from './types'
import _ from 'lodash'
import { setSweetAlert } from './sweetAlertActions'
const BASE_URL = getAppBaseUrl()

export const updateUserOptInStatus = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/opt-in-email-settings/update-user-opt-in-status', data).then(res => {
        if (res.data.success === 1) {
            if (['dismissed', 'no_thanks', 'no_thanks_opt_out'].includes(data.opt_in_status)) {
                dispatch(updateUserOptInDetail())
            }
            callback()
        }
    }).catch(error => {
        let message = _.get(error, 'response.data.message', 'There was a problem in update status.')
        return alert(message)
    })
}

export const userOptInEmailVerification = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/opt-in-email-settings/user-opt-in-email-verification', data).then(res => {
        if (res.data.success === 1) {
            dispatch(updateUserOptInDetail())
            alert('Email verified successfully.')
            callback()
        }
    }).catch(error => {
        let message = _.get(error, 'response.data.message', 'There was a problem in opt in email verification.')
        alert(message)
        callback({})
    })
}

export const userChangeEmailVerification = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/change-email-settings/user-change-email-verification', data).then(res => {
        if (res.data.success === 1) {
            dispatch(updateUserOptInDetail())
            dispatch(updateUserEmailAfterVerified(res.data.data))
            dispatch(setSweetAlert({ description: 'Change Email Verified Successfully' }))
            callback()
        }
    }).catch(error => {
        let message = _.get(error, 'response.data.message', 'There was a problem in change email verification.')
        dispatch(setSweetAlert({ description: message }))
        callback({})
    })
}

export const updateUserDetail = () => dispatch => {
    dispatch(updateUserOptInEmailStatus())
}

// Update User Opt In Detail
export const updateUserOptInEmailStatus = () => {
    return {
        type: UPDATE_USER_OPT_IN_EMAIL_STATUS
    }
}

// Update User Opt In Detail
export const updateUserOptInDetail = () => {
    return {
        type: UPDATE_USER_OPT_IN_DETAIL
    }
}

// hide opt email banner and update user new email
export const updateUserEmailAfterVerified = (data) => {
    return {
        type: UPDATE_USER_EMAIL_AFTER_VERIFIED,
        payload: data
    }
}
