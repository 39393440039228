import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'

import { logoutUser } from './actions/authActions'

import HomePage from './components/HomePage.react'
import PrivateRoute from './components/private-route/PrivateRoute'
import Startup from './components/StartUp'
import GoogleAnalytics from './components/Layouts/GoogleAnalytics'
import OneSignalWrapper from './components/Layouts/OneSignal'

import { getUserDetails, setIsProfileReadyAction } from './actions/authActions'
import 'tabler-react/dist/Tabler.css'
import Loader from './components/Layouts/Loader'

const TipsPage = React.lazy(() => import('./components/tips/TipsPage.react'))
const ChatPage = React.lazy(() => import('./components/chat-new/ChatPage'))
const ChatRoomPage = React.lazy(() => import('./components/chat-new/ChatRoomPage'))
const LoginPage = React.lazy(() => import('./components/login/LoginPage'))
const ForgotPasswordPage = React.lazy(() => import('./components/auth/ForgotPasswordPage'))
const MembershipPage = React.lazy(() => import('./components/ccbill/MembershipBillingPage'))
const ChangePasswordPage = React.lazy(() => import('./components/auth/ChangePasswordPage'))
const RegisterPage = React.lazy(() => import('./components/register/RegisterPage'))
const TransactionPage = React.lazy(() => import('./components/transactions/Transactions'))
const SubscribersPage = React.lazy(() => import('./components/subscribers/SubscribersPage'))
const AppSettingsPage = React.lazy(() => import('./components/app-settings/AppSettings'))
const GenerateSubscriptionURLPage = React.lazy(() => import('./components/app-settings/GenerateSubscriptionURL'))
const LinksPageSettings = React.lazy(() => import('./components/app-settings/LinksPageSettings'))
const MassMessagesPage = React.lazy(() => import('./components/mass-messages/MassMessagesPage'))
const SuperAdminToolsPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsPage'))
const SuperAdminToolsLookupPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsLookupPage'))
const EditTermsOfService = React.lazy(() => import('./components/terms-of-service/EditTermsOfService'))
const EditPrivacyPolicy = React.lazy(() => import('./components/privacy-policy/EditPrivacyPolicy'))
const EditDMCA = React.lazy(() => import('./components/dmca/EditDMCA'))
const Edit2257 = React.lazy(() => import('./components/2257/Edit2257'))
const EditFaq = React.lazy(() => import('./components/faq/EditFaq'))
const EditMaintenanceMode = React.lazy(() => import('./components/maintenance/EditMaintenanceMode.js'))
const UploadPhotoID = React.lazy(() => import('./components/upload-photo-id/UploadPhotoID'))
const LikeTheWebsite = React.lazy(() => import('./components/like-the-website/LikeTheWebsite'))
const GalleryPage = React.lazy(() => import('./components/gallery/GalleryPage.react'))
const SingleGalleryPage = React.lazy(() => import('./components/gallery/SingleGalleryPage'))
const TermsOfService = React.lazy(() => import('./components/terms-of-service/TermsOfService'))
const PrivacyPolicy = React.lazy(() => import('./components/privacy-policy/PrivacyPolicy'))
const Dmca = React.lazy(() => import('./components/dmca/DMCA'))
const Render2257 = React.lazy(() => import('./components/2257/2257'))
const FaqPage = React.lazy(() => import('./components/faq/Faq'))
const ContactUs = React.lazy(() => import('./components/contact-us/Contact'))
const Error404 = React.lazy(() => import('./pages/404.react'))
const LinksPage = React.lazy(() => import('./components/links-page/LinksPage'))
const TipsList = React.lazy(() => import('./components/chat-new/TipsList'))
const LockedExclusiveContent = React.lazy(() => import('./components/locked-exclusive-content/lockedExclusiveContent'))
const AddProduct = React.lazy(() => import('./components/shop-admin/AddProduct'))
const EditProduct = React.lazy(() => import('./components/shop-admin/EditProduct'))
const ProductListAdmin = React.lazy(() => import('./components/shop-admin/ProductListAdmin'))
const ProductListUser = React.lazy(() => import('./components/shop-user/ProductListUser'))
const ViewProduct = React.lazy(() => import('./components/shop-user/ViewProduct'))
const Cart = React.lazy(() => import('./components/shop-user/Cart'))
const CCBillLogs = React.lazy(() => import('./components/SuperAdminTools/CCBillLogs'))
const Checkout = React.lazy(() => import('./components/shop-user/Checkout'))
const ShippingAddressList = React.lazy(() => import('./components/shop-user/ShippingAddressList'))
const AddShippingAddress = React.lazy(() => import('./components/shop-user/AddShippingAddress'))
const EditShippingAddress = React.lazy(() => import('./components/shop-user/EditShippingAddress'))
const OrderHistory = React.lazy(() => import('./components/shop-user/OrderHistory'))
const OrderDetails = React.lazy(() => import('./components/shop-user/OrderDetails'))
const OrderList = React.lazy(() => import('./components/shop-admin/OrderList'))
const EditOrder = React.lazy(() => import('./components/shop-admin/EditOrderDetails'))
const LandingPageSettings = React.lazy(() => import('./components/app-settings/LandingPageSettings'))
const UploadThemeImage = React.lazy(() => import('./components/app-settings/UploadThemeImage'))
const ReportingPurchasedByPage = React.lazy(() => import('./components/reporting-purchased-by/ReportingPurchasedByPage'))
const BackgroundSettings = React.lazy(() => import('./components/app-settings/BackgroundSettings'))
const ChatSettings = React.lazy(() => import('./components/app-settings/ChatSettings'))
const ChatSlot = React.lazy(() => import('./components/chat-new/ChatSlot'))
const UserChatBookingPage = React.lazy(() => import('./components/chat-new/UserChatBookingPage'))
const ReportingPage = React.lazy(() => import('./components/app-settings/ReportingPage'))
const SubscribeForm = React.lazy(() => import('./components/ccbill/SubscribeForm'))
const CCBillRestApiSettings = React.lazy(() => import('./components/app-settings/CCBillRestApiSettings'))
const LiveStream = React.lazy(() => import('./components/live-stream/LiveStream'))
const LiveStreamingSettings = React.lazy(() => import('./components/app-settings/LiveStreamingSettings'))
const EmailConfigurationSetting = React.lazy(() => import('./components/app-settings/EmailConfiguration'))
const LiveStreamReports = React.lazy(() => import('./components/live-stream/LiveStreamReports'))
const StaggerOverTimeSettings = React.lazy(() => import('./components/app-settings/StaggerOverTime'))
const WelcomeMessage = React.lazy(() => import('./components/mass-messages/WelcomeMessagePage'))
const PurchasedContents = React.lazy(() => import('./components/Layouts/PurchasedContents'))
const PurchasedContentSettings = React.lazy(() => import('./components/purchased-content-settings/PurchasedContentSettings'))
const ExportUsersCSV = React.lazy(() => import('./components/export-users/ExportUsersCSV'))
const LandingPagePreview = React.lazy(() => import('./components/landing-page/LandingPagePreview'))
const CCBillSettings = React.lazy(() => import('./components/app-settings/CCBillSettings'))
const AWSSettings = React.lazy(() => import('./components/app-settings/AWSSettings'))
const GroupLiveStream = React.lazy(() => import('./components/live-stream/GroupLiveStream'))
const GroupLiveStreamWrapper = React.lazy(() => import('./components/live-stream/GroupLiveStreamWrapper'))
const PayPerMessageSettings = React.lazy(() => import('./components/app-settings/PayPerMessageSettings'))
const PlatformSettings = React.lazy(() => import('./components/app-settings/PlatformSettings'))
const PurchaseNotificationSettings = React.lazy(() => import('./components/app-settings/PurchaseNotificationSettings'))
const SeoSettings = React.lazy(() => import('./components/app-settings/SeoSettings'))
const OptInEmailVerification = React.lazy(() => import('./components/opt-in-email/OptInEmailVerification'))
const UpdateProfile = React.lazy(() => import('./components/account/UpdateProfile'))
const ChangePassword = React.lazy(() => import('./components/account/ChangePassword'))
const CancelSubscription = React.lazy(() => import('./components/account/CancelSubscription'))
const NotificationPreference = React.lazy(() => import('./components/account/NotificationPreference'))
const PaymentMethod = React.lazy(() => import('./components/account/PaymentMethod'))
const OrderLists = React.lazy(() => import('./components/account/OrderLists'))
const ProfilePage = React.lazy(() => import('./components/account/ProfilePage'))
const PromotionSettings = React.lazy(() => import('./components/app-settings/PromotionSettings'))
const AddNewPaymentMethod = React.lazy(() => import('./components/account/AddNewPaymentMethod'))
const MenuSettings = React.lazy(() => import('./components/app-settings/MenuSettings'))
const SubscriptionPageSettings = React.lazy(() => import('./components/app-settings/SubscriptionPageSettings'))
const TipPageSettings = React.lazy(() => import('./components/app-settings/TipPageSettings'))
const PromotionReports = React.lazy(() => import('./components/promotion/PromotionReports'))
const NewSignUpPageSettings = React.lazy(() => import('./components/app-settings/NewSignUpPageSettings'))
const Theme2Settings = React.lazy(() => import('./components/app-settings/Theme2Settings'))
const MembershipPriceSettings = React.lazy(() => import('./components/app-settings/MembershipPriceSetting'))
const OptInEmailReporting = React.lazy(() => import('./components/opt-in-email/OptInEmailReporting'))
const UserCardList = React.lazy(() => import('./components/user-card-list/UserCardList'))
const StickyIoSettings = React.lazy(() => import('./components/app-settings/StickyIoSettings'))
const WebsiteSettings = React.lazy(() => import('./components/app-settings/WebsiteSettings'))
const ServiceStopPage = React.lazy(() => import('./components/Layouts/ServiceStopPage'))
const StickyIoTransactions = React.lazy(() => import('./components/sticky-io/stickyIoTransactions'))
const ResubscribeOfferSettings = React.lazy(() => import('./components/app-settings/ResubscribeOfferSettings'))
const FlexForm = React.lazy(() => import('./components/Layouts/FlexForm'))
const UserReport = React.lazy(() => import('./components/user-report/UserReport'))
const StickyIoSubscriptionProductList = React.lazy(() => import('./components/sticky-io/stickyIoSubscriptionProductList'))
const SuperAdminToolsStickyIoLookupPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsStickyIoLookupPage'))
const WatermarkSettings = React.lazy(() => import('./components/app-settings/WatermarkSettings'))
const Notification = React.lazy(() => import('./components/Layouts/Notification'))
const WebsiteSettingsPage = React.lazy(() => import('./components/app-settings/WebsiteSettingsPage'))
const ThemeSettings = React.lazy(() => import('./components/app-settings/ThemeSettings'))
const NotificationRedirectPage = React.lazy(() => import('./components/Layouts/NotificationRedirectPage'))
const BannerImageTextSettings = React.lazy(() => import('./components/app-settings/BannerImageTextSettings'))
const MigrateSubscriptionsSettings = React.lazy(() => import('./components/app-settings/MigrateSubscriptionsSettings'))
const MinimumAndMaximumAmounts = React.lazy(() => import('./components/app-settings/MinimumAndMaximumAmounts'))
const AgeModal = React.lazy(() => import('./components/app-settings/AgeModal'))
const ContactUsSettings = React.lazy(() => import('./components/app-settings/ContactUsSettings'))
const LoginSignupTitle = React.lazy(() => import('./components/app-settings/LoginSignupTitle'))
const MiscellaneousPopUp = React.lazy(() => import('./components/app-settings/MiscellaneousPopUp'))
const Shop = React.lazy(() => import('./components/app-settings/Shop'))
const HybridPaymentSettings = React.lazy(() => import('./components/app-settings/HybridPaymentSettings'))
const CustomMenuLinksSettings = React.lazy(() => import('./components/app-settings/CustomMenuLinks'))
const InfluencerHelpDetailPage = React.lazy(() => import('./components/Layouts/InfluencerHelpDetailPage'))
const InfluencerHelp = React.lazy(() => import('./components/Layouts/InfluencerHelp'))
const MiscellaneousSettings = React.lazy(() => import('./components/app-settings/MiscellaneousSettings'))
const CSVDownload = React.lazy(() => import('./components/app-settings/CSVDownload'))
const RecaptchaSettings = React.lazy(() => import('./components/app-settings/RecaptchaSettings'))
const UnlockedContentUserList = React.lazy(() => import('./components/unlocked-content-user-list/UnlockedContentUserList.js'))
const HealthInfo = React.lazy(() => import('./components/health-info/HealthInfo'))
const TimestampVisibility = React.lazy(() => import('./components/app-settings/TimestampVisibility'))
const UniversalBlock = React.lazy(() => import('./components/app-settings/UniversalBlock'))
const UniversalBlockLogs = React.lazy(() => import('./components/universal-block/universalBlockLogs'))
const HelpSettings = React.lazy(() => import('./components/app-settings/HelpSettings'))
const PublicPostSettings = React.lazy(() => import('./components/app-settings/PublicPostSettings'))
const UserUtmParams = React.lazy(() => import('./components/user-utm-params/userUtmParams'))
const Cancellation = React.lazy(() => import('./components/cancellation/cancellation'))
const CancellationReasonSettings = React.lazy(() => import('./components/app-settings/CancellationReasonSettings'))
const RedirectUrl = React.lazy(() => import('./components/redirect-url/redirectUrl'))
const RedirectUrlList = React.lazy(() => import('./components/redirect-url/redirectUrlList'))
const RedirectRouteInformation = React.lazy(() => import('./components/redirect-url/redirectRouteInformation'))
const ForumPaySettings = React.lazy(() => import('./components/app-settings/ForumPaySettings'))
const BlogCommentSettings = React.lazy(() => import('./components/app-settings/BlogCommentSettings'))
const BlogInfo = React.lazy(() => import('./components/blogs/BlogInfo'))
const HideEarning = React.lazy(() => import('./components/app-settings/HideEarning'))
const FeedTags = React.lazy(() => import('./components/app-settings/FeedTagsSettings'))
const ShowPaymentIcons = React.lazy(() => import('./components/app-settings/ShowPaymentIcons'))
const Wallet = React.lazy(() => import('./components/wallet/Wallet'))
const AnnouncementBanner = React.lazy(() => import('./components/app-settings/AnnouncementBanner'))
const VoidRefundAndChargeback = React.lazy(() => import('./components/void-refund-and-chargeback/VoidRefundAndChargeback'))
const ChatListSettings = React.lazy(() => import('./components/app-settings/ChatListSettings'))
const UserPurchaseHistory = React.lazy(() => import('./components/user-purchase-history/UserPurchaseHistory'))
const ShowMediaCountInExclusiveContentSettings = React.lazy(() => import('./components/app-settings/ShowMediaCountInExlusiveContentSettings'))
const InfluencerActivity = React.lazy(() => import('./components/influencerActivity/influencerActivity'))
const ManageMassMessages = React.lazy(() => import('./components/mass-messages/ManageMessagesPage'))
const Navbar = React.lazy(() => import('./components/app-settings/NavbarSettings'))
const ReportingMenu = React.lazy(() => import('./components/Layouts/ReportingMenu'))
const CustomMenuLinks = React.lazy(() => import('./components/Layouts/CustomMenuLinks'))
const WebPushNotificationSettings = React.lazy(() => import('./components/app-settings/WebPushNotificationSettings'))
const SubscriptionVideoMessage = React.lazy(() => import('./components/Layouts/VideoSubscribePage.js'))
const UniversalLoginSetting = React.lazy(() => import('./components/app-settings/UniversalLoginSetting'))
const SubscriptionEmailNotification = React.lazy(() => import('./components/app-settings/SubscriptionEmailNotification.js'))
const DisplayPaginationOnExclusiveContent = React.lazy(() => import('./components/app-settings/DisplayPaginationOnExclusiveContent.js'))
const CouponSettings = React.lazy(() => import('./components/app-settings/CouponSettings.js'))
const CouponDetails = React.lazy(() => import('./components/coupon/CouponDetails.js'))
const CouponReports = React.lazy(() => import('./components/coupon/CouponReports.js'))
const ContentRemoval = React.lazy(() => import('./components/content-removal/ContentRemoval'))
const EditContentRemoval = React.lazy(() => import('./components/content-removal/EditContentRemoval'))
const MediaManager = React.lazy(() => import('./components/media/Media'))

// Check for token to keep user logged in
if (localStorage.AuthToken) {
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.AuthToken)

    // Check for expired token
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser())
    } else {
        // Set auth token header auth
        setAuthToken(localStorage.AuthToken)

        // Set user and isAuthenticated
        // store.dispatch(setCurrentUser(decoded))

        // Get Current user details
        getUserDetails()
    }
} else {
    store.dispatch(setIsProfileReadyAction())
}

const ROLE_ADMIN = 'admin'
const ROLE_MODEL = 'model'
const ROLE_CONTENT_MANAGER = 'content_manager'
const ROLE_USER = 'user'
const ROLE_SUPPORT = 'support'
const ROLE_SUB_ADMIN = 'sub_admin'
const ROLE_PROXY_USER = 'proxy_user'
const ROLE_LIVE_STREAM_MANAGER = 'live_stream_manager'

const ALLOW_ALL = [ROLE_USER, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_ADMIN, ROLE_SUPPORT, ROLE_SUB_ADMIN]
const ALLOW_MODEL_ADMIN = [ROLE_MODEL, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ADMIN = [ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ALL_ADMINS = [ROLE_ADMIN, ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_SUB_ADMIN]
const ALLOW_SUPPORT_ADMIN = [ROLE_SUPPORT, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_CONTENT_MANAGER_ADMIN = [ROLE_CONTENT_MANAGER, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ONLY_SUPER_ADMIN = [ROLE_ADMIN]
const ALLOW_ALL_ADMIN_SUPPORT = [ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_SUB_ADMIN, ROLE_SUPPORT]
const ALLOW_MODEL_CONTENT_MANAGER = [ROLE_MODEL, ROLE_ADMIN, ROLE_SUB_ADMIN, ROLE_CONTENT_MANAGER]
const ALLOW_ONLY_USER = [ROLE_USER]
const ALLOW_MODEL_SUPER_ADMIN = [ROLE_ADMIN, ROLE_MODEL]
const ALLOW_LIVE_STREAM_MANAGER_ADMIN = [ROLE_USER, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_ADMIN, ROLE_SUPPORT, ROLE_SUB_ADMIN, ROLE_LIVE_STREAM_MANAGER]

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Startup>
                    <BrowserRouter>
                        <GoogleAnalytics>
                            <OneSignalWrapper />
                            <React.Suspense fallback={
                                <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                                    <Loader loading={true} size={10} />
                                </div>
                            }>
                                <GroupLiveStreamWrapper>
                                    <Routes>
                                        <Route element={<PrivateRoute allow={ALLOW_ALL} />}>
                                            <Route exact path='/private-chat/:id?' element={<ChatRoomPage />} allow={ALLOW_ALL} />
                                            <Route exact path='/membership' element={<MembershipPage />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/view-cart' element={<Cart />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/checkout/:id?' element={<Checkout />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/shipping-details' element={<ShippingAddressList />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/shipping-details/add' element={<AddShippingAddress />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/shipping-details/edit/:id' element={<EditShippingAddress />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/order-history' element={<OrderHistory />} allow={ALLOW_ALL} />
                                            <Route exact path='/shop/order-history/:id' element={<OrderDetails />} allow={ALLOW_ALL} />
                                            <Route exact path='/upload-photo-id' element={<UploadPhotoID />} allow={ALLOW_ALL} />
                                            <Route exact path='/admin/subscribers' element={<SubscribersPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/settings' element={<AppSettingsPage />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/settings/links-page-settings' element={<LinksPageSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/landing-page-settings' element={<LandingPageSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/edit-terms-of-service' element={<EditTermsOfService />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-privacy-policy' element={<EditPrivacyPolicy />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-dmca' element={<EditDMCA />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-2257' element={<Edit2257 />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-faq' element={<EditFaq />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-maintenance-mode' element={<EditMaintenanceMode />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/mass-messages' element={<MassMessagesPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/locked-exclusive-content' element={<LockedExclusiveContent />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/transactions' element={<TransactionPage />} allow={ALLOW_SUPPORT_ADMIN} />
                                            <Route exact path='/admin/super-admin-tools' element={<SuperAdminToolsPage />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/super-admin-tools/lookup' element={<SuperAdminToolsLookupPage />} allow={ALLOW_SUPPORT_ADMIN} />
                                            <Route exact path='/admin/chat/tips-list' element={<TipsList />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/shop/add-product' element={<AddProduct />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/shop/edit-product/:id' element={<EditProduct />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/shop/product-list' element={<ProductListAdmin />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/shop/order-list' element={<OrderList />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/shop/order/edit/:id' element={<EditOrder />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/logs' element={<CCBillLogs />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/upload-theme-image' element={<UploadThemeImage />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/upload-theme2-banner-image' element={<UploadThemeImage />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/upload-theme-image/model-profile' element={<UploadThemeImage />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/purchased-by/mass-message/:_id/:promotion_id?' element={<ReportingPurchasedByPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/promotion-subscribe-users/:promotion_id' element={<ReportingPurchasedByPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/purchased-by/locked-exclusive-content/:blogId/:promotion_id?' element={<ReportingPurchasedByPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/generate-subscription-url' element={<GenerateSubscriptionURLPage />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/background-settings' element={<BackgroundSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/chat-booking-settings' element={<ChatSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/set-chat-availability/:page' element={<ChatSlot />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/book-slot/:page' element={<UserChatBookingPage />} allow={ALLOW_ALL} />
                                            <Route exact path='/admin/settings/reporting-page' element={<ReportingPage />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/live-stream' element={<LiveStream />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/live-streaming-settings' element={<LiveStreamingSettings />} allow={ALLOW_CONTENT_MANAGER_ADMIN} />
                                            <Route exact path='/admin/settings/email-configuration' element={<EmailConfigurationSetting />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/live-stream-sessions/:id?' element={<LiveStreamReports />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/settings/stagger-message-settings' element={<StaggerOverTimeSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/email-configuration' element={<EmailConfigurationSetting />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/welcome-messages' element={<WelcomeMessage />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/purchased-contents/:id?' element={<PurchasedContents />} allow={ALLOW_ALL} />
                                            <Route exact path='/admin/settings/purchased-content-settings' element={<PurchasedContentSettings />} allow={[ROLE_MODEL, ROLE_ADMIN]} />
                                            <Route exact path='/admin/settings/export-users' element={<ExportUsersCSV />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/purchased-by/welcome-message/:_id' element={<ReportingPurchasedByPage />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/landing-page-preview' element={<LandingPagePreview />} allow={ALLOW_ALL_ADMIN_SUPPORT} />
                                            <Route exact path='/admin/settings/ccbill-settings' element={<CCBillSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/aws-settings' element={<AWSSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/group-live-stream' element={<GroupLiveStream />} allow={ALLOW_LIVE_STREAM_MANAGER_ADMIN} />
                                            <Route exact path='/admin/settings/platform-settings' element={<PlatformSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/purchase-notification-settings' element={<PurchaseNotificationSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/pay-per-message' element={<PayPerMessageSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/change-email-verification/:token' element={<OptInEmailVerification />} allow={ALLOW_ONLY_USER} />
                                            <Route exact path='/admin/settings/seo-setting' element={<SeoSettings />} allow={[ROLE_ADMIN]} />
                                            <Route exact path='/subscribe' element={<SubscribeForm />} allow={ALLOW_ALL} />
                                            <Route exact path='/admin/settings/ccbill-rest-api-settings' element={<CCBillRestApiSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/profile' element={<ProfilePage />} allow={ALLOW_ALL} />
                                            <Route exact path='/profile/update' element={<UpdateProfile />} allow={ALLOW_ALL} />
                                            <Route exact path='/profile/change-password' element={<ChangePassword />} allow={ALLOW_ALL} />
                                            <Route exact path='/profile/cancel-subscription' element={<CancelSubscription />} allow={ALLOW_ONLY_USER} />
                                            <Route exact path='/profile/notification-preference' element={<NotificationPreference />} allow={ALLOW_ALL} />
                                            <Route exact path='/profile/payment-method' element={<PaymentMethod />} allow={ALLOW_ONLY_USER} />
                                            <Route exact path='/profile/order-list' element={<OrderLists />} allow={ALLOW_ALL} />
                                            <Route exact path='/admin/settings/promotion-settings' element={<PromotionSettings />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/profile/add-new-payment-method' element={<AddNewPaymentMethod />} allow={ALLOW_ONLY_USER} />
                                            <Route exact path='/admin/settings/menu-settings' element={<MenuSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/subscription-page-settings' element={<SubscriptionPageSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/tip-page-settings' element={<TipPageSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/promotion-reports' element={<PromotionReports />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/settings/signup-design' element={<NewSignUpPageSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/theme-2-settings' element={<Theme2Settings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/membership-price-settings' element={<MembershipPriceSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/opt-in-report' element={<OptInEmailReporting />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/user-card-list' element={<UserCardList />} allow={[ROLE_SUPPORT, ROLE_ADMIN]} />
                                            <Route exact path='/admin/settings/sticky-io-setting' element={<StickyIoSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/website-settings' element={<WebsiteSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/sticky-io-transactions' element={<StickyIoTransactions />} allow={[ROLE_ADMIN, ROLE_SUPPORT]} />
                                            <Route exact path='/admin/ccbill-test-flex-form' element={<FlexForm />} allow={ALLOW_CONTENT_MANAGER_ADMIN} />
                                            <Route exact path='/admin/settings/resubscribe-offer-settings' element={<ResubscribeOfferSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/user-report' element={<UserReport />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/watermark' element={<WatermarkSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/sticky-io-subscription-products' element={<StickyIoSubscriptionProductList />} allow={[ROLE_ADMIN]} />
                                            <Route exact path='/admin/super-admin-tools/sticky-io-lookup' element={<SuperAdminToolsStickyIoLookupPage />} allow={ALLOW_SUPPORT_ADMIN} />
                                            <Route exact path='/notification/:page/:promotionId?' element={<Notification />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/user-notification/:page' element={<Notification />} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                            <Route exact path='/purchase-notification' element={<NotificationRedirectPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/admin/banner-image-text' element={<BannerImageTextSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/website' element={<WebsiteSettingsPage />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/theme' element={<ThemeSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/migrate-subscription' element={<MigrateSubscriptionsSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/minimum-and-maximum-amounts' element={<MinimumAndMaximumAmounts />} allow={ROLE_ADMIN} />
                                            <Route exact path='/admin/settings/age-modal' element={<AgeModal />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/contact-us-settings' element={<ContactUsSettings />} allow={ROLE_ADMIN} />
                                            <Route exact path='/admin/settings/login-and-signup-title' element={<LoginSignupTitle />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/miscellaneous-pop-up' element={<MiscellaneousPopUp />} allow={ROLE_ADMIN} />
                                            <Route exact path='/admin/settings/shop' element={<Shop />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/payment-setting' element={<HybridPaymentSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/custom-menu-links' element={<CustomMenuLinksSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/help' element={<InfluencerHelp />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/help/:page/:id?' element={<InfluencerHelpDetailPage />} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                            <Route exact path='/user-help' element={<InfluencerHelp />} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                            <Route exact path='/user-help/:page/:id?' element={<InfluencerHelpDetailPage />} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                            <Route exact path='/admin/settings/miscellaneous-settings' element={<MiscellaneousSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/csv-download' element={<CSVDownload />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/recaptcha-setting' element={<RecaptchaSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/unlocked-content-user-list' element={<UnlockedContentUserList />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/health-info' element={<HealthInfo />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/timestamp-visibility' element={<TimestampVisibility />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/universal-block' element={<UniversalBlock />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/universal-block-logs' element={<UniversalBlockLogs />} allow={[ROLE_ADMIN]} />
                                            <Route exact path='/admin/settings/help-setting' element={<HelpSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/public-post-setting' element={<PublicPostSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/user-utm-params' element={<UserUtmParams />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/cancellation' element={<Cancellation />} allow={ALLOW_MODEL_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/subscription-cancellation-reason' element={<CancellationReasonSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/redirect-url/:route?' element={<RedirectUrl />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/list-redirect-url' element={<RedirectUrlList />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/forum-pay-setting' element={<ForumPaySettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/comments' element={<BlogCommentSettings />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/settings/hide-earnings' element={<HideEarning />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/feed-tags' element={<FeedTags />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/voids-refund-and-chargeback' element={<VoidRefundAndChargeback />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/profile/wallet' element={<Wallet />} allow={ALLOW_ONLY_USER} />
                                            <Route exact path='/admin/settings/announcement-banner' element={<AnnouncementBanner />} allow={ALLOW_MODEL_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/show-payment-icons' element={<ShowPaymentIcons />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/chat-list' element={<ChatListSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/user-purchase-history' element={<UserPurchaseHistory />} allow={ALLOW_SUPPORT_ADMIN} />
                                            <Route exact path='/admin/settings/show-media-count-in-exclusive-content' element={<ShowMediaCountInExclusiveContentSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/influencer-activity' element={<InfluencerActivity />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/manage-mass-messages' element={<ManageMassMessages />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/navbar' element={<Navbar />} allow={ALLOW_MODEL_ADMIN} />
                                            <Route exact path='/admin/reporting' element={<ReportingMenu />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/settings/web-push-notification' element={<WebPushNotificationSettings />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/universal-login' element={<UniversalLoginSetting />} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                            <Route exact path='/admin/settings/coupon' element={<CouponSettings />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/coupon-reports' element={<CouponReports />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/coupon-reports/coupon-details/:couponId' element={<CouponDetails />} allow={ALLOW_ALL_ADMINS} />
                                            <Route exact path='/admin/settings/subscription-email-notification' element={<SubscriptionEmailNotification />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/settings/display-pagination-on-exclusive-content' element={<DisplayPaginationOnExclusiveContent />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/edit-content-removal' element={<EditContentRemoval />} allow={ALLOW_ADMIN} />
                                            <Route exact path='/admin/content-manager' element={<MediaManager />} allow={[ROLE_MODEL, ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_SUB_ADMIN]} />
                                        </Route>
                                        <Route exact path='/' element={<HomePage />} />
                                        <Route exact path='/home' element={<HomePage />} />
                                        <Route exact path='/forgot-password' element={<ForgotPasswordPage />} />
                                        <Route exact path='/change-password/:token' element={<ChangePasswordPage />} />
                                        <Route exact path='/login' element={<LoginPage />} />
                                        <Route exact path='/register' element={<RegisterPage />} />
                                        <Route exact path='/tips' element={<TipsPage />} />
                                        <Route exact path='/subscription-video' element={<SubscriptionVideoMessage />} />
                                        <Route exact path='/chat' element={<ChatPage />} />
                                        <Route exact path='/404' element={<Error404 />} />
                                        <Route exact path='/gallery' element={<GalleryPage />} />
                                        <Route exact path='/single-gallery/:id' element={<SingleGalleryPage />} />
                                        <Route exact path='/like-the-website' element={<LikeTheWebsite />} />
                                        <Route exact path='/shop' element={<ProductListUser />} />
                                        <Route exact path='/shop/:id' element={<ViewProduct />} />
                                        <Route exact path='/terms-of-service' element={<TermsOfService />} />
                                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                                        <Route exact path='/dmca' element={<Dmca />} />
                                        <Route exact path='/2257' element={<Render2257 />} />
                                        <Route exact path='/faq' element={<FaqPage />} />
                                        <Route exact path='/contact-us' element={<ContactUs />} />
                                        <Route exact path='/links' element={<LinksPage />} />
                                        <Route exact path='/opt-in-email-verification/:token' element={<OptInEmailVerification />} />
                                        <Route exact path='/service-stopped' element={<ServiceStopPage />} />
                                        <Route exact path='/feed/:blog_id/:from_notification?' element={<BlogInfo />} />
                                        <Route exact path='/more-links' element={<CustomMenuLinks />} />
                                        <Route exact path='/content-removal' element={<ContentRemoval />} />
                                        <Route exact path='/:path' element={<RedirectRouteInformation />} />
                                        <Route element={<Error404 />} />
                                    </Routes>
                                </GroupLiveStreamWrapper>
                            </React.Suspense>
                        </GoogleAnalytics>
                    </BrowserRouter>
                </Startup>
            </Provider>
        )
    }
}

export default App
