import React, { useState, useEffect } from 'react'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAnnouncementSettings } from '../../actions/announcementBannerSettingAction'
import _ from 'lodash'
import AnnouncementBannerLayout from './AnnouncementBannerLayout'

function AnnouncementBanner(props) {

    const { announcement_banner_settings } = props.auth.appSettings
    const [activeAnnouncementBannerForSubscriber, setActiveAnnouncementBannerForSubscriber] = useState(false)
    const [activeAnnouncementBannerNonSubscriber, setActiveAnnouncementBannerNonSubscriber] = useState(false)

    let subscriptionStatus = _.get(props.auth.user, 'ccbillSubscriptionStatus', '0')
    let isAdmin = _.get(props.auth.user, 'isAdmin', false)

    useEffect(() => {
        setActiveAnnouncementBannerNonSubscriber(false)
        setActiveAnnouncementBannerForSubscriber(false)
        if ((announcement_banner_settings.is_announcement_banner_enabled_for_subscribers === true && isAdmin === false) && (subscriptionStatus === '1' || subscriptionStatus === '2')) {
            setActiveAnnouncementBannerForSubscriber(true)
            setActiveAnnouncementBannerNonSubscriber(false)
        }
        if ((announcement_banner_settings.is_announcement_banner_enabled_for_non_subscribers === true && isAdmin === false && subscriptionStatus === '0')) {
            setActiveAnnouncementBannerNonSubscriber(true)
            setActiveAnnouncementBannerForSubscriber(false)
        }
    })

    return (
        <>
            {(activeAnnouncementBannerNonSubscriber === true) &&
                <AnnouncementBannerLayout banner_text={announcement_banner_settings.non_subscriber_announcement_banner_text} />
            }

            {(activeAnnouncementBannerForSubscriber === true && props.location.pathname !== '/subscribe') &&
                <AnnouncementBannerLayout banner_text={announcement_banner_settings.subscriber_announcement_banner_text} />
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

AnnouncementBanner.propTypes = {
    auth: PropTypes.object.isRequired,
    getAnnouncementSettings: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { getAnnouncementSettings })(withRouter(AnnouncementBanner))
