import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { toggleMiscellaneousPopUp } from './../../actions/utilityActions'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const MiscellaneousPopUp = (props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const {
        content_color,
        model_id,
        show_misc_pop_up,
        misc_pop_up_text,
        content_font_color,
        card_background_color
    } = props.auth.appSettings
    const profilePic = (props.chat.userProfileInfo[model_id])
        ? props.chat.userProfileInfo[model_id].profile
        : getCloudFrontAssetsUrl('faces/avatar.png')
    const modalConditions = isDialogOpen && show_misc_pop_up

    useEffect(() => {
        setIsDialogOpen(props.utility.isMiscellaneousPopUpOpen)
    })

    return (
        <WebsiteFontColor>
            {modalConditions ? <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#000000ef' }}>
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                            <div className='text-center mt-5 mb-5'>
                                <img
                                    src={profilePic}
                                    className='img-fluid'
                                    alt=''
                                    style={{ height: '60px', borderRadius: '30px' }}
                                    draggable={false}
                                />
                            </div>
                            <p className='text-center'>{misc_pop_up_text ? misc_pop_up_text.split('\n').map((item, key) => {
                                return <div key={key}>{item}<br /></div>
                            }) : ''}</p>
                            <button
                                type='button'
                                className='btn btn-block btn-pill'
                                onClick={props.toggleMiscellaneousPopUp}
                                style={{ backgroundColor: content_color, color: content_font_color }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
        </WebsiteFontColor>
    )
}

MiscellaneousPopUp.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    utility: PropTypes.object.isRequired,
    toggleMiscellaneousPopUp: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    utility: state.utility
})

export default connect(
    mapStateToProps,
    { toggleMiscellaneousPopUp }
)(withRouter((MiscellaneousPopUp)))
