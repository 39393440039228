import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from '../WithRouter'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const OverlayContainer = styled.div`
    margin: auto;
    position: relative;
    display: block;
    width: fit-content;
    padding: 0px;
    height: auto;

    &::after {
        content: '${props => props.auth.appSettings.enable_watermark === true ? props.userId : ''}';
        font-size: ${props => props.auth.appSettings.watermark_size}rem;
        color: ${props => props.auth.appSettings.watermark_color};
        position: absolute;
        margin-bottom: 0.5rem;
        
        ${props => props.auth.appSettings.watermark_position === 'top-left' ? 'top: 0; left: 2px;' :
        props.auth.appSettings.watermark_position === 'top-right' ? 'top: 0; right: 2px;' :
            props.auth.appSettings.watermark_position === 'bottom-left' ? 'bottom: 0; left: 2px;' :
                props.auth.appSettings.watermark_position === 'bottom-right' ? 'bottom: 0; right: 2px;' :
                    'top: 0;'
}
    }

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        &::after{
            font-size: 1em;
        }
    }
`

const mapStateToProps = state => ({
    auth: state.auth
})

export default withRouter(connect(mapStateToProps)(OverlayContainer))
