import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'
import _ from 'lodash'
import OneSignalReact from 'react-onesignal'
import { SetTagsToOneSignal, initOneSignal, removeUserDataFromOneSignal, setExternalUserId } from '../../actions/OneSignalAction'

function OneSignalWrapper(props) {
    const { appSettings, user, isAdmin, isSupport } = props.auth
    const dispatch = useDispatch()

    const { one_signal, web_push_notification_enabled } = appSettings
    const { app_id, safari_web_id } = one_signal

    const userId = _.get(user, '_id', false)

    useEffect(() => {
        if (!_.isEmpty(app_id) && !_.isEmpty(safari_web_id) && web_push_notification_enabled && !isSupport) {
            dispatch(initOneSignal())
        }
    }, [])

    OneSignalReact.on('subscriptionChange', async function (event) {
        if (event && userId) {
            setTimeout(() => {
                setExternalUserId(userId)
                if (!isAdmin) {
                    SetTagsToOneSignal(user)
                }
            }, [3000])
            setExternalUserId(userId)
            if (!isAdmin) {
                SetTagsToOneSignal(user)
            }
        } else {
            removeUserDataFromOneSignal()
        }
    })

    return (<></>)
}

OneSignalWrapper.propTypes = {
    children: PropTypes.any,
    auth: PropTypes.object.isRequired,
    pushNotification: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    pushNotification: state.pushNotification
})

export default connect(mapStateToProps, {})(withRouter(OneSignalWrapper))

