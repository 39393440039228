import axios from 'axios'
import { getAppBaseUrl } from './api'
import _ from 'lodash'
import { UPDATE_USER_SUBSCRIPTION_STATUS } from './../actions/types'
import {
    UPDATE_BLOG_DATA,
    BLOG_UNLOCK_PAYMENT_PROCESSING,
    BLOG_UNLOCK_PAYMENT_COMPLETED,
    PAYMENT_PROCESSING,
    PAYMENT_COMPLETED
} from './types'
import store from './../store'
import { googleAnalyticsTrackEvent, setPromotionGoogleAnalyticsEvent } from '../utils/GoogleAnalyticsEvent'
import { getSingleMessage, refreshMessageCount } from './chatActions'
import { updateRebillFailedStatus } from './ccbillSubscriptionActions'
import { awaitGetUserDetails } from './authActions'
import { setSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

/**
 * @description New User Subscription.
 * @param {object} data user address and card details.
 * @param {function} callback success or fail messages.
 */
export const subscriptionUsingStickyIo = (data, callback) => () => {
    const url = BASE_URL + '/api/sticky-io/subscription'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            callback(errorMessage)
        })
}

/**
 * @description update user's subscription status client side
 * @returns {object} client side update user subscription status type
 */
export const updateUserSubscriptionStatus = () => {
    return {
        type: UPDATE_USER_SUBSCRIPTION_STATUS
    }
}

/**
 * @description If user already added card then subscribe using that card
 * @param {object} data subscription data for card
 * @param {function} callback success or fail messages.
 */
export const userSubscriptionWithCardStickyIo = (data, callback) => () => {
    const url = BASE_URL + '/api/sticky-io/subscribe-with-card'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            callback(errorMessage)
        })
}

/**
 * @description cancelSubscription
 */
export const cancelStickyIoSubscription = (data = { cancellation_reason: '' }) => async (dispatch) => {
    const url = `${BASE_URL}/api/sticky-io/cancel-subscription`
    try {
        const res = await axios.post(url, data)
        const response = res.data
        const payload = {
            description: 'There was a problem cancelling your subscription. Please try again after an hour.'
        }
        if (response.data.cancelled === true) {
            payload.description = 'Subscription Cancelled'
            updateRebillFailedStatus()
            await dispatch(awaitGetUserDetails(false))
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const payload = {
            description: errorMessage
        }
        return dispatch(setSweetAlert(payload))
    }
}

/**
 * @description Blog Content Purchase
 * @param {object} data Blog Content Purchase data for payment
 */
export const blogContentPurchase = (data) => async (dispatch) => {
    dispatch(blogUnlockPaymentProcessing())

    const url = `${BASE_URL}/api/sticky-io/blog-content-purchase`
    try {
        const res = await axios.post(url, data)
        const response = res.data

        dispatch(blogUnlockPaymentResponseReceived())
        if (response.data.success === 0) {
            return alert(response.data.message)
        }

        dispatch({ type: UPDATE_BLOG_DATA, payload: response.data.blogData })
        const isRePost = _.get(res, 'data.blogData.udid', '').length > 1
        // Fetch data from store
        const state = store.getState()
        const userId = _.get(state, 'auth.user._id', false)
        const transactionId = userId ? response.data.transactionId + '-' + userId : response.data.transactionId
        const productSKU = (data.blogType === 'photo') ? 'image' : data.blogType
        const productCategory = isRePost === true ? 'unlock reuse feed' : 'unlock feed'
        // Set google analytics event for unlock feed
        googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, productCategory, data.title)

        if (data.is_promotion_applied === true && data.promotion_id !== false) {
            setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
        }
        return alert(response.data.message)
    } catch (error) {
        dispatch(blogUnlockPaymentResponseReceived())
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return alert(errorMessage)
    }
}

export const blogUnlockPaymentProcessing = () => {
    return {
        type: BLOG_UNLOCK_PAYMENT_PROCESSING
    }
}

export const blogUnlockPaymentResponseReceived = () => {
    return {
        type: BLOG_UNLOCK_PAYMENT_COMPLETED
    }
}

export const paymentProcessing = () => {
    return {
        type: PAYMENT_PROCESSING
    }
}

// Chat content purchase
export const chatContentPurchase = (data) => async (dispatch) => {
    dispatch(paymentProcessing())

    const url = `${BASE_URL}/api/sticky-io/chat-content-purchase`
    try {
        const res = await axios.post(url, data)
        const response = res.data

        dispatch(getSingleMessage(response.data.messageObject))
        if (response.data.success === 0) {
            return alert(response.data.message)
        }

        // Fetch data from store
        const state = store.getState()
        let userId = _.get(state, 'auth.user._id', false)
        let transactionId = userId ? response.data.transactionId + '-' + userId : response.data.transactionId

        // Set google analytics event for unlock chat
        googleAnalyticsTrackEvent('purchase', transactionId, data.amount, data.productSKU, data.productName, data.productCategory)

        if (data.action === 'chat' && data.promotion_id !== false) {
            setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
        }
        return alert(response.data.message)

    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return alert(errorMessage)
    }
}

export const stickyIoTipFromChat = (data, userId, callback) => async (dispatch) => {
    const url = `${BASE_URL}/api/sticky-io/send-tip-from-chat`
    dispatch(paymentProcessing())

    try {
        const res = await axios.post(url, data)
        const response = res.data
        if (data.action === 'tips') {
            const transactionId = `${response.data.transactionId}-${userId}`
            const productSKU = data.tipFrom === 'go live stream' ? data.tipFrom : 'chat'
            let product = data.tipFrom === 'go live stream' ? 'go live tip' : 'tip'
            // Set google analytics event for tip
            googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, product, '')
        }
        dispatch(paymentResponseReceived())
        callback(response)
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return dispatch(setSweetAlert({ description: errorMessage }))
    }
}

export const paymentResponseReceived = () => {
    return {
        type: PAYMENT_COMPLETED
    }
}

export const stickyIoNewPayment = (data, userId) => async (dispatch) => {
    const url = (userId !== undefined) ? BASE_URL + '/api/sticky-io/send-tip' : BASE_URL + '/api/ccbill/pay-guest'
    dispatch(paymentProcessing())

    try {
        const res = await axios.post(url, data)
        const response = res.data
        if (response.data.success === 0) {
            dispatch(paymentResponseReceived())
            return alert(response.data.message)
        }

        if (data.action === 'tips') {
            const transactionId = userId !== undefined ? `${res.data.transactionId}-${userId}` : res.data.transactionId
            // For now its fixed 'menu' because there is no option for tip in live video or chat
            const productSKU = 'menu'
            // Set google analytics event for tip
            googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, 'tip', '')
        }
        if (data.action === 'subscription' && data.promotion_id !== false) {
            setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
        }
        dispatch(paymentResponseReceived())
        return alert(res.data.message)

    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return alert(errorMessage)
    }
}

export const stickyIoShopPayment = (data, navigate) => async (dispatch) => {
    const url = BASE_URL + '/api/sticky-io/shop-payment'
    dispatch(paymentProcessing())

    try {
        const res = await axios.post(url, data)
        const response = res.data
        if (response.data.success === 0) {
            dispatch(paymentResponseReceived())
            return alert(response.data.message)
        }

        alert(res.data.message)
        dispatch(refreshMessageCount())
        return navigate('/')

    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return alert(errorMessage)
    }
}

/**
 * @description Get All Subscription products.
 * @param {object} data product filter.
 * @param {function} callback success or fail messages.
 */
export const getSubscriptionProductList = (data, callback) => () => {
    axios.post(BASE_URL + '/api/sticky-io-subscription-products', data)
        .then((res) => {
            callback(res.data.data)
        })
        .catch((err) => {
            const error = {
                isError: true,
                message: err.response.data.message
            }
            callback(error)
        })
}

/**
 * @description Add New Card.
 * @param {object} data user address and card details.
 * @param {function} callback success or fail messages.
 */
export const addNewCardStickyIo = (data, callback) => () => {
    const url = BASE_URL + '/api/sticky-io/add-payment-method'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            callback(errorMessage)
        })
}
