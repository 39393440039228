import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Error404 from './../../pages/404.react'

const isAllowd = (roles, isAdmin, isContentManager, isModel, isSuperAdmin, isSupport, isSubAdmin) => {
    let user_role = 'user'
    if (isAdmin && isContentManager) {
        user_role = 'content_manager'
    } else if (isAdmin && isModel) {
        user_role = 'model'
    } else if (isAdmin && isSuperAdmin) {
        user_role = 'admin'
    } else if (isAdmin && isSupport) {
        user_role = 'support'
    } else if (isAdmin && isSubAdmin) {
        user_role = 'sub_admin'
    }
    return roles.includes(user_role)
}

const PrivateRoute = ({ auth, allow }) => {
    if (!auth.isAuthenticated) {
        return redirectRoute()
    }

    if (isAllowd(allow, auth.isAdmin, auth.isContentManager, auth.isModel, auth.isSuperAdmin, auth.isSupport, auth.isSubAdmin)) {
        return <Outlet />
    } else {
        return <Error404 />
    }
}

const redirectRoute = () => {
    const currentRoute = window.location.href
    const actualRoute = currentRoute.split('/')
    const verificationSource = actualRoute[actualRoute.length - 2]
    if (['opt-in-email-verification', 'change-email-verification'].includes(verificationSource)) {
        localStorage.setItem('routeBeforeLogin', window.location.href)
    }
    return <Navigate to="/login" />
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
    allow: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)
