import React from 'react'
import { withRouter } from '../WithRouter'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

function PaymentIcons(props) {
    const show_payment_icons = props.auth.appSettings.show_payment_icons
    const ccbillSubscriptionStatus = _.get(props.auth, 'user.ccbillSubscriptionStatus', '0')
    const is_crypto_payment_enabled = _.get(props.auth, 'appSettings.is_crypto_payment_enabled', false)

    return (
        (ccbillSubscriptionStatus === '0' && show_payment_icons === true) ?
            <div className='col-12 text-center p-0 mt-5'>
                <img src={getCloudFrontAssetsUrl('payment-icon/visa.png')} alt='visa' className='mx-2 my-1' width='40' />
                <img src={getCloudFrontAssetsUrl('payment-icon/mastercard.png')} alt='mastercard' className='mx-2 my-1' width='40' />
                <img src={getCloudFrontAssetsUrl('payment-icon/discover.png')} alt='discover' className='mx-2 my-1' width='40' />
                <img src={getCloudFrontAssetsUrl('payment-icon/jcb.png')} alt='jcb' className='mx-2 my-1' width='40' />
                <img src={getCloudFrontAssetsUrl('payment-icon/maestro.png')} alt='maestro' className='mx-2 my-1' width='40' />
                <img src={getCloudFrontAssetsUrl('payment-icon/diners_club.png')} alt='diners_club' className='mx-2 my-1' width='40' />
                {is_crypto_payment_enabled && <img src={getCloudFrontAssetsUrl('payment-icon/coins.svg')} alt='crypto' className='mx-2 my-1' width='35' />}
            </div> :
            <></>
    )
}

PaymentIcons.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(withRouter(PaymentIcons))
