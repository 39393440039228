import React from 'react'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { setSweetAlert, removeSweetAlert } from '../../actions/sweetAlertActions'
import styled from 'styled-components'
import Button from '../Layouts/Button'

const AlertDiv = styled.div`
width: 100%;
    h2, p, .description {
        color: ${props => props.color} !important;
    }
    p, .description {
        font-size: 15px !important;
        margin-top: 0px !important;
    }

    .btn-primary:not(:disabled):not(.disabled):active {
        background-color: ${props => props.backgroundColor} !important;
      }
`

function SweetAlertsWrapper(props) {
    const { card_background_color, content_color, site_font_color, content_font_color, button_shadow_color } = props.auth.appSettings
    const { description, onConfirmUrl } = props.sweetAlert

    const { showAlert } = props.sweetAlert

    const onConfirm = () => {
        props.removeSweetAlert()
        if (onConfirmUrl !== '') {
            props.navigate(onConfirmUrl)
        }
    }

    return (
        <>
            {showAlert === true &&
                <AlertDiv
                    backgroundColor={content_color}
                    color={site_font_color}
                    btnFontColor={content_font_color}
                    button_shadow_color={button_shadow_color}
                >
                    <SweetAlert
                        title={''}
                        style={{ backgroundColor: card_background_color, color: content_font_color }}
                        btnSize='md'
                        showConfirm={false}
                    >
                        {typeof description === 'object'
                            ? <p>{description}</p>
                            : <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        }
                        <Button
                            type='button'
                            variant="primary"
                            onClick={onConfirm}
                        >OK</Button>
                    </SweetAlert>
                </AlertDiv>
            }
        </>
    )
}

SweetAlertsWrapper.propTypes = {
    auth: PropTypes.object.isRequired,
    sweetAlert: PropTypes.object.isRequired,
    children: PropTypes.object,
    setSweetAlert: PropTypes.func.isRequired,
    removeSweetAlert: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired
}

SweetAlertsWrapper.defaultProps = {
    title: '',
    confirmBtnText: 'OK',
    cancelBtnText: '',
    onConfirmUrl: ''
}

const mapStateToProps = state => ({
    auth: state.auth,
    sweetAlert: state.sweetAlert
})

export default connect(mapStateToProps, { setSweetAlert, removeSweetAlert })(withRouter(SweetAlertsWrapper))
