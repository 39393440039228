import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getCountries, getStates, getCities } from '../../actions/SubscribeActions'
import { newUserSubscription, updateUserSubscriptionStatus, userSubscriptionWithCard, getCountryFromIP } from '../../actions/ccbillSubscriptionActions'
import { subscriptionUsingStickyIo, addNewCardStickyIo, userSubscriptionWithCardStickyIo } from '../../actions/stickyIoActions'
import _ from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { getUsersPaymentMethod } from './../../actions/paymentMethodAction'
import CreatableSelect from 'react-select/creatable'
import Loader from '../Layouts/Loader'
import { subscriptionPayment, blogPurchasePayment, chatPurchasePayment, TipPayment } from './../../actions/hybridPaymentAction'
import { togglePaymentModal } from './../../actions/utilityActions'
import ModalPopUp from '../Layouts/ModalPopUp'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const CustomForm = styled.div`

input, textarea, select {
    padding: 15px;
    border: 1px solid #0028641f;
    width: -webkit-fill-available !important;
}
input:focus, textarea:focus, select:focus {
    outline: 1px solid #0028641f;
    box-shadow: none;
    border: 1px border transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control__input input {
    margin: 5px 0px;
}
.form-control__control {
    padding-left: 8px;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
select {
    height: auto !important;
}
img {
    object-fit: contain;
}
.form-control__menu {
    color: black
}
.selection>div>div {
    border-color: hsl(0, 0%, 90%);
}
.payment_billing_information, .payment_card_information {
    padding-left: 50px;
    padding-right: 50px;
}

@media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
    input, textarea, select {
        padding-right: 5px;
        padding-left: 5px;
    }
    .payment_billing_information, .payment_card_information {
        padding-left: 5px;
        padding-right: 5px;
    }
    .card_number img{
        right: 20px !important;
    }
}
`

const SubmitButton = styled.button`
    border-radius: calc(1.5em + 0.37rem + 1px) !important;
    background-color: ${props => props.contentColor} !important;
    color: ${props => props.contentFontColor} !important;
    
    &:hover {
        color: ${props => props.contentFontColor} !important;
    }
`

function PaymentForm(props) {
    const { register, handleSubmit, errors, setValue, watch, control } = useForm()

    const countryId = watch('country')
    const stateId = watch('state')
    let [isLoading, setIsLoading] = useState(false)
    let [countryList, setCountryList] = useState([])
    let [stateList, setStateList] = useState([])
    let [cityList, setCityList] = useState([])
    let [isPaymentInProcess, setIsPaymentInProcess] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [addNewCard, setAddNewCard] = useState(false)
    const [cardType, setCardType] = useState('')
    const [defaultCountry, setDefaultCountry] = useState('')
    const [isCountryLoading, setIsCountryLoading] = useState(false)
    let [isDataLoaded, setIsDataLoaded] = useState(false)
    let [isInputDisabled, setIsInputDisabled] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    let [allCountries, setAllCountries] = useState([])
    let [allStates, setAllStates] = useState([])

    const getStateListFromCountryId = (countryId) => {
        let data = {
            countryId: countryId
        }
        setIsLoading(true)
        setCityList([])
        props.getStates(data, (res) => {
            const stateOptions = res.data.states.map((data) => {
                return {
                    label: data.name,
                    value: data._id
                }
            })
            setStateList(stateOptions)
            setAllStates(res.data.states)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (countryId !== '' && countryId !== undefined) {
            getStateListFromCountryId(countryId)
        }
    }, [countryId])

    useEffect(() => {
        if (stateId !== '' && stateId !== undefined) {
            let data = {
                stateId: stateId,
                countryId: countryId
            }
            setIsLoading(true)
            setCityList([])
            props.getCities(data, (res) => {
                const cityOptions = res.data.cities.map((data) => {
                    return {
                        label: data.name,
                        value: data._id
                    }
                })
                setCityList(cityOptions)
                setIsLoading(false)
            })
        }
    }, [stateId])

    useEffect(() => {
        const userEmail = props.auth.user.email
        setValue('email', userEmail)
        setUserEmail(userEmail)
        props.getCountryFromIP((res) => {
            const countryFromIP = _.get(res, 'data.country', '')
            props.getCountries((res) => {
                setIsLoading(false)
                const countryOptions = res.data.countries.map((data) => {
                    return {
                        label: data.name,
                        value: data._id
                    }
                })
                setCountryList(countryOptions)
                setAllCountries(res.data.countries)
                // country from IP is return iso2 code for country, find country id from iso2 code in country list
                const country = res.data.countries.filter((data) => data.iso2.toLowerCase() === countryFromIP)
                if (country.length > 0) {
                    setIsCountryLoading(true)
                    setDefaultCountry(country[0]._id)
                    getStateListFromCountryId(country[0]._id)
                    setIsCountryLoading(false)
                }
            })
        })

        if (props.addCardAndSubscription === true) {
            getPaymentMethods()
        } else {
            setAddNewCard(true)
            setIsDataLoaded(true)
        }
    }, [])

    const getPaymentMethods = () => {
        setIsLoading(true)
        setIsDataLoaded(false)
        props.getUsersPaymentMethod(res => {
            setAddNewCard(res.data.length > 0 ? false : true)
            setIsLoading(false)
            setIsDataLoaded(true)
        })
    }

    const {
        content_color,
        content_font_color,
        new_signup_design_title_font_size,
        new_signup_design_description_font_size,
        card_background_color,
        site_font_color,
        payment_form_bg_color,
        payment_form_text_color,
        payment_form_border_radius,
        payment_form_btn_bg_color,
        payment_form_btn_text_color,
        payment_form_padding
    } = props.auth.appSettings

    const formTextColor = props.addCardAndSubscription === true ? (payment_form_text_color || site_font_color) : site_font_color
    const formBorderRadius = props.addCardAndSubscription === true ? (payment_form_border_radius || '0') : '0'
    const formBtnBgColor = props.addCardAndSubscription === true ? (payment_form_btn_bg_color || content_color) : content_color
    const formBtnTextColor = props.addCardAndSubscription === true ? (payment_form_btn_text_color || content_font_color) : content_font_color
    const formPadding = props.addCardAndSubscription === true ? (payment_form_padding || '15') : '15'

    let formBgColor = card_background_color
    if (props.addCardAndSubscription === true) {
        if (payment_form_bg_color !== undefined && payment_form_bg_color !== '') {
            formBgColor = payment_form_bg_color
        } else if (card_background_color.toLowerCase() === '#fff' || card_background_color.toLowerCase() === '#ffffff') {
            formBgColor = '#fffcf6'
        } else {
            formBgColor = card_background_color
        }
    }

    let titleFontSize = new_signup_design_title_font_size.split('|')
    if (titleFontSize.length !== 4) {
        titleFontSize = ['55px', '65px', '75px', '85px']
    }

    let descriptionFontSize = new_signup_design_description_font_size.split('|')
    if (descriptionFontSize.length !== 4) {
        descriptionFontSize = ['25px', '25px', '35px', '35px']
    }
    let CardMonthsOptions = [
        { value: '01', label: '01 - January' },
        { value: '02', label: '02 - February' },
        { value: '03', label: '03 - March' },
        { value: '04', label: '04 - April' },
        { value: '05', label: '05 - May' },
        { value: '06', label: '06 - June' },
        { value: '07', label: '07 - July' },
        { value: '08', label: '08 - August' },
        { value: '09', label: '09 - September' },
        { value: '10', label: '10 - October' },
        { value: '11', label: '11 - November' },
        { value: '12', label: '12 - December' }
    ]

    const CardMonths = CardMonthsOptions.map(option => (
        <option key={option.label} value={option.value}>
            {option.label}
        </option>
    ))

    const YearsList = useMemo(() => {
        const currentYear = new Date().getFullYear()
        let yearArray = []
        for (let index = 0; index < 15; index++) {
            yearArray.push(currentYear + index)
        }
        return yearArray.map(option => (
            <option key={option} value={option}>
                {option}
            </option>
        ))
    })

    const onSubmit = (data) => {
        if (cardType === 'unknown') {
            alert('Invalid payment card type.')
            return false
        }

        data = { ...data, ...props.utility.paymentRequest }
        data.addCardAndSubscription = props.addCardAndSubscription
        data.payment_cascade = props.utility.paymentCascade
        data.is_cascade = true
        data.email = data.email.trim()
        data.address = data.address.trim()
        data.country = data.country.trim()
        data.state = data.state.trim()
        data.city = data.city.trim()
        data.pincode = data.pincode.trim()
        data.firstName = data.firstName.trim()
        data.lastName = data.lastName.trim()
        data.cardNumber = data.cardNumber.trim()
        data.cvv = data.cvv.trim()
        data.month = data.month.trim()
        data.year = data.year.trim()
        // to save cardType (visa, mastercard etc.) in database.
        data.card_type = cardType === 'mastercard' ? 'master' : cardType.trim()
        validateCardAndContentPurchase(data)
    }

    async function validateCardAndContentPurchase(data) {
        const countryName = allCountries.filter((country) => country._id === data.country)
        data.country = countryName[0].iso2
        const stateName = allStates.filter((state) => state._id === data.state)
        data.state = stateName[0] ? stateName[0].state_code : ''
        const cityName = cityList.filter((city) => city.value === data.city)
        data.city = cityName[0] ? cityName[0].label : ''

        let payment
        changeProcessingStatus(true)
        if (data.action === 'blog') {
            payment = await props.blogPurchasePayment(data)
        }

        if (data.action === 'chat') {
            payment = await props.chatPurchasePayment(data)
        }

        if (data.action === 'tips') {
            payment = await props.TipPayment(data, props.auth.user._id)
        }

        changeProcessingStatus(false)
        if (payment.success === 1) {
            document.querySelector('body').style.overflow = ''
            props.togglePaymentModal({})
        }
    }

    const changeProcessingStatus = (status) => {
        setIsInputDisabled(status)
        setIsLoading(status)
        setIsPaymentInProcess(status)
    }

    const handleCreateNewCity = (inputValue) => {
        const newOption = {
            label: inputValue,
            value: inputValue.trim()
        }
        cityList = [...cityList, newOption]
        setCityList(cityList)
    }

    function getCardType(cur_val) {
        // the regular expressions check for possible matches as user type
        const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$') // 2131, 1800, 35 (3528-3589)
        const amex_regex = new RegExp('^3[47][0-9]{0,}$') // 34, 37
        const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$') // 300-305, 309, 36, 38-39
        const visa_regex = new RegExp('^4[0-9]{0,}$') // 4
        const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$') // 2221-2720, 51-55
        const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$') // always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
        const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$') // 6011, 622126-622925, 644-649, 65

        // get rid of anything but numbers
        cur_val = cur_val.replace(/\D/g, '')

        // checks per each, as their could be multiple hits
        // fix: ordering matter in detection, otherwise can give false results in rare cases
        let card_type = 'unknown'
        if (cur_val.match(jcb_regex)) {
            card_type = 'jcb'
        } else if (cur_val.match(amex_regex)) {
            card_type = 'amex'
        } else if (cur_val.match(diners_regex)) {
            card_type = 'diners_club'
        } else if (cur_val.match(visa_regex)) {
            card_type = 'visa'
        } else if (cur_val.match(mastercard_regex)) {
            card_type = 'mastercard'
        } else if (cur_val.match(discover_regex)) {
            card_type = 'discover'
        } else if (cur_val.match(maestro_regex)) {
            if (cur_val[0] === '5') { // started 5 must be mastercard
                card_type = 'mastercard'
            } else {
                // maestro is all 60-69 which is not something else, thats why this condition in the end
                card_type = 'maestro'
            }
        }

        return card_type
    }

    const regex = /^[0-9]{0,19}$/
    const onCardNumberChange = (value) => {
        const card_type = getCardType(value)
        setCardType(card_type)
        if (regex.test(value) === true) {
            setValue('cardNumber', value)
            setCardNumber(value)
            return
        }
        setValue('cardNumber', cardNumber)
    }

    return (
        isDataLoaded === true &&
        <ModalPopUp
            title='Add Card Details'
            handleClose={() => props.togglePaymentModal({})}
            modalBodyMaxHeight='100vh'
        >
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: `${formBorderRadius}px`, overflow: 'hidden' }}>
                <CustomForm
                    contentColor={content_color}
                    contentFontColor={content_font_color}
                >
                    {
                        addNewCard === true && <>
                            <div className='payment_billing_information mb-5' style={{ background: formBgColor, padding: `${formPadding}px`, borderBottomLeftRadius: `${formBorderRadius}px`, borderBottomRightRadius: `${formBorderRadius}px` }}>
                                <div className='col-12'>
                                    <h2 className='text-center' style={{ color: formTextColor }}>BILLING INFORMATION</h2>
                                </div>
                                <div className='form-group row mb-0'>
                                    <div className='col-12 mb-2'>
                                        <input type='email' name='email' className='form-control w-100 text-secondary h-auto' placeholder='Email Address' defaultValue={userEmail} ref={register({ required: true, pattern: /^\S+@\S+$/i })} disabled={isInputDisabled} />
                                        {errors.email && <>
                                            {errors.email.type === 'required' && <span className='text-red mx-3'>Please enter an email.</span>}
                                            {errors.email.type === 'pattern' && <span className='text-red mx-3'>Please enter a valid email.</span>}
                                        </>
                                        }
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <textarea name='address' rows='1' className='form-control w-100 h-auto' placeholder='Address' ref={register({ required: true })} disabled={isInputDisabled}></textarea>
                                        {errors.address && <span className='text-red mx-3'>Please enter an address.</span>}
                                    </div>
                                    {
                                        isCountryLoading === false &&
                                        <div className='col-12 selection mb-2'>
                                            <Controller
                                                control={control}
                                                defaultValue={defaultCountry}
                                                name="country"
                                                render={({ onChange, value, ref }) => (
                                                    <Select
                                                        style={{ color: site_font_color }}
                                                        inputRef={ref}
                                                        classNamePrefix="form-control"
                                                        options={countryList}
                                                        value={countryList.find(c => c.value === value)}
                                                        onChange={val => onChange(val.value)}
                                                        placeholder={'Select country'}
                                                        disabled={isInputDisabled}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                            />
                                            {errors.country && <span className='text-red mx-3'>Please enter a country.</span>}
                                        </div>
                                    }
                                    <div className='col-12 selection mb-2'>
                                        <Controller
                                            control={control}
                                            defaultValue={''}
                                            name="state"
                                            render={({ onChange, value, ref }) => (
                                                <Select
                                                    style={{ color: site_font_color }}
                                                    inputRef={ref}
                                                    classNamePrefix="form-control"
                                                    options={stateList}
                                                    value={stateList.find(c => c.value === value)}
                                                    onChange={val => onChange(val.value)}
                                                    placeholder={'Select state/province'}
                                                    disabled={isInputDisabled}
                                                />
                                            )}
                                            rules={{ required: allStates.length > 0 ? true : false }}
                                        />
                                        {errors.state && <span className='text-red mx-3'>Please enter a state/province.</span>}
                                    </div>
                                    <div className='col-12 selection mb-2'>
                                        <Controller
                                            control={control}
                                            defaultValue={''}
                                            name="city"
                                            render={({ onChange, value, ref }) => (
                                                <CreatableSelect
                                                    style={{ color: site_font_color }}
                                                    classNamePrefix="form-control"
                                                    isClearable
                                                    onChange={val => onChange(val !== null ? val.value : null)}
                                                    onCreateOption={val => { handleCreateNewCity(val); onChange(val) }}
                                                    options={cityList}
                                                    value={cityList.find(c => c.value === value)}
                                                    placeholder={'Select city'}
                                                    inputRef={ref}
                                                    disabled={isInputDisabled}
                                                    formatCreateLabel={(input) => input}
                                                />
                                            )}
                                            rules={{ required: cityList.length > 0 ? true : false }}
                                        />{errors.city && <span className='text-red mx-3'>Please enter a city.</span>}
                                    </div>
                                    <div className='col-12'>
                                        <input type='text' id='pincode' name='pincode' className='form-control w-100 text-secondary h-auto' placeholder='Postal Code' ref={register({ required: true })} disabled={isInputDisabled} />
                                        {errors.pincode && <span className='text-red mx-3'>Please enter a postal code.</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='payment_card_information' style={{ background: formBgColor, padding: `${formPadding}px`, borderTopLeftRadius: `${formBorderRadius}px`, borderTopRightRadius: `${formBorderRadius}px` }}>
                                <div className='col-12 text-center'>
                                    <h2 style={{ color: formTextColor }}>CARD PAYMENT INFORMATION</h2>
                                    <img src={getCloudFrontAssetsUrl('payment-icon/visa.png')} alt='visa' className='m-2' width='30px' height='30px' />
                                    <img src={getCloudFrontAssetsUrl('payment-icon/mastercard.png')} alt='mastercard' className='m-2' width='30px' height='30px' />
                                    <img src={getCloudFrontAssetsUrl('payment-icon/discover.png')} alt='discover' className='m-2' width='30px' height='30px' />
                                    <img src={getCloudFrontAssetsUrl('payment-icon/jcb.png')} alt='jcb' className='m-2' width='30px' height='30px' />
                                    <img src={getCloudFrontAssetsUrl('payment-icon/maestro.png')} alt='maestro' className='m-2' width='30px' height='30px' />
                                    <img src={getCloudFrontAssetsUrl('payment-icon/diners_club.png')} alt='diners_club' className='m-2' width='30px' height='30px' />
                                </div>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <input type='text' name='firstName' className='form-control w-100 h-auto' placeholder='First Name' ref={register({ required: true })} disabled={isInputDisabled} />
                                        {errors.firstName && <span className='text-red mx-3'>Please enter a first name.</span>}
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <input type='text' name='lastName' className='form-control w-100 h-auto' placeholder='Last Name' ref={register({ required: true })} disabled={isInputDisabled} />
                                        {errors.lastName && <span className='text-red mx-3'>Please enter a last name.</span>}
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-8 d-inline-block card_number'>
                                                {
                                                    (cardType !== '' && cardType !== 'unknown') && <img src={getCloudFrontAssetsUrl(`payment-icon/${cardType}.png`)} alt={cardType} width='40px' height='30px' style={{ position: 'absolute', right: '30px', top: '16px' }} />
                                                }
                                                <input
                                                    name='cardNumber' type='text'
                                                    className='form-control text-secondary w-100 d-inline h-auto'
                                                    placeholder='Enter only numbers. No spaces or dashes.'
                                                    style={{ paddingRight: '25px' }}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 19,
                                                        minLength: 13,
                                                        pattern: regex
                                                    })}
                                                    defaultValue={cardNumber}
                                                    onChange={(e) => onCardNumberChange(e.target.value)}
                                                    disabled={isInputDisabled}
                                                />
                                                {errors.cardNumber &&
                                                    <>
                                                        {errors.cardNumber.type === 'required' && <span className='text-red mx-3'>Please enter a card number.</span>}
                                                        {errors.cardNumber.type === 'maxLength' && <span className='text-red mx-3'>Please enter a valid card number.</span>}
                                                        {errors.cardNumber.type === 'minLength' && <span className='text-red mx-3'>Please enter a valid card number.</span>}
                                                        {errors.cardNumber.type === 'pattern' && <span className='text-red mx-3 d-block'>Please enter a valid card number (Only numbers allowed)</span>}
                                                    </>
                                                }
                                            </div>
                                            <div className='col-4 d-inline-block pl-0'>
                                                <input type='password' name='cvv' className='form-control w-100 h-auto' placeholder='CVV' ref={register({ required: true, minLength: 3, maxLength: 4, pattern: /\d+/ })} disabled={isInputDisabled} />
                                                {errors.cvv &&
                                                    <>
                                                        {errors.cvv.type === 'required' && <span className='text-red mx-3'>Please enter a cvv.</span>}
                                                        {errors.cvv.type === 'maxLength' && <span className='text-red mx-3'>Please enter a valid cvv.</span>}
                                                        {errors.cvv.type === 'minLength' && <span className='text-red mx-3'>Please enter a valid cvv.</span>}
                                                        {errors.cvv.type === 'pattern' && <span className='text-red mx-3'>Please enter a valid cvv.</span>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='row'>
                                            <div className='col-6 d-inline-block'>
                                                <select id='inputMonth' name='month' className='form-control custom-select' ref={register({ required: true })} disabled={isInputDisabled}>
                                                    <option value=''>Choose Month</option>
                                                    {CardMonths}
                                                </select>
                                                {errors.month && <span className='text-red mx-3'>Please select month.</span>}
                                            </div>
                                            <div className='col-6 d-inline-block pl-0'>
                                                <select id='inputYear' name='year' className='form-control custom-select' ref={register({ required: true })} disabled={isInputDisabled}>
                                                    <option value=''>Choose Year</option>
                                                    {YearsList}
                                                </select>
                                                {errors.year && <span className='text-red mx-3'>Please select year.</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='col-12 text-center submit_btn' style={{ background: formBgColor, padding: `${formPadding}px`, paddingTop: '0' }}>
                        {
                            props.addCardAndSubscription === true ?
                                <>
                                    <p style={{ color: formTextColor }}>
                                        By completing this order, I confirm that I am 18 (some locations 21) years or older and agree with the <a className='styled-link' href='/privacy-policy' target='_blank'>privacy statement</a> and <a className='styled-link' href='/terms-of-service' target='_blank'>general terms and conditions</a>.
                                    </p>
                                    <SubmitButton
                                        type='submit'
                                        className='btn z-depth-1 btn-lg mx-auto'
                                        contentColor={formBtnBgColor}
                                        contentFontColor={formBtnTextColor}
                                        disabled={isLoading}
                                    >
                                        {isPaymentInProcess === true ? 'Payment processing' : 'Subscribe Now'}
                                        <Loader loading={isPaymentInProcess} color={formBtnTextColor} size={10} />
                                    </SubmitButton>
                                </>
                                : <SubmitButton
                                    type='submit'
                                    className='btn z-depth-1 btn-lg mx-auto'
                                    contentColor={formBtnBgColor}
                                    contentFontColor={formBtnTextColor}
                                    disabled={isLoading}
                                >
                                    {isPaymentInProcess === true ? 'Processing Card' : 'Add Card'}
                                    <Loader loading={isPaymentInProcess} color={formBtnTextColor} size={10} />
                                </SubmitButton>
                        }

                    </div>
                </CustomForm>
            </form>
        </ModalPopUp>
    )
}

PaymentForm.propTypes = {
    auth: PropTypes.object.isRequired,
    getCountries: PropTypes.func.isRequired,
    getCities: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    newUserSubscription: PropTypes.func.isRequired,
    addCardAndSubscription: PropTypes.bool.isRequired,
    updateUserSubscriptionStatus: PropTypes.func.isRequired,
    paymentMethods: PropTypes.array,
    getUsersPaymentMethod: PropTypes.func,
    userSubscriptionWithCard: PropTypes.func,
    getCountryFromIP: PropTypes.func,
    promotion: PropTypes.object.isRequired,
    subscriptionUsingStickyIo: PropTypes.func.isRequired,
    addNewCardStickyIo: PropTypes.func.isRequired,
    resubscriptionOffer: PropTypes.object.isRequired,
    userSubscriptionWithCardStickyIo: PropTypes.func.isRequired,
    subscriptionPayment: PropTypes.func.isRequired,
    blogPurchasePayment: PropTypes.func.isRequired,
    togglePaymentModal: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired,
    chatPurchasePayment: PropTypes.func.isRequired,
    TipPayment: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    promotion: state.promotion,
    resubscriptionOffer: state.resubscriptionOffer,
    utility: state.utility
})

export default connect(mapStateToProps, {
    getCountries,
    getStates,
    getCities,
    newUserSubscription,
    updateUserSubscriptionStatus,
    getUsersPaymentMethod,
    userSubscriptionWithCard,
    getCountryFromIP,
    subscriptionUsingStickyIo,
    addNewCardStickyIo,
    userSubscriptionWithCardStickyIo,
    subscriptionPayment,
    blogPurchasePayment,
    chatPurchasePayment,
    togglePaymentModal,
    TipPayment
})(withRouter(PaymentForm))
