import React, { useEffect, useState } from 'react'
import { withRouter } from '../WithRouter'
import { useForm } from 'react-hook-form'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { addOrUpdateFeedTag } from '../../actions/FeedTagsActions'
import styled from 'styled-components'
import ModalPopUp from '../Layouts/ModalPopUp'
import _ from 'lodash'
import TextFieldGroup from '../Layouts/TextFieldGroup'
import Button from '../Layouts/Button'
import { SET_FEED_TAG } from '../../actions/types'
import { setSweetAlert } from '../../actions/sweetAlertActions'

const CancelButton = styled.button`
        border-color: ${props => props.btnBorderColor} !important;
        color: ${props => props.btnColor} !important;
        box-shadow : none;
        margin-right:20px;
        paddingX: 50px;
`

function FeedTagsAddDialog(props) {
    const { register, handleSubmit } = useForm()
    const {
        content_color,
        site_font_color,
        feed_tags
    } = props.auth.appSettings

    const [feedTags, updateFeedTags] = useState({
        name: '',
        slug: ''
    })
    const [errorText, setErrorText] = useState('')
    const [DataLoading, updateDataLoading] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.feedTags) {
            if (props.feedTags.name !== '') {
                updateFeedTags(props.feedTags)
            } else {
                updateFeedTags({
                    name: '',
                    slug: ''
                })
            }
        }
    }, [])

    const manageFeedTags = async () => {
        updateDataLoading(true)
        setDisableBtn(true)
        let NewFeedTag = {
            id: _.get(feedTags, 'id', ''),
            name: feedTags.name.trim(),
            slug: feedTags.name.toLowerCase().trim(),
            blogId: _.get(props, 'blogId', '')
        }
        const tag = feed_tags.filter(d => d.slug === NewFeedTag.slug)
        if (_.isEmpty(tag) === false) {
            if (tag[0]._id === NewFeedTag.id && tag[0].slug === NewFeedTag.slug && tag[0].name === NewFeedTag.name) {
                closePopUp()
                dispatch(setSweetAlert({ description: 'Tag update successfully' }))
            } else if (tag[0]._id !== NewFeedTag.id && tag[0].slug === NewFeedTag.slug) {
                setErrorText('Tag already exist')
            } else {
                const response = await props.addOrUpdateFeedTag(NewFeedTag)
                if (response.status === 200) {
                    dispatch(setSweetAlert({ description: response.message }))
                    closePopUp()
                } else {
                    setErrorText(response.data.message)
                }
            }
        } else {
            const isAddNewTag = _.get(props, 'isAddNewTag', false)
            if (NewFeedTag.name !== '') {
                updateFeedTags(NewFeedTag)
                const response = await props.addOrUpdateFeedTag(NewFeedTag)
                if (isAddNewTag === false) {
                    if (response.status === 200) {
                        props.feedTagList(response.data.feed_tags)
                        dispatch(setSweetAlert({ description: response.message }))
                        closePopUp()
                    } else {
                        setErrorText(response.data.message)
                    }
                } else {
                    dispatch({ type: SET_FEED_TAG, payload: response.data.feedData })
                    closePopUp()
                }
            } else {
                setErrorText('Enter Valid Tag Name')
                clearForm()
            }
        }
        setDisableBtn(true)
        updateDataLoading(false)
    }

    const clearForm = () => {
        updateFeedTags({
            name: '',
            slug: ''
        })
    }

    const closePopUp = () => {
        props.hideAddNewTag()
        clearForm()
        setErrorText('')
    }

    const onChange = (e) => {
        setDisableBtn(false)
        updateFeedTags({
            ...feedTags,
            name: e.target.value,
            slug: e.target.value.toLowerCase()
        })
        setErrorText('')
    }

    return (
        <ModalPopUp classes='modal-md' title={(feedTags === undefined || _.isEmpty(props.feedTags.name)) ? 'Add Tag' : 'Update Tag'} showCloseBtn={false} modalBodyMaxHeight='30vh'>
            <form onSubmit={handleSubmit(manageFeedTags)}>
                <div className='form-row'>
                    <div className='form-group col-12 mb-0'>
                        <TextFieldGroup
                            id='feed-tag'
                            label='Enter tag name'
                            type='text'
                            name='feedTags'
                            value={feedTags.name}
                            required={true}
                            formRef={register()}
                            onChange={(e) => onChange(e)}
                            maxLength={30}
                            error={errorText}
                            disabled={DataLoading}
                        />
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <Button loading={disableBtn} type='submit' classes='m-0 px-5' >
                        {DataLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        {_.isEmpty(props.feedTags.name) ? 'Add' : 'Update'}
                    </Button>
                    <CancelButton
                        className='btn'
                        type='button'
                        btnColor={site_font_color}
                        btnBorderColor={content_color}
                        disabled={DataLoading}
                        onClick={() => closePopUp()}
                    >
                        Cancel
                    </CancelButton>
                </div>
            </form>
        </ModalPopUp>
    )
}

FeedTagsAddDialog.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired,
    addOrUpdateFeedTag: PropTypes.func.isRequired,
    closePopUp: PropTypes.func,
    feedTags: PropTypes.array,
    feedTagList: PropTypes.func,
    isAddNewTag: PropTypes.bool,
    hideAddNewTag: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog
})

export default connect(
    mapStateToProps,
    {
        addOrUpdateFeedTag
    }
)(withRouter(FeedTagsAddDialog))
