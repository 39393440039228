import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from '../WithRouter'
import WebsiteFontColor from './WebsiteFontColor'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { isIOS } from 'react-device-detect'
import { handleIsProgressiveWebAppPopupVisible, sendMobileInfoToServices, handleProgressiveWebAppPopupShowAgain } from './../../actions/utilityActions'
import _ from 'lodash'

const PopUpBox = styled.div`
    .modal {
        display: none;
        background-color: #00000080;
        top: 0 !important;
    }
    .modal-content {
        background-color: ${props => props.card_background_color};
        width: 100vw;
        margin: 0;
        border-radius: 15px 15px 0 0;
    }
    hr {
        margin: 0;
    }
    p {
        margin: 0;
    }
    span {
        color: ${props => props.site_font_color} !important;
        margin: 0 5px;
        border: none;
    }
    .instructions {
        display: flex;
        align-item: center;
        padding: 20px 0;
    }
    .circleNumber {
        color : ${props => props.card_background_color} !important;
        background-color : ${props => props.site_font_color};
        border-radius : 50%;
        text-align: center;
        width: 25px;
        height: 25px;
        font-weight: bold;
        padding: 1px 0 0 0;
        margin: 0 0 0 -10px;
    }
    .instructions .instructions-text {
        width: 93%;
        text-align: center;
    }
    .highlightIcon {
        padding: 7px 7px 10px 7px;
        background-color : ${props => props.site_font_color}1f;
        border-radius: 5px;
    }
    .highlightText {
        padding: 8px;
        background-color : ${props => props.site_font_color}1f;
        border-radius: 5px;
    }
    .close-button {
        position: absolute;
        right: 15px;
        top: 15px
        padding: 5px 17px;
        border-radius: 50%;
        opacity: 1;
        font-size: 30px;
        color: ${props => props.site_font_color};
        background-color : ${props => props.site_font_color}1f;
    }
    @media only screen and (max-width: 991px) {
        .modal {
            display: block;
        }
    }
`

const Icons = styled.span`
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: ${props => props.color};
    mask: url(${props => props.image});
    -webkit-mask: url(${props => props.image}) no-repeat center / cover;
    margin: 0 0 -6px 0 !important;
`

function ProgressiveWebPopUp(props) {
    const { isProgressiveWebAppPopupVisible, progressiveWebAppPopupShowAgain } = props.utility
    const isAPICallInProgress = useRef(false)

    const {
        card_background_color,
        site_font_color,
        model_id,
        model_name,
        manifest_icons
    } = props.auth.appSettings

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [message, setMessage] = useState('')

    const msg1 = <p className='text-center py-5'>Enjoy a better app-like experience, improved performance, and access <b>{model_name}</b> easier directly from your home screen.</p>
    const msg2 = <p className='text-center py-5'>Do you want to enjoy an app-like experience with this website?  If you follow the steps below you will be able to launch this site right from your phone without using the browser, and the experience will be similar to all of your apps.<br /><br /> We honor your privacy so therefore, the icon that is saved to your phone is discrete and will not bring any unwanted attention to you.</p>
    const msg3 = <p className='text-center py-5'>We noticed you haven&apos;t yet chosen to save the app on your phone. We strongly suggest you do so now to access the site faster, enjoy the full size of your phone screen to view videos and photos, and have an overall app-like experience.</p>
    const msg4 = <p className='text-center py-5'>We are sorry to be a pain, but we noticed that you are still accessing the site via your browser. If you follow the steps below, you will have direct access to the site via a discrete icon on your phone. The site will be much faster, you will have your full screen to enjoy videos and photos, and the site will work just like any other app. What are you waiting for? Just do it!</p>

    useEffect(() => {
        const showProgressiveWebPopUpCount = localStorage.getItem('showProgressiveWebPopUp')
        if (showProgressiveWebPopUpCount === '50') {
            localStorage.setItem('showProgressiveWebPopUp', 12)
        }
        popupInit()
    }, [isDialogOpen, isProgressiveWebAppPopupVisible, localStorage.getItem('showProgressiveWebPopUp')])

    const popupInit = async () => {
        if (isDialogOpen && window.innerWidth <= 991) {
            document.querySelector('body').style.overflow = 'hidden'
        }
        let showProgressiveWebPopUp = localStorage.getItem('showProgressiveWebPopUp')

        setMessage(msg1)
        if (Number(showProgressiveWebPopUp) === 3) setMessage(msg1)
        if (Number(showProgressiveWebPopUp) === 12) setMessage(msg2)
        if (Number(showProgressiveWebPopUp) === 21) setMessage(msg3)
        if (Number(showProgressiveWebPopUp) === 30) setMessage(msg4)

        let showPopup = false
        showPopup = [3, 12, 21, 30].includes(Number(showProgressiveWebPopUp))
        if ((window.innerWidth <= 991) && (window.orientation !== undefined) && showProgressiveWebPopUp && showProgressiveWebPopUp !== false && (showPopup)) {
            setIsDialogOpen(true)
            props.handleIsProgressiveWebAppPopupVisible(true)
            props.handleProgressiveWebAppPopupShowAgain(true)
            if (isAPICallInProgress.current === false) {
                isAPICallInProgress.current = true
                const data = {
                    is_running_from_pwa: window.matchMedia('(display-mode: standalone)').matches === true,
                    user_id: props.auth.isAuthenticated === true ? props.auth.user._id : '',
                    user_agent: window.navigator.userAgent,
                    is_popup_opened: true,
                    ccbill_subscription_status: props.auth.user.ccbillSubscriptionStatus
                }
                await props.sendMobileInfoToServices(data)
                isAPICallInProgress.current = false
            }
        }
    }

    let profilePic = (props.chat.userProfileInfo[model_id])
        ? props.chat.userProfileInfo[model_id].profile
        : getCloudFrontAssetsUrl('faces/avatar.png')


    const closeModel = () => {
        document.querySelector('body').style.overflow = 'visible'
        const showProgressiveWebPopUpCount = localStorage.getItem('showProgressiveWebPopUp')
        if (showProgressiveWebPopUpCount === '50') {
            localStorage.setItem('showProgressiveWebPopUp', 12)
        } else {
            localStorage.setItem('showProgressiveWebPopUp', Number(showProgressiveWebPopUpCount) + 1)
        }
        props.handleIsProgressiveWebAppPopupVisible(false)
        setIsDialogOpen(false)
    }
    if (!_.isEmpty(manifest_icons)) {
        profilePic = manifest_icons[12].url
    }

    const neverShowPopup = () => {
        document.querySelector('body').style.overflow = 'visible'
        localStorage.setItem('showProgressiveWebPopUp', 'never')
        props.handleIsProgressiveWebAppPopupVisible(false)
        setIsDialogOpen(false)
    }

    return (<>
        {(isDialogOpen || isProgressiveWebAppPopupVisible) && <WebsiteFontColor>
            <PopUpBox
                card_background_color={card_background_color}
                site_font_color={site_font_color}
            >
                <div className='modal show' role='dialog'>
                    <div className='modal-content position-fixed fixed-bottom'>
                        <div className='modal-body mt-0 mb-5' style={{ padding: '10px 40px' }}>
                            <button className='close close-button' onClick={() => closeModel()} />
                            {(!_.isEmpty(profilePic)) && <div className='d-flex justify-content-center my-5 w-100'>
                                <img
                                    src={profilePic}
                                    alt=''
                                    style={{ height: '70px', borderRadius: '10px' }}
                                    draggable={false}
                                />
                            </div>}
                            <h3 className='text-center m-0'>
                                Add <b>{model_name}</b> to the home screen
                            </h3>
                            {message}
                            < hr />
                            <p className='instructions'>
                                <span className='circleNumber'>1</span>
                                <span className='instructions-text'>
                                    Tap on the
                                    {isIOS ?
                                        <>
                                            <span className='highlightIcon'>
                                                <Icons image={getCloudFrontAssetsUrl('images/ios-share.svg')} color={site_font_color}></Icons>
                                            </span>
                                            button below
                                        </>
                                        :
                                        <>
                                            <span className='highlightIcon'>
                                                <Icons image={getCloudFrontAssetsUrl('images/three-dots.svg')} color={site_font_color}></Icons>
                                            </span>
                                            three-dots
                                        </>
                                    }
                                </span>
                            </p>
                            < hr />
                            <p className='instructions'>
                                <span className='circleNumber'>2</span>
                                <span className='instructions-text'>
                                    {isIOS ? 'Then' : 'Then click'}
                                    <span className='highlightText'>{isIOS ? 'Add to Home Screen' : 'Install App'} </span>
                                </span>
                            </p>
                            < hr />
                            {((localStorage.getItem('showProgressiveWebPopUp')) !== 'never' && (progressiveWebAppPopupShowAgain === true)) && <p className='nav-item instructions text-decoration-underline pb-0' style={{ width: 'max-content' }} onClick={neverShowPopup}>
                                Don&apos;t show this again
                            </p>}
                        </div>
                    </div>
                </div >
            </PopUpBox>
        </WebsiteFontColor>}
    </>
    )

}

ProgressiveWebPopUp.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    utility: PropTypes.object.isRequired,
    handleIsProgressiveWebAppPopupVisible: PropTypes.func.isRequired,
    sendMobileInfoToServices: PropTypes.func.isRequired,
    handleProgressiveWebAppPopupShowAgain: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    utility: state.utility
})

export default connect(
    mapStateToProps,
    { handleIsProgressiveWebAppPopupVisible, sendMobileInfoToServices, handleProgressiveWebAppPopupShowAgain }
)(withRouter((ProgressiveWebPopUp)))
