import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from '../WithRouter'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkUserEligibleForOffer, setSubscriptionOffer } from '../../actions/ResubscribeOfferActions'
import _ from 'lodash'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import SweetAlert from 'react-bootstrap-sweetalert'
import { userResubscriptionWithCard, updateUserSubscriptionStatus, updateUserSubscriptionPaymentGateway } from '../../actions/ccbillSubscriptionActions'
import { userResubscription } from '../../actions/hybridPaymentAction'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import WebsiteFontColor from './WebsiteFontColor'
import { closeResubscriptionBanner } from '../../actions/utilityActions'
import { setSweetAlert } from '../../actions/sweetAlertActions'

const TextDiv = styled.div`
    font-size: 24px;
    font-family: ${props => props.fontFamily};
    color: ${props => props.ribbonColor};
`

const AlertDiv = styled.div`
width: 100%;
    h2, p {
        color: ${props => props.color} !important;
    }
    h2 {
        font-size: 1.25rem !important;
        font-weight: 500 !important;
    }
    p {
        margin-top: 0px !important;
        font-size: 15px !important;
    }
    .custom-confirm-btn {
        display: none;
    }
`

function ResubscribeOfferNotificationBar(props) {

    const { content_color, site_font_color, card_background_color, content_font_color, resubscribe_offer_settings, enable_ccbill_rest_api } = props.auth.appSettings
    const [showPopup, setShowPopup] = useState(false)
    const [isPaymentProcess, setIsPaymentProcess] = useState(false)
    const { default_payment_method, wallet_amount } = props.auth.user

    useEffect(() => {
        const { ccbillSubscriptionStatus, isAdmin } = props.auth.user
        if (ccbillSubscriptionStatus !== '2' && isAdmin === false && resubscribe_offer_settings.is_enabled === true && enable_ccbill_rest_api === true && props.resubscriptionOffer.isCheckOffer === false) {
            props.checkUserEligibleForOffer()
        }
    }, [])

    const backgroundColor = _.get(resubscribe_offer_settings, 'ribbon_background_color', content_font_color)
    const fontColor = _.get(resubscribe_offer_settings, 'ribbon_text_color', content_color)
    const fontFamily = _.get(resubscribe_offer_settings, 'ribbon_text_font', 'inherit')
    const ribbonAnimation = _.get(resubscribe_offer_settings, 'ribbon_animation', 'zoomInUp')
    const animationDelay = _.get(resubscribe_offer_settings, 'animation_delay', 'inherit')
    const animationDuration = _.get(resubscribe_offer_settings, 'animation_duration', 'inherit')

    const onConfirm = async () => {
        if (props.resubscriptionOffer.offer.for_subscribers_only === false) {
            props.navigate('/subscribe')
            return
        }
        else {
            setIsPaymentProcess(true)
            const data = {
                offer: props.resubscriptionOffer.offer
            }

            const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
            if (payment_api_version === 'v1') {
                props.userResubscriptionWithCard(data, (res) => {
                    const isSuccess = _.get(res, 'data.accepted', false)
                    setIsPaymentProcess(false)
                    if (isSuccess === true) {
                        props.updateUserSubscriptionStatus()
                        alert('Thank you for subscribing')
                        props.navigate('/')
                        return
                    } else {
                        const error = _.get(res, 'data.error', false)
                        if (error !== false) {
                            alert(error)
                        } else {
                            alert(res)
                        }
                    }
                })
            } else {
                let response
                if (default_payment_method === 'crypto_currency') {
                    if (Number(wallet_amount) === 0) {
                        const payload = {
                            description: 'Please Add Funds in wallet to subscribe.'
                        }
                        props.setSweetAlert(payload)
                        setIsPaymentProcess(false)
                        return
                    }

                    data.payment_for = 'resubscription'
                    response = await props.purchaseFromWallet(data)
                } else {
                    response = await props.userResubscription(data)
                }
                setIsPaymentProcess(false)
                const isSuccess = _.get(response, 'success', 0)
                if (isSuccess === 1) {
                    if (default_payment_method === 'crypto_currency') {
                        props.updateWalletAmount(response.data.wallet_balance)
                        props.updateUserSubscriptionPaymentGateway('forumpay')
                    }
                    props.updateUserSubscriptionStatus()
                    alert('Thank you for subscribing')
                    props.navigate('/')
                    setShowPopup(false)
                    return
                } else {
                    if (default_payment_method === 'crypto_currency') {
                        const error = _.get(response, 'message', false)
                        const errorMessage = error !== false ? error : response
                        const payload = {
                            description: errorMessage
                        }
                        props.setSweetAlert(payload)
                        setShowPopup(false)
                    } else {
                        const error = _.get(response, 'data.error', false)
                        const errorMessage = error !== false ? error : response
                        const payload = {
                            description: errorMessage
                        }
                        props.setSweetAlert(payload)
                    }
                }
            }
        }
    }

    const popupText = useMemo(() => {
        const { offer } = props.resubscriptionOffer
        if (!_.isEmpty(offer.promo_banner_text)) {
            return offer.promo_banner_text
        }

        if (props.resubscriptionOffer.offer.for_subscribers_only === true) {
            if (props.resubscriptionOffer.offer.give_free_month_subscription === 0) {
                return `Resubscribe in just $${props.resubscriptionOffer.offer.recurring_price}/month.`
            } else if (props.resubscriptionOffer.offer.give_free_month_subscription === -1) {
                return 'Resubscribe for free. There will be no subscription charge!'
            } else {
                return `Resubscribe for ${props.resubscriptionOffer.offer.give_free_month_subscription} free month(s), then $${props.resubscriptionOffer.offer.recurring_price}/month.`
            }
        } else {
            if (props.resubscriptionOffer.offer.give_free_month_subscription === 0) {
                return `Subscribe in just $${props.resubscriptionOffer.offer.recurring_price}/month.`
            } else if (props.resubscriptionOffer.offer.give_free_month_subscription === -1) {
                return 'Subscribe for free. There will be no subscription charge!'
            } else {
                return `Subscribe for ${props.resubscriptionOffer.offer.give_free_month_subscription} free month(s), then $${props.resubscriptionOffer.offer.recurring_price}/month.`
            }
        }
    }, [props.resubscriptionOffer])

    const onClickSubscribe = () => {
        if (props.resubscriptionOffer.offer.for_subscribers_only === false) {
            props.navigate('/subscribe')
            return
        }
        setShowPopup(true)
    }

    return (
        <WebsiteFontColor>
            {props.resubscriptionOffer.isUserEligibleForOffer === true && props.utility.isResubscriptionBannerOpen &&
                <Animated animationIn={ribbonAnimation} animationInDelay={animationDelay} animationInDuration={animationDuration} isVisible={true}>
                    <div className='float-right'>
                        <button className='close mr-2 mt-1' style={{ color: '#ffffff', opacity: '1.0', fontSize: '1.3em' }} onClick={() => props.closeResubscriptionBanner(false)}></button>
                    </div>
                    <div className='text-center' style={{ width: '100%', background: backgroundColor, padding: '10px' }}>
                        <TextDiv ribbonColor={fontColor} fontFamily={fontFamily}>
                            {popupText}
                            <button className='btn ml-1' onClick={() => onClickSubscribe(true)} style={{ color: content_font_color, background: content_color }}>
                                {props.resubscriptionOffer.offer.for_subscribers_only === true ? 'Resubscribe' : 'Subscribe'}
                            </button>
                        </TextDiv>
                    </div>
                </Animated>
            }
            {showPopup === true &&
                <AlertDiv
                    backgroundColor={content_color}
                    color={site_font_color}
                    btnFontColor={content_font_color}
                >
                    <SweetAlert
                        title={''}
                        onConfirm={onConfirm}
                        style={{ backgroundColor: card_background_color, color: content_font_color }}
                        confirmBtnCssClass='custom-confirm-btn'
                        cancelBtnCssClass='custom-confirm-btn'
                        btnSize='md'
                        confirmBtnText={isPaymentProcess ? 'Payment process...' : 'Resubscribe'}
                    >
                        <div className='row' style={{ marginTop: '-12px' }}>
                            <div className='col-12 text-right'>
                                <i className='fas fa-times' disabled={isPaymentProcess} onClick={() => setShowPopup(false)} style={{ cursor: 'pointer', color: site_font_color }} ></i>
                            </div>
                        </div>
                        <div className='mb-3' style={{ color: site_font_color }}>{popupText}</div>
                        <button className='btn mb-3' onClick={onConfirm} style={{ backgroundColor: content_color, color: content_font_color }} disabled={isPaymentProcess}>
                            {props.resubscriptionOffer.offer.for_subscribers_only === true ? 'RESUBSCRIBE' : 'SUBSCRIBE'}
                        </button>
                        {props.resubscriptionOffer.offer.for_subscribers_only === true &&
                            <>
                                {default_payment_method === 'crypto_currency' ?
                                    <div className='mt-3'>
                                        <Link className='styled-link' disabled={isPaymentProcess} to={'/profile/wallet'}>Add Funds to wallet</Link>
                                    </div> :
                                    <div className='mt-3'>
                                        <Link className='styled-link' disabled={isPaymentProcess} to={'/profile/payment-method'}>I want to add a new credit card</Link>
                                    </div>
                                }
                            </>
                        }
                    </SweetAlert>
                </AlertDiv>
            }
        </WebsiteFontColor>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    resubscriptionOffer: state.resubscriptionOffer,
    utility: state.utility
})

ResubscribeOfferNotificationBar.propTypes = {
    auth: PropTypes.object.isRequired,
    resubscriptionOffer: PropTypes.object.isRequired,
    checkUserEligibleForOffer: PropTypes.func.isRequired,
    setSubscriptionOffer: PropTypes.func.isRequired,
    userResubscriptionWithCard: PropTypes.func.isRequired,
    updateUserSubscriptionStatus: PropTypes.func.isRequired,
    userResubscription: PropTypes.func.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired,
    closeResubscriptionBanner: PropTypes.func.isRequired,
    updateUserSubscriptionPaymentGateway: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func,
    navigate: PropTypes.func.isRequired
}

export default connect(mapStateToProps,
    {
        checkUserEligibleForOffer,
        setSubscriptionOffer,
        userResubscriptionWithCard,
        updateUserSubscriptionStatus,
        userResubscription,
        purchaseFromWallet,
        updateWalletAmount,
        closeResubscriptionBanner,
        setSweetAlert,
        updateUserSubscriptionPaymentGateway
    }
)(withRouter(ResubscribeOfferNotificationBar))
