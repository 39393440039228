import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { install } from 'ga-gtag'
import { hideNotificationPopup } from '../../actions/utilityActions'
import { createReCaptchaScript } from './../../utils/reCaptcha'


window.isGALoaded = false

class GoogleAnalytics extends Component {
    componentDidMount() {
        createReCaptchaScript()
        this.initializeAnalytics()
    }

    componentDidUpdate(prevProps) {
        // Handle location changes for analytics tracking
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.trackPageView(this.props.location.pathname)
        }
        // Handle notification popup visibility
        this.props.hideNotificationPopup()
    }

    initializeAnalytics() {
        const { google_analytics_4_measurement_id } = this.props.auth.appSettings

        if (this.props.auth.isReady && google_analytics_4_measurement_id && !window.isGALoaded) {
            ReactGA.initialize(google_analytics_4_measurement_id)
            install(google_analytics_4_measurement_id)

            this.trackPageView(this.props.location.pathname)

            window.isGALoaded = true
        }
    }

    trackPageView(pathname) {
        if (pathname.includes('private-chat') || pathname.includes('purchased-contents')) {
            pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1) + 'user_id'
        }
        if (pathname.includes('purchased-by/locked-exclusive-content/')) {
            pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1) + 'blog_id'
        }
        if (pathname.includes('mass-message')) {
            pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1) + 'udid'
        }

        ReactGA.send({ hitType: 'pageview', page: pathname })
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

GoogleAnalytics.propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    hideNotificationPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps, { hideNotificationPopup }
)(withRouter(GoogleAnalytics))

