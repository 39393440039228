import React, { useEffect } from 'react'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getChatBookingTimerData, removeChatTimer } from '../../actions/ChatBookingTimerActions'
import Countdown from 'react-countdown'

function ChatBookingTimer(props) {
    useEffect(() => {
        const { chat_booking_enabled } = props.auth.appSettings
        if (props.auth.isAuthenticated && chat_booking_enabled === true) {
            const isAdmin = props.auth.user.isAdmin
            const data = {
                isAdmin
            }
            props.getChatBookingTimerData(data)
        } else {
            props.removeChatTimer()
        }
    }, [])

    const upcomingChatCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            if (days > 0) {
                return days + ' days'
            }
            if (days === 0 && hours > 0) {
                return hours + ' hours, ' + minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes > 0) {
                return minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
                return seconds + ' seconds'
            }
        }
    }
    const onCompleteTimer = () => {
        const { chat_booking_enabled } = props.auth.appSettings
        if (props.auth.isAuthenticated && chat_booking_enabled === true) {
            const isAdmin = props.auth.user.isAdmin
            const data = {
                isAdmin
            }
            props.getChatBookingTimerData(data)
        }
    }
    return (
        <>
            {props.chatBookingTimer.chatTimeRemaining !== null && props.chatBookingTimer.numberOfLiveChatUser === 0 &&
                <div> Next chat starts in: &nbsp;&nbsp;
                    <Countdown date={props.chatBookingTimer.chatTimeRemaining}
                        onComplete={onCompleteTimer}
                        renderer={upcomingChatCountdownRenderer}
                    />
                </div>
            }
            {props.chatBookingTimer.numberOfLiveChatUser > 0 &&
                <div>Your chat is live.</div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    chatBookingTimer: state.chatBookingTimer
})

ChatBookingTimer.propTypes = {
    chatBookingTimer: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getChatBookingTimerData: PropTypes.func.isRequired,
    removeChatTimer: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { getChatBookingTimerData, removeChatTimer })(withRouter(ChatBookingTimer))
