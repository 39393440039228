import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'

const ModalStyle = styled.div`
width: 100%;

    .modal {
        display: block;
        background-color: #00000080;
        left: ${props => props.leftPosition}px;
        top: ${props => props.topPosition}px;
        width: ${props => props.expandBackground && '100dvw'};
        height: ${props => props.expandBackground && '100dvh'};
    }

    .modal-content {
        color: ${props => props.siteFontColor};
        max-height: ${props => props.modalHeight} !important;
    }

    .modal-header, .modal-body {
        background-color: ${props => props.cardBackgroundColor};
    }

    .modal-body {
        max-height: ${props => props.modalBodyMaxHeight};
    }
    .close {
        color: ${props => props.siteFontColor};
        opacity: 1;
    }

    .close:hover {
        color: ${props => props.siteFontColor};
        opacity: 1;
        cursor: pointer;
    }
    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
        padding: 16px 16px 15px 16px
    }
`

function ModalPopUp(props) {
    const { site_font_color, card_background_color } = props.auth.appSettings
    let defaultModalClass = 'modal-dialog modal-dialog-centered modal-dialog-scrollable'
    let distanceFromLeft = 0
    let distanceFromTop = 0

    if (props.expandBackground) {
        let element = document.getElementById(props.parentModalId)
        distanceFromLeft = element ? -(element.offsetLeft) : 0
        distanceFromTop = element ? -(element.offsetTop) : 0
    }

    const modalClasses = _.get(props, 'classes', '')
    if (modalClasses !== '') {
        defaultModalClass = defaultModalClass + ' ' + modalClasses
    }
    const showCloseButton = _.get(props, 'showCloseBtn', true)
    const showHeader = _.get(props, 'showHeader', true)
    const closePopup = () => {
        // show scrollbar only when there is no parent modal
        if (!props.parentModalId) document.querySelector('body').style.overflow = 'visible'
        props.handleClose()
    }
    const close_btn_font_size = _.get(props, 'closeBtnFontSize', '')
    const popupTitle = _.get(props, 'popup_title', '')
    const isLoading = _.get(props, 'isLoading', false)

    useEffect(() => {
        document.querySelector('body').style.overflow = 'hidden'
        return (() => { if (!props.parentModalId) document.querySelector('body').style.overflow = 'visible' })
    }, [])

    return <ModalStyle siteFontColor={site_font_color}
        cardBackgroundColor={card_background_color}
        leftPosition={distanceFromLeft}
        topPosition={distanceFromTop}
        expandBackground={props.expandBackground}
    >
        <div className='modal fade show' role='dialog'>
            <div className={defaultModalClass} role='document'>
                <div className='modal-content'>
                    {showHeader === true &&
                    <div className='modal-header align-items-center'>
                        {popupTitle !== '' &&
                            <div className='row d-block text-center'>
                                <h3 className='mb-0'>{popupTitle}</h3>
                            </div>
                        }
                        <h5 className='modal-title'>{props.title}</h5>
                        {showCloseButton === true && <button className='close' disabled={isLoading} style={{ fontSize: close_btn_font_size ? close_btn_font_size : 'xx-large' }} onClick={closePopup} />}
                    </div>
                    }
                    <div className='modal-body' ref={props.modalRef}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </ModalStyle>
}

ModalPopUp.propTypes = {
    auth: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
    children: PropTypes.object,
    classes: PropTypes.string,
    modalBodyMaxHeight: PropTypes.string,
    showCloseBtn: PropTypes.bool,
    closeBtnFontSize: PropTypes.string,
    isLoading: PropTypes.bool,
    // Use this when background of modal is not able to get full screen width and height because of it's parent modal
    expandBackground: PropTypes.bool,
    // Id of parent modal element having className modal-dialog
    parentModalId: PropTypes.string    // Can be only used when expandBackground is true
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(ModalPopUp))
