import axios from 'axios'
import { getAppBaseUrl } from './api'
const BASE_URL = getAppBaseUrl()

export const saveAnnouncementBannerSettings = (data) => async () => {
    const url = BASE_URL + '/api/save-announcement-banner-settings'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export const getAnnouncementSettings = () => async () => {
    const url = BASE_URL + '/api/get-announcement-banner-settings'
    try {
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        return error.message
    }
}
