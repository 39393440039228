import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {
    IS_LOADING,
    CHAT_USER_LIST,
    SET_MESSAGES,
    CHAT_USER_PROFILE_INFO,
    SET_UNREAD_COUNT,
    TIPS_LIST,
    IS_MORE_MESSAGE_LOADING,
    LOAD_MORE_MESSAGE,
    SET_CHAT_SELECTED_ID,
    SET_CHAT_MODEL_ID,
    ADD_NEW_MESSAGE_IN_END,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    TOGGLE_CHAT_TIP_POPUP,
    UPDATE_USER_AMOUNT_SPENT,
    UPDATE_USER_LAST_SEEN,
    UPDATE_USER_NICKNAME
} from './types'
import { getAppBaseUrl } from './api'
import store from './../store'
import { paymentResponseRecieved } from './ccbillActions'
import { removePayPerMessageCredit } from './authActions'
import { updateWalletAmount } from './cryptoPaymentActions'
import _ from 'lodash'
import { setSweetAlert, removeConfirmSweetAlert } from './sweetAlertActions'
import { googleAnalyticsTrackEvent } from '../utils/GoogleAnalyticsEvent'

const BASE_URL = getAppBaseUrl()

// Get User List
export const getUserList = (data) => dispatch => {
    dispatch(setLoading())
    axios.get(`${BASE_URL}/api/chat/get_user_list?page_num=${data.pageNum}&sort_by=${data.sortBy}&user_name=${data.userName}&sort_by_subscribers=${data.sortBySubscribers}&list_id=${data.list_id}`)
        .then(res => {
            dispatch(setUserList(res.data))
        })
}

// Get Messages
export const getUserMessages = (data, isAdmin, callback) => dispatch => {
    dispatch(setLoading())
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = dispatch(getAdminValue(token))
    }
    dispatch({ type: SET_CHAT_SELECTED_ID, payload: data.selectedUserId })
    const url = (isAdmin === true) ? BASE_URL + `/api/chat/get_messages_admin?selected_model_id=${data.selectedModelId}&selected_user_id=${data.selectedUserId}&page_num=${data.pageNum}` : BASE_URL + `/api/chat/get_messages?selected_user_id=${data.selectedUserId}&page_num=${data.pageNum}`

    axios.get(url)
        .then(res => {
            const shouldHideInChat = _.get(res, 'data.should_hide_in_chat', false)
            if (shouldHideInChat === true) {
                callback(false)
                return
            }
            dispatch(setMessages(res.data))
            let readMessageData = {
                userId: data.selectedUserId
            }

            if (isAdmin === true) {
                readMessageData.modelId = data.selectedModelId
            }

            if (data.role !== 'admin') {
                dispatch(readMessage(readMessageData, isAdmin))
            }
            if (callback) {
                callback(true)
            }
        })
}

// Load more Messages
export const loadMore = (data, isAdmin, callback) => dispatch => {
    dispatch(setIsMoreMessagesLoading())
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = dispatch(getAdminValue(token))
    }

    const url = (isAdmin === true) ? BASE_URL + `/api/chat/get_messages_admin?selected_user_id=${data.userId}&selected_model_id=${data.modelId}&page_num=${data.pageNum}` : BASE_URL + `/api/chat/get_messages?selected_user_id=${data.userId}&page_num=${data.pageNum}`

    axios.get(url)
        .then(res => {
            dispatch(setMoreMessages(res.data))
            callback()
        })
}

// Get isAdmin value from token
const getAdminValue = (token) => () => {
    const decoded = jwt_decode(token)

    return decoded.isAdmin
}

// Send Message to all users
export const sendMessageToAll = (data, paginationData) => dispatch => {
    axios.post(BASE_URL + '/api/chat/send_message_to_all', data)
        .then(() => {
            dispatch(getUserList(paginationData))
            return alert('Message Sent to all users')
        })
}

// Read message
export const readMessage = (data, isAdmin) => dispatch => {
    const url = (isAdmin === true) ? BASE_URL + '/api/chat/read_message_model' : BASE_URL + '/api/chat/read_message_user'

    axios.post(url, data)
        .then(() => {
            dispatch(refreshMessageCount())
        })
}

export const refreshMessageCount = () => dispatch => {
    axios.get(BASE_URL + '/api/users/get_unread_count')
        .then(res => {
            dispatch(setUnreadCount(res.data))
        })
}

window.skipLookup = {}

export const chatUserProfileInfoAction = (userId) => (dispatch) => {
    if (userId !== undefined) {
        if (window.skipLookup[userId] !== true) {
            window.skipLookup[userId] = true
            axios.get(BASE_URL + `/api/chat/get_user_details?user_id=${userId}`)
                .then(res => {
                    dispatch(chatUserProfileInfo(res.data))
                })
        }
    }
}

export const updateUserChatNickname = (data) => dispatch => {
    if (data.userId !== undefined) {
        dispatch(updateUsersNickname(data))
    }
}


// Get booked slot data.
export const getBookedSlotData = (data, callback) => () => {
    if (data.userId !== undefined) {
        axios.get(BASE_URL + `/api/chat/get_user_booked_slot_data?user_id=${data.userId}&timezone=${data.timezone}`)
            .then(res => {
                callback(res.data)
            })
    }
}

// Get booked slot start time.
export const getNextMeetingTime = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/get_next_meeting_time', data)
        .then(res => {
            callback(res.data)
        })
}
// send message

export const sendTextMessage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/chat/send_message', data)
        .then(res => {
            if (res.data.wallet_balance !== false) {
                dispatch(updateWalletAmount(res.data.wallet_balance))
            }
            dispatch(removePayPerMessageCredit())
            dispatch(addNewMessageInEnd(res.data))
            callback(true)
            if (res.data.fromAdmin === false && res.data.is_pay_per_message === true) {
                const transaction_id = res.data.transactionId + '-' + res.data.senderId
                googleAnalyticsTrackEvent('purchase', transaction_id, res.data.amount, 'chat', 'pay per message', '')
            }
        }).catch(error => {
            error.message = _.get(error, 'response.data.message', error.message)
            const allow_cascade = _.get(error.response.data.errors, 'cascade.allow_cascade', false)
            if (allow_cascade === false) {
                dispatch(setSweetAlert({ description: `Could not send Message: ${error.message}` }))
                return callback(true)
            }
            dispatch(setSweetAlert({ description: 'Could not send Message: Problem in card authorization please re-enter your card details or add new card.' }))
            callback(false)
        })
}

// Get all tips messages
export const getAllTipsMessages = (data) => dispatch => {
    dispatch(setLoading())
    axios.post(BASE_URL + '/api/chat/get_tips_list', data)
        .then(res => {
            dispatch(setTipsList(res.data))
        })
}

export const setAllowUserToChat = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/update_allow_user_to_chat', data)
        .then(() => {
            callback()
        })
}

export const getUserSendMessageStatus = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/get_user_send_message_status', data)
        .then(res => {
            callback(res)
        })
}

// Get single message to update
export const getSingleMessage = (data) => dispatch => {
    const messageId = _.get(data, 'messageId', false)
    if (messageId === false) {
        return dispatch(paymentResponseRecieved())
    }

    axios.get(BASE_URL + `/api/chat/get_chat_message?id=${data.messageId}`)
        .then(res => {
            dispatch(updateMessageAction(res.data.data))
            dispatch(paymentResponseRecieved())
        })
}

export const sendMassMessage = (data) => async () => {
    try {
        await axios.post(BASE_URL + '/api/message/send-mass-message', data)
        return true
    } catch (error) {
        error.message = _.get(error, 'response.data.message', error.message)
        alert(`Could not send Message: ${error.message}`)
        return false
    }
}

export const setWelcomeMessage = (data) => async () => {
    try {
        await axios.post(BASE_URL + '/api/message/set-welcome-message', data)
        return true
    } catch (error) {
        error.message = _.get(error, 'response.data.message', error.message)
        alert(`Could not set Message: ${error.message}`)
        return false
    }
}

export const sendMessage = (data) => async () => {
    try {
        const res = await axios.post(BASE_URL + '/api/message/send-message', data)
        return res.data.data
    } catch (error) {
        const res = { isError: true, error }
        return res
    }
}

export const getErrorMessage = () => async () => {
    try {
        const res = await axios.get(BASE_URL + '/admin/get-error-mass-messages')
        return res.data.data
    } catch (error) {
        const res = { isError: true, error }
        return res
    }
}

export const setLoading = () => {
    return {
        type: IS_LOADING
    }
}

export const setIsMoreMessagesLoading = () => {
    return {
        type: IS_MORE_MESSAGE_LOADING
    }
}

export const setUserList = (data) => {
    return {
        type: CHAT_USER_LIST,
        payload: data
    }
}

export const setMessages = (data) => {
    return {
        type: SET_MESSAGES,
        payload: data
    }
}

export const setMoreMessages = (data) => {
    return {
        type: LOAD_MORE_MESSAGE,
        payload: data
    }
}

export const setTipsList = (data) => {
    return {
        type: TIPS_LIST,
        payload: data
    }
}

export const chatUserProfileInfo = (data) => {
    return {
        type: CHAT_USER_PROFILE_INFO,
        payload: data
    }
}

export const updateUsersNickname = (data) => {
    return {
        type: UPDATE_USER_NICKNAME,
        payload: data
    }
}

export const setUnreadCount = (data) => {
    return {
        type: SET_UNREAD_COUNT,
        payload: data
    }
}

export const setSelectedChatId = (id, navigate) => dispatch => {
    const state = store.getState()

    if (state.auth.isAdmin === true) {
        dispatch(setSelectedChatModelId(state.auth.appSettings.model_id))
        dispatch(setChatId(id))
        setTimeout(() => {
            navigate(`/private-chat/${id}`)
        }, 0)
    } else {
        dispatch(setChatId(id))
        setTimeout(() => {
            navigate('/private-chat')
        }, 0)
    }
}

export const setChatId = (id) => {
    return {
        type: SET_CHAT_SELECTED_ID,
        payload: id
    }
}

export const setSelectedChatModelId = (id) => {
    return {
        type: SET_CHAT_MODEL_ID,
        payload: id
    }
}

export const addNewMessageInEnd = (data) => {
    return {
        type: ADD_NEW_MESSAGE_IN_END,
        payload: data
    }
}

export const updateMessageAction = (data) => {
    return {
        type: UPDATE_MESSAGE,
        payload: data
    }
}

export const deleteMessageAction = data => {
    return {
        type: DELETE_MESSAGE,
        payload: data
    }
}

export const toggleChatTipPopup = data => {
    return {
        type: TOGGLE_CHAT_TIP_POPUP,
        payload: data
    }
}

// Block/Unblock User in v2
export const blockUserAndCancelUserSubscription = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/v2/admin/block-user', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
        }).catch(error => {
            const message = _.get(error, 'response.data.message', 'There was a problem in Block user.')
            dispatch(removeConfirmSweetAlert())
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const updateUserLastSeen = data => {
    return {
        type: UPDATE_USER_LAST_SEEN,
        payload: data
    }
}

export const updateUserAmountSpent = data => {
    return {
        type: UPDATE_USER_AMOUNT_SPENT,
        payload: data
    }
}
