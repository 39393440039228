import React, { useMemo, useState, useEffect } from 'react'
import { withRouter } from '../WithRouter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap-tabs'
import styled from 'styled-components'
import Countdown from 'react-countdown'
import { savePromotionOffer, getPromotionOffer, endPromotionOffer } from '../../actions/PromotionActions'
import { timezoneList } from '../chat-new/TimezoneListOption'
import SelectListGroup from '../Layouts/SelectListGroup'

const MainPromotionDivision = styled.div`
    width: 100%;
        .modal-dialog {
            max-width: 500px;
        }

        @media(max-width: 575px) {
            .modal-dialog{
                max-width: 320px
                margin: auto;
            }
        }

        @media(max-width: 375px) {
            .modal-dialog{
                max-width: 310px
                margin: auto;
                margin-top: 5px;
            }
        }

`
const PromotionDiv = styled.div`
    width: 100%;
        .nav-item a.nav-link {
            display: block;
            width: -webkit-fill-available;
        }
        .tab-content {
            margin-top: 12px;
        }
        ul.nav.nav-tabs{
            margin: 0px -16px;
            border: none;
        }
        li.nav-item {
            padding: 0px;
        }
        a.nav-link.active {
            background-color: ${props => props.cardColor};
            border: 0px;
            color: ${props => props.siteFontColor} !important;
        }
        a.nav-link {
            background-color: ${props => props.contentColor};
            color: ${props => props.contentFontColor} !important;
            border: 0px;
            font-weight: bold;
        }
        button {
            background-color: ${props => props.contentColor} ;
            color: ${props => props.contentFontColor};
        }
        button:hover{
            color: ${props => props.contentFontColor};
        }
        .options-div {
            margin: 6px 0px;
        }
        .save-btn, .close-btn {
            width: 120px;
        }
`

function PromotionModal(props) {

    const { site_font_color, card_background_color, content_color, content_font_color, membership_price, recurring_membership_price } = props.auth.appSettings
    const { isSuperAdmin, isSubAdmin } = props.auth

    let [selectedOffer, setSelectedOffer] = useState(0)
    let [selectedLockedContentOffer, setSelectedLockedContentOffer] = useState(0)
    let [applicableTo, setApplicableTo] = useState(0)
    let [expireDate, setExpireDate] = useState(1)
    let [ribbonText, setRibbonText] = useState('free first month')
    let [isPromotionExist, setIsPromotionExist] = useState(false)
    let [existPromotionExpireDate, setExistPromotionExpireDate] = useState(false)
    let [existPromotionId, setExistPromotionId] = useState('')
    let [ribbonTextFieldValue, setRibbonTextFieldValue] = useState('New Subscriber Promotion')
    let [selectedTab, setSelectedTab] = useState('')
    let [offerText, setOfferText] = useState('New Subscriber Promotion')
    let [selectedDiscount, setSelectedDiscount] = useState(50)
    let [isLoading, setIsLoading] = useState(true)
    let [timezone, setTimezone] = useState('UTC')
    let [isRepeatPromotion, setIsRepeatPromotion] = useState(false)
    const [excludePriceText, setExcludePriceText] = useState(false)
    const [excludePercentageText, setExcludePercentageText] = useState(false)
    const [isTrialSubscriptionOffer, setTrialSubscriptionOffer] = useState(false)
    const [trialSubscriptionInitialPeriod, setTrialSubscriptionInitialPeriod] = useState(30)
    const [isLifeTimeFreeOffer, setLifeTimeFreeOffer] = useState(false)
    const [isPromotionOfferSaving, setIsPromotionOfferSaving] = useState(false)

    useEffect(() => {
        if (selectedTab !== '') {
            setIsLoading(true)
            const data = {
                promotion_type: selectedTab
            }
            props.getPromotionOffer(data, (res) => {
                if (res.data !== null) {
                    setIsPromotionExist(true)
                    setExistPromotionExpireDate(res.data.date_expire)
                    setRibbonText(res.data.ribbon_text)
                    setExistPromotionId(res.data._id)
                    setIsRepeatPromotion(res.data.is_repeat_promotion)
                } else {
                    setIsPromotionExist(false)
                    setExistPromotionId('')
                    if (selectedTab === 'SUBSCRIPTION') {
                        const selectedPromo = promotionOfferOptions[selectedOffer]
                        setRibbonText(selectedPromo.text)
                        setOfferText('New Subscriber Promotion')
                        setRibbonTextFieldValue('New Subscriber Promotion')
                    } else {
                        setSelectedLockedContentOffer(0)
                        setOfferText('Exclusive Content and Mass Message Promotion')
                        setRibbonTextFieldValue('Exclusive Content and Mass Message Promotion')
                    }
                }
                setIsLoading(false)
            })
        }
    }, [selectedTab])

    const showSubscriptionTab = useMemo(() => {
        if (membership_price === '0.00' && recurring_membership_price === '0.00') {
            return false
        }
        return true
    }, [membership_price, recurring_membership_price])

    useEffect(() => {
        if (showSubscriptionTab === false) {
            setSelectedTab('LOCKED_CONTENT')
        } else {
            setSelectedTab('SUBSCRIPTION')
        }
    }, [])

    const promotionOfferOptions = useMemo(() => {
        const options = [{
            label: `free for initial trial period, $${recurring_membership_price} recurring price`,
            price: 0,
            recurring_price: recurring_membership_price,
            value: 0,
            text: 'free for initial trial period'
        }]
        let priceOptions = []
        for (let startPrice = (membership_price - 1); startPrice > 1; startPrice--) {
            priceOptions.push(startPrice)
            priceOptions.reverse()
        }
        priceOptions.sort(function (a, b) { return a - b })
        priceOptions.forEach((price, index) => {
            const priceAmount = parseFloat(price).toFixed(2)
            const option = {
                label: `$${priceAmount} for initial trial period, $${recurring_membership_price} recurring price`,
                price: priceAmount,
                recurring_price: recurring_membership_price,
                value: index + 1,
                text: `$${priceAmount} for initial trial period`
            }
            options.push(option)
        })
        options.push(
            {
                label: 'free for life',
                price: 0,
                recurring_price: 0,
                value: priceOptions.length + 1,
                text: 'free for life'
            }
        )
        return options
    }, [])

    const PromotionOfferDateOption = useMemo(() => {
        const options = [{
            value: 1,
            label: '1 hour'
        }]
        for (let hour = 2; hour <= 24; hour++) {
            options.push({
                value: hour,
                label: `${hour} hours`
            })
        }
        for (let day = 2; day <= 30; day++) {
            options.push({
                value: day * 24,
                label: `${day} days`
            })
        }
        options.push({
            value: 'END_OF_DAY',
            label: 'End of day'
        })
        options.push({
            value: 'END_OF_MONTH',
            label: 'End of month'
        })
        return options
    }, [])

    const TrialSubscriptionOfferInitialPeriodOption = useMemo(() => {
        const options = [{
            value: 30,
            label: '1 Month'
        }]
        for (let day = 1; day < 30; day++) {
            options.push({
                value: day,
                label: `${day} day${day > 1 ? 's' : ''}`
            })
        }
        return options
    }, [])

    const discountOptions = useMemo(() => {
        const options = []
        for (let percentage = 10; percentage <= 80; percentage++) {
            options.push({
                value: percentage,
                label: `${percentage}% off`
            })
        }
        return options
    }, [])

    const setPromotionExpireDate = (e) => {
        setExpireDate(e.target.value)
    }

    const setTrialOfferInitialPeriod = (e) => {
        setTrialSubscriptionOffer(false)
        setApplicableTo(0)
        if (parseInt(e.target.value) < 30) {
            setTrialSubscriptionOffer(true)
            setApplicableTo(1)
        }
        setTrialSubscriptionInitialPeriod(parseInt(e.target.value))
    }

    const finalRibbonText = useMemo(() => {
        let text = ribbonTextFieldValue
        if (excludePriceText === false) {
            text += ' - ' + ribbonText
        }

        const replaceText = `${trialSubscriptionInitialPeriod === 30 ? 'first month' : `${trialSubscriptionInitialPeriod} Day${trialSubscriptionInitialPeriod > 1 ? 's' : ''}`}`
        text = text.replace('initial trial period', replaceText)

        return text
    }, [ribbonTextFieldValue, ribbonText, excludePriceText, trialSubscriptionInitialPeriod])

    const ribbonTextForMessagePromotion = useMemo(() => {
        let text = ribbonTextFieldValue
        if (excludePercentageText === false) {
            text += ' - ' + selectedDiscount + '% off'
        }
        return text
    }, [ribbonTextFieldValue, excludePercentageText, selectedDiscount])

    const saveSubscriptionPromotion = () => {
        const data = {
            date_expire: expireDate,
            type: selectedTab,
            is_repeat_promotion: isRepeatPromotion,
            is_trial_offer: trialSubscriptionInitialPeriod === 30 ? false : true,
            initial_period: trialSubscriptionInitialPeriod
        }
        if (expireDate === 'END_OF_MONTH' || expireDate === 'END_OF_DAY') {
            data.timezone = timezone
            data.is_repeat_promotion = false
        }
        if (selectedTab === 'SUBSCRIPTION') {
            data.ribbon_text = finalRibbonText
            const promotionPriceData = promotionOfferOptions.filter(offer => offer.value === selectedOffer)
            data.subscription_initial_amount = promotionPriceData[0].price
            data.subscription_recurring_amount = promotionPriceData[0].recurring_price

            if (applicableTo === 0) {
                data.applicable_to = 'ALL_USERS'
            } else if (applicableTo === 1) {
                data.applicable_to = 'NEW_USERS'
            } else {
                data.applicable_to = 'OLD_USERS'
            }
        } else {
            if (selectedLockedContentOffer === 0) {
                data.applicable_to = 'EXCLUSIVE_CONTENT_AND_MASS_MESSAGE'
            } else if (selectedLockedContentOffer === 1) {
                data.applicable_to = 'EXCLUSIVE_CONTENT'
            } else {
                data.applicable_to = 'MASS_MESSAGE'
            }
            data.discount_percentage = selectedDiscount
            data.ribbon_text = ribbonTextForMessagePromotion
        }
        setIsPromotionOfferSaving(true)
        props.savePromotionOffer(data, () => {
            setIsPromotionOfferSaving(false)
            props.handlePromotionModelClose()
            props.setPromotionButtonTextAfterSave()
        })
    }

    const endPromotion = () => {
        let confirmationText = 'Are you sure that you want to end the New Subscriber Promotion?'
        if (selectedTab !== 'SUBSCRIPTION') {
            confirmationText = 'Are you sure that you want to end the Locked Content Promotion?'
        }
        if (window.confirm(confirmationText)) {
            const data = {
                id: existPromotionId
            }
            setIsPromotionOfferSaving(true)
            props.endPromotionOffer(data, () => {
                setIsPromotionOfferSaving(false)
                props.handlePromotionModelClose()
                props.setPromotionButtonTextAfterSave()
            })
        }
    }

    const upcomingChatCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            if (days > 0) {
                return days + ' Days, ' + hours + ' hours, ' + minutes + ' minutes'
            }
            if (days === 0 && hours > 0) {
                if (hours === 1) {
                    return hours + ' hour, ' + minutes + ' minutes'
                } else {
                    return hours + ' hours, ' + minutes + ' minutes'
                }
            }
            if (days === 0 && hours === 0 && minutes > 0) {
                return minutes + ' minutes, ' + seconds + ' seconds'
            }
            if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
                return seconds + ' seconds'
            }
        }
    }

    const onChangeOffer = (offerIndex) => {
        setSelectedOffer(offerIndex)
        const selectedPromo = promotionOfferOptions[offerIndex]
        setRibbonText(selectedPromo.text)

        const is_free_for_life_offer = (selectedPromo.price === 0 && selectedPromo.recurring_price === 0)
        setLifeTimeFreeOffer(is_free_for_life_offer)
        if (is_free_for_life_offer === true) {
            setTrialSubscriptionInitialPeriod(30)
        }
    }

    const onChangeLockedContentOffer = (offerIndex) => {
        setSelectedLockedContentOffer(offerIndex)
        setPromotionMessage(offerIndex)
    }

    const onChangeDiscountPercentage = (e) => {
        setSelectedDiscount(e.target.value)
    }

    const onChangeTab = (index) => {
        if (showSubscriptionTab === false) {
            setSelectedLockedContentOffer(0)
            setSelectedTab('LOCKED_CONTENT')
            setSelectedDiscount(50)
            setOfferText('Exclusive Content and Mass Message Promotion')
            setRibbonTextFieldValue('Exclusive Content and Mass Message Promotion')
            return
        }
        if (index === 0) {
            setSelectedTab('SUBSCRIPTION')
            const selectedPromo = promotionOfferOptions[selectedOffer]
            setRibbonText(selectedPromo.text)
            setOfferText('New Subscriber Promotion')
            setRibbonTextFieldValue('New Subscriber Promotion')
        } else {
            setSelectedLockedContentOffer(0)
            setSelectedTab('LOCKED_CONTENT')
            setSelectedDiscount(50)
            setOfferText('Exclusive Content and Mass Message Promotion')
            setRibbonTextFieldValue('Exclusive Content and Mass Message Promotion')
        }
    }

    const setPromotionMessage = (offer) => {
        if (offer === 0) {
            setRibbonTextFieldValue('Exclusive Content and Mass Message Promotion')
            setOfferText('Exclusive Content and Mass Message Promotion')
        } else if (offer === 1) {
            setRibbonTextFieldValue('Exclusive Content Promotion')
            setOfferText('Exclusive Content Promotion')
        } else {
            setRibbonTextFieldValue('Mass Message Promotion')
            setOfferText('Mass Message Promotion')
        }
    }

    return (
        <MainPromotionDivision>
            <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                    <div className='modal-content' style={{ color: site_font_color, borderRadius: '5px', background: 'transparent' }}>
                        <div className='modal-body pt-0' style={{ backgroundColor: card_background_color }}>
                            <PromotionDiv contentColor={content_color}
                                contentFontColor={content_font_color}
                                cardColor={card_background_color}
                                siteFontColor={site_font_color}>
                                {showSubscriptionTab === true ?
                                    <Tabs
                                        className='nav-pills nav-fill'
                                        onSelect={(index) => onChangeTab(index)}
                                        disabled={isPromotionOfferSaving}
                                    >
                                        <Tab label='NEW SUBSCRIBERS'>
                                            {isLoading === true ? <div className='text-center'>
                                                <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                                                    <span className='sr-only'>Loading...</span>
                                                </div>
                                            </div> :
                                                <div className='row'>
                                                    {isPromotionExist === false ?
                                                        <>
                                                            <div className='col-12 mb-3'>
                                                                <h4 className='text-center mt-3 mb-4'>NEW SUBSCRIBER PROMOTION</h4>
                                                                {promotionOfferOptions.map((option, index) => {
                                                                    return <div className='options-div' key={'offer_' + index}>
                                                                        <div className='form-check'>
                                                                            <input className='form-check-input' type='radio' name='radio' id={'radio' + index} onChange={() => onChangeOffer(index)} checked={index === selectedOffer ? true : false} />
                                                                            <label className='form-check-label' htmlFor={'radio' + index}>
                                                                                {option.label}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                                {((isSuperAdmin === true || isSubAdmin === true)) &&
                                                                    <div className='options-div mt-4'>
                                                                        <div className='form-check'>
                                                                            <input className='form-check-input' type='checkbox' id='checkbox-1' defaultChecked={excludePriceText} onChange={() => setExcludePriceText(!excludePriceText)} />
                                                                            <label className='form-check-label' htmlFor='checkbox-1'>
                                                                                Exclude &quot;{ribbonText}&quot; from promotion message
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {isLifeTimeFreeOffer === false &&
                                                                    <>
                                                                        <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                        <label>Initial Trial Period</label>
                                                                        <br />
                                                                        <select className='custom-select py-1 form-control w-auto' onChange={setTrialOfferInitialPeriod}>
                                                                            {TrialSubscriptionOfferInitialPeriodOption.map((date, index) => {
                                                                                return <option key={index} value={date.value}>{date.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </>}
                                                                <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                <label>How long should the promotion be active?</label>
                                                                <br />
                                                                <select className='custom-select py-1 form-select w-auto' aria-label='Default select example' onChange={setPromotionExpireDate}>
                                                                    {PromotionOfferDateOption.map((date, index) => {
                                                                        return <option key={index} value={date.value}>{date.label}</option>
                                                                    })}
                                                                </select>
                                                                {(expireDate === 'END_OF_DAY' || expireDate === 'END_OF_MONTH') &&
                                                                    <div className='form-row mt-2'>
                                                                        <div className='col-12 col-md-12 col-lg-12'>
                                                                            <SelectListGroup
                                                                                id='max-s'
                                                                                label='Timezone'
                                                                                name='website_timezone'
                                                                                onChange={(e) => setTimezone(e.target.value)}
                                                                                options={timezoneList}
                                                                                value={timezone}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(expireDate !== 'END_OF_DAY' && expireDate !== 'END_OF_MONTH') &&
                                                                    <div className='form-check mt-4'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='form-check-input'
                                                                            name='repeat-promotion'
                                                                            id={'repeat-promotion'}
                                                                            onChange={(e) => setIsRepeatPromotion(e.target.checked)}
                                                                        />
                                                                        <label className='form-check-label mt-1' htmlFor={'repeat-promotion'}>
                                                                            Repeat the promotion once time expires
                                                                        </label>
                                                                    </div>
                                                                }
                                                                <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                <div className='options-div'>
                                                                    <div className='form-check'>
                                                                        <input className='form-check-input' type='radio' name='radio3' id='offer_radio1' onChange={() => setApplicableTo(0)} checked={applicableTo === 0} disabled={isTrialSubscriptionOffer} />
                                                                        <label className='form-check-label' htmlFor='offer_radio1'>
                                                                            for both new subscriber and old subscriber
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='options-div'>
                                                                    <div className='form-check'>
                                                                        <input className='form-check-input' type='radio' name='radio3' id='offer_radio2' onChange={() => setApplicableTo(1)} checked={applicableTo === 1} disabled={isTrialSubscriptionOffer} />
                                                                        <label className='form-check-label' htmlFor='offer_radio2'>
                                                                            for only new subscriber
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='options-div'>
                                                                    <div className='form-check'>
                                                                        <input className='form-check-input' type='radio' name='radio3' id='offer_radio3' onChange={() => setApplicableTo(2)} checked={applicableTo === 2} disabled={isTrialSubscriptionOffer} />
                                                                        <label className='form-check-label' htmlFor='offer_radio3'>
                                                                            for only old subscriber
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                <h6 className='text-center mt-1'>Message on Landing Page</h6>
                                                                <label>{finalRibbonText}</label>
                                                                <div className='input-group'>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control'
                                                                        placeholder='New Subscriber Promotion'
                                                                        aria-label='Coupon Code'
                                                                        aria-describedby='button-addon2'
                                                                        onChange={(e) => setOfferText(e.target.value)}
                                                                        value={offerText}
                                                                    />
                                                                    <button className='btn my-0' style={{ padding: '0.295rem 0.75rem' }} type='button' id='button-addon2' onClick={() => setRibbonTextFieldValue(offerText)}>Apply</button>
                                                                </div>
                                                                <small id="couponcode" className='form-text text-muted mt-0'>Change promotion message.</small>
                                                            </div>
                                                            <div className='col-6 text-center text-md-right'>
                                                                <button
                                                                    className='btn save-btn'
                                                                    onClick={saveSubscriptionPromotion}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                            <div className='col-6 text-center text-md-left'>
                                                                <button
                                                                    className='btn close-btn'
                                                                    onClick={props.handlePromotionModelClose}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='col-12 text-center'>
                                                            <h4 className='text-center mt-1'>New Subscriber Promotion is Currently active{isRepeatPromotion ? ' - Repeated' : ''}</h4>
                                                            <p>{ribbonText}</p>
                                                            <p> Ends in &nbsp;
                                                                {existPromotionExpireDate !== false &&
                                                                    <Countdown date={existPromotionExpireDate}
                                                                        onComplete={props.handlePromotionModelClose}
                                                                        renderer={upcomingChatCountdownRenderer}
                                                                    />
                                                                }
                                                            </p>
                                                            <button
                                                                className='btn'
                                                                type='button'
                                                                id='button-addon2'
                                                                onClick={endPromotion}
                                                                disabled={isPromotionOfferSaving}
                                                            >
                                                                END PROMOTION
                                                            </button>
                                                            <button
                                                                className='btn close-btn'
                                                                onClick={props.handlePromotionModelClose}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Tab>
                                        <Tab label='LOCKED CONTENT' disabled={isPromotionOfferSaving}>
                                            {isLoading === true ? <div className='text-center'>
                                                <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                                                    <span className='sr-only'>Loading...</span>
                                                </div>
                                            </div> :
                                                <div className='row'>
                                                    {isPromotionExist === false ?
                                                        <>
                                                            <div className='col-12 mb-3'>
                                                                <h4 className='text-center mt-3 mb-4'>LOCKED CONTENT PROMOTION</h4>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio11' onChange={() => onChangeLockedContentOffer(0)} checked={selectedLockedContentOffer === 0} />
                                                                    <label className='form-check-label' htmlFor='radio11'>
                                                                        both exclusive content and mass messages
                                                                    </label>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio21' onChange={() => onChangeLockedContentOffer(1)} checked={selectedLockedContentOffer === 1} />
                                                                    <label className='form-check-label' htmlFor='radio21'>
                                                                        exclusive content only
                                                                    </label>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio31' onChange={() => onChangeLockedContentOffer(2)} checked={selectedLockedContentOffer === 2} />
                                                                    <label className='form-check-label' htmlFor='radio31'>
                                                                        mass messages only
                                                                    </label>
                                                                </div>
                                                                {(isSuperAdmin === true || isSubAdmin === true) &&
                                                                    <div className='mt-4'>
                                                                        <div className='form-check'>
                                                                            <input className='form-check-input' type='checkbox' id='checkbox-1' defaultChecked={excludePercentageText} onChange={() => setExcludePercentageText(!excludePercentageText)} />
                                                                            <label className='form-check-label' htmlFor='checkbox-1'>
                                                                                Exclude &quot;{selectedDiscount + '% off'}&quot; from promotion message
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <br />
                                                                {selectedTab === 'LOCKED_CONTENT' &&
                                                                    <select className='custom-select py-1 form-select w-auto' aria-label='Default select example' onChange={onChangeDiscountPercentage}>
                                                                        {discountOptions.map((percentage, index) => {
                                                                            return <option key={index} value={percentage.value} selected={selectedDiscount === percentage.value}>{percentage.label}</option>
                                                                        })
                                                                        }
                                                                    </select>
                                                                }
                                                                <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                <label>How long do you need to set the promotion?</label>
                                                                <br />
                                                                <select className='custom-select py-1 form-select w-auto' aria-label='Default select example' onChange={setPromotionExpireDate}>
                                                                    {PromotionOfferDateOption.map((date, index) => {
                                                                        return <option key={index} value={date.value}>{date.label}</option>
                                                                    })
                                                                    }
                                                                </select>
                                                                {(expireDate === 'END_OF_DAY' || expireDate === 'END_OF_MONTH') &&
                                                                    <div className='form-row mt-2'>
                                                                        <div className='col-12 col-md-12 col-lg-12'>
                                                                            <SelectListGroup
                                                                                id='max-s'
                                                                                label='Timezone'
                                                                                name='website_timezone'
                                                                                onChange={(e) => setTimezone(e.target.value)}
                                                                                options={timezoneList}
                                                                                value={timezone}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <hr className='mx-9 my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px' }}></hr>
                                                                <label>{ribbonTextForMessagePromotion}</label>
                                                                <div className='input-group'>
                                                                    <input type='text' className='form-control' value={offerText} aria-label='Coupon Code' aria-describedby='button-addon2' onChange={(e) => setOfferText(e.target.value)} />
                                                                    <button className='btn my-0' style={{ padding: '0.295rem 0.75rem' }} type='button' id='button-addon2' onClick={() => setRibbonTextFieldValue(offerText)} >Apply</button>
                                                                </div>
                                                                <small id="couponcode" className='form-text text-muted mt-0'>Change promotion message.</small>
                                                            </div>
                                                            <div className='col-6 text-center text-md-right'>
                                                                <button
                                                                    className='btn save-btn'
                                                                    onClick={saveSubscriptionPromotion}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                            <div className='col-6 text-center text-md-left'>
                                                                <button
                                                                    className='btn close-btn'
                                                                    onClick={props.handlePromotionModelClose}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='col-12 text-center'>
                                                            <h4 className='text-center mt-1'>Locked Content Promotion is Currently active</h4>
                                                            <p>{ribbonText}</p>
                                                            <p> Ends in &nbsp;
                                                                {existPromotionExpireDate !== false &&
                                                                    <Countdown date={existPromotionExpireDate}
                                                                        onComplete={props.handlePromotionModelClose}
                                                                        renderer={upcomingChatCountdownRenderer}
                                                                    />
                                                                }
                                                            </p>
                                                            <button
                                                                className='btn'
                                                                type='button'
                                                                id='button-addon2'
                                                                onClick={endPromotion}
                                                                disabled={isPromotionOfferSaving}
                                                            >
                                                                END PROMOTION
                                                            </button>
                                                            <button
                                                                className='btn close-btn'
                                                                onClick={props.handlePromotionModelClose}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Tab>
                                    </Tabs>
                                    :
                                    <Tabs className='nav-pills nav-fill' onSelect={(index) => onChangeTab(index)}>
                                        <Tab label='LOCKED CONTENT'>
                                            {isLoading === true ? <div className='text-center'>
                                                <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                                                    <span className='sr-only'>Loading...</span>
                                                </div>
                                            </div> :
                                                <div className='row'>
                                                    {isPromotionExist === false ?
                                                        <>
                                                            <div className='col-12 mb-3'>
                                                                <h4 className='text-center mt-3 mb-4'>LOCKED CONTENT PROMOTION</h4>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio11' onChange={() => onChangeLockedContentOffer(0)} checked={selectedLockedContentOffer === 0} />
                                                                    <label className='form-check-label' htmlFor='radio11'>
                                                                        both exclusive content and mass messages
                                                                    </label>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio21' onChange={() => onChangeLockedContentOffer(1)} checked={selectedLockedContentOffer === 1} />
                                                                    <label className='form-check-label' htmlFor='radio21'>
                                                                        exclusive content only
                                                                    </label>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input className='form-check-input' type='radio' name='radio1' id='radio31' onChange={() => onChangeLockedContentOffer(2)} checked={selectedLockedContentOffer === 2} />
                                                                    <label className='form-check-label' htmlFor='radio31'>
                                                                        mass messages only
                                                                    </label>
                                                                </div>
                                                                <br />
                                                                {selectedTab === 'LOCKED_CONTENT' &&
                                                                    <select className='custom-select py-1 form-select w-auto' aria-label='Default select example' onChange={onChangeDiscountPercentage}>
                                                                        {discountOptions.map((percentage, index) => {
                                                                            return <option key={index} value={percentage.value} selected={selectedDiscount === percentage.value}>{percentage.label}</option>
                                                                        })
                                                                        }
                                                                    </select>
                                                                }
                                                                <hr className='my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px', maxWidth: '100px' }}></hr>
                                                                <label>How long do you need to set the promotion?</label>
                                                                <br />
                                                                <select className='custom-select py-1 form-select w-auto' aria-label='Default select example' onChange={setPromotionExpireDate}>
                                                                    {PromotionOfferDateOption.map((date, index) => {
                                                                        return <option key={index} value={date.value}>{date.label}</option>
                                                                    })
                                                                    }
                                                                </select>
                                                                {(expireDate === 'END_OF_DAY' || expireDate === 'END_OF_MONTH') &&
                                                                    <div className='form-row mt-2'>
                                                                        <div className='col-12 col-md-12 col-lg-12'>
                                                                            <SelectListGroup
                                                                                id='max-s'
                                                                                label='Timezone'
                                                                                name='website_timezone'
                                                                                onChange={(e) => setTimezone(e.target.value)}
                                                                                options={timezoneList}
                                                                                value={timezone}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <hr className='mx-9 my-5' style={{ borderTop: '3px solid #0000006b', borderRadius: '25px' }}></hr>
                                                                <label>{ribbonTextFieldValue + ' - ' + selectedDiscount + '% off'}</label>
                                                                <div className='input-group'>
                                                                    <input type='text' className='form-control' value={offerText} aria-label='Coupon Code' aria-describedby='button-addon2' onChange={(e) => setOfferText(e.target.value)} />
                                                                    <button className='btn my-0' style={{ padding: '0.295rem 0.75rem' }} type='button' id='button-addon2' onClick={() => setRibbonTextFieldValue(offerText)} >Apply</button>
                                                                </div>
                                                                <small id="couponcode" className='form-text text-muted mt-0'>Change promotion message.</small>
                                                            </div>
                                                            <div className='col-6 text-center text-md-right'>
                                                                <button
                                                                    className='btn save-btn'
                                                                    onClick={saveSubscriptionPromotion}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                            <div className='col-6 text-center text-md-left'>
                                                                <button
                                                                    className='btn close-btn'
                                                                    onClick={props.handlePromotionModelClose}
                                                                    disabled={isPromotionOfferSaving}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='col-12 text-center'>
                                                            <h4 className='text-center mt-1'>Locked Content Promotion is Currently active</h4>
                                                            <p>{ribbonText}</p>
                                                            <p> Ends in &nbsp;
                                                                {existPromotionExpireDate !== false &&
                                                                    <Countdown date={existPromotionExpireDate}
                                                                        onComplete={props.handlePromotionModelClose}
                                                                        renderer={upcomingChatCountdownRenderer}
                                                                    />
                                                                }
                                                            </p>
                                                            <button
                                                                className='btn'
                                                                type='button'
                                                                id='button-addon2'
                                                                onClick={endPromotion}
                                                                disabled={isPromotionOfferSaving}
                                                            >
                                                                END PROMOTION
                                                            </button>
                                                            <button
                                                                className='btn close-btn'
                                                                onClick={props.handlePromotionModelClose}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Tab>
                                    </Tabs>
                                }
                            </PromotionDiv>
                        </div>
                    </div>
                </div>
            </div>
        </MainPromotionDivision>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

PromotionModal.propTypes = {
    auth: PropTypes.object.isRequired,
    handlePromotionModelClose: PropTypes.func.isRequired,
    savePromotionOffer: PropTypes.func.isRequired,
    getPromotionOffer: PropTypes.func.isRequired,
    endPromotionOffer: PropTypes.func.isRequired,
    setPromotionButtonTextAfterSave: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { savePromotionOffer, getPromotionOffer, endPromotionOffer })(withRouter(PromotionModal))
