import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'
import { connect } from 'react-redux'
import ModalPopUp from '../Layouts/ModalPopUp'
import Button from '../Layouts/Button'
import { updateUserNotificationDate } from '../../actions/RebillNotificationPopup'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { RESET_REBILL_NOTIFICATION_POPUP } from '../../actions/types'
import { setSweetAlert } from '../../actions/sweetAlertActions'

function RebillNotificationPopup(props) {
    const [isModal, setIsModal] = useState(true)
    const dispatch = useDispatch()
    const { is_notification_enable, subscription_detail, expiration_date, payment_gateway } = props.auth.user
    let message = ''
    if (payment_gateway === 'forumpay') {
        message = `Hi, Your subscription of $${subscription_detail.recurring_price} will expires on ${moment(expiration_date).format('MM/DD/YYYY')}. 
        please keep your wallet balance of $${subscription_detail.recurring_price} or above to continue your subscription.`
    }

    const updateRebillDate = async () => {
        const res = await updateUserNotificationDate()
        if (res.success === 0) {
            const payload = {
                description: res.message
            }
            setIsModal(false)
            return props.setSweetAlert(payload)
        } else {
            dispatch({ type: RESET_REBILL_NOTIFICATION_POPUP })
            setIsModal(false)
        }
    }

    return (
        <>
            {is_notification_enable && subscription_detail.is_rebill_failed === false && isModal === true &&
                <ModalPopUp
                    showCloseBtn={false}
                    showHeader={false}>
                    <>
                        <p className='pt-3'>{message}</p>
                        <div className="modal-button row d-flex justify-content-center my-4">
                            <Button
                                loading={false}
                                type='button'
                                classes='btn ms-md-3 col-6 col-md-auto ml-md-4 mb-0'
                                style={{ minWidth: '8.375rem' }}
                                onClick={updateRebillDate}
                            >OK</Button>
                        </div>
                    </>
                </ModalPopUp>
            }
        </>
    )
}

RebillNotificationPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    updateUserNotificationDate: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, {
    updateUserNotificationDate, setSweetAlert
})(withRouter(RebillNotificationPopup))
